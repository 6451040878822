import React from "react";
import moment from 'moment';
import { Grid, Box, Typography } from "@material-ui/core";
import AuctionCard from "../componets/cards/AuctionCard";
import { getSeaport } from "../webServices/openseaAPI";
import { OrderSide } from 'opensea-js/lib/types';
import { GET } from "../webServices/fetch.js";
import { Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import CircularProgress from "@material-ui/core/CircularProgress";
import { BigNumber } from "bignumber.js";
import Web3 from "web3";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 575 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
  },
};
class LiveAuctions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      LiveAuctions: [],
      isLoaded: false,
      fixPoint: 3,
    };
  }
  componentDidMount() {
    const getFeaturedData = async () => {
      setTimeout(async ()  => {
        const seaport = await getSeaport();
      const Assets  = await seaport.api.getOrders({side:0});
      this.setState({ LiveAuctions:Assets.orders.filter(itm => itm.asset ), isLoaded: true });
      }, 5000);
      
    };
    getFeaturedData();
  }

  render() {
    // const { Items, isLoaded } = this.state;
    return (
      <Grid container spacing={2}>
        <Grid sm={12}>
          <Typography variant="h3" component="h3" className={"grid_title"}>
            <Box fontSize={"24px"}>Live Auctions</Box>
          </Typography>
        </Grid>
        <Grid sm={12}>
          <Row>
            <Col lg={{ span: 12 }} xs={{ span: 12 }}>
              <div className=" responsive-view homePageCarousel live-auctions-mobileview">
                <Carousel responsive={responsive}>
                  {!this.state.LiveAuctions ? (
                    <div
                      className="loading-icons"
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                        display: "inline-block",
                      }}
                    >
                      <Row>
                      <Col lg={{ span: 11 }} xs={{ span: 12 }}>
                      <div class="loader-container">
                      <CircularProgress disableShrink />
                      </div>
                      </Col>
                      </Row>
                    </div>
                  ) : (
                    this.state.LiveAuctions.map((item, i) => (
                      <Col lg={{ span: 11 }} xs={{ span: 12 }} key={i}>
                        <div className="cards-iii">
                          <AuctionCard
                            price={Math.round(Web3.utils.fromWei(BigNumber(item.basePrice).toString(), "ether") * 1e4) / 1e4}
                            collection_name={item.asset.collectionSlug}
                            item_name={item.asset.name ? item.asset.name : item.asset.tokenId}
                            description={item.asset.description}
                            image={item.asset.imageUrl}
                            created_date={item.asset.createdDate}
                            duration={BigNumber(item.expirationTime).toString()}
                            asset_address={
                              item.asset.assetContract.address +
                              "/" +
                              item.asset.tokenId
                            }
                            key={
                              item.asset.assetContract.address +
                              "/" +
                              item.asset.tokenId
                            }
                          />
                        </div>
                      </Col>
                    ))
                  )}
                </Carousel>
              </div>
            </Col>
          </Row>
        </Grid>
      </Grid>
    );
  }
}
export default LiveAuctions;
