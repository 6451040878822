import { React, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import "../styles/style.css";
import { signupViaEmail } from "../webServices/loginServices";
function SignupId() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [errors, setError] = useState("");
  const [signedUp, setSignedUp] = useState(false);
  const [isChecked, setIsChecked] = useState(false);

  function validateForm() {
    if (name == "") {
      setError("Name field is required");
      return false;
    }
    if (email == "") {
      setError("email field is required");
      return false;
    }
    if (password == "") {
      setError("password field is required");
      return false;
    }

    if (confirmPassword == "") {
      setError("Confirm password field is required");
      return false;
    }
    if (confirmPassword !== password) {
      setError("Password and Confirm password does not match");
      return false;
    }

    if (!isChecked) {
      setError("You must accept Terms and contitions");
      return false;
    }

    return true;
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (validateForm()) {
      signupViaEmail(name, email, password)
        .then((success) => {
          setSignedUp(true);
          console.log(success);
        })
        .catch((error) => {
          console.log(error.response.data.error.message);
          console.log(isChecked);
          setError(error.response.data.error.message);
        });
    }
  }

  return (
    <>
      <div className="container-cmd">
        <Container>
          <Row>
            <Col lg={{ span: 12 }}>
              <div className="registered-sign">
                <Row>
                  <Col lg={{ span: 7, offset: 3 }}>
                    <div className="forms-tag">
                      <p>Register</p>
                      {signedUp ? (
                        <div>
                          <p style={{ fontSize: "small", color: "green" }}>
                            User registered successfully you can login now
                          </p>
                          <a href="login">
                            {" "}
                            <Button variant="primary" className="light-shadow ">
                              Goto Login
                            </Button>{" "}
                          </a>
                        </div>
                      ) : (
                        <Form onSubmit={handleSubmit}>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicName"
                          >
                            <Form.Control
                              type="text"
                              placeholder="Enter Name"
                              value={name}
                              onChange={(e) => setName(e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicEmail"
                          >
                            <Form.Control
                              type="email"
                              placeholder="Enter Email"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </Form.Group>

                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Control
                              type="password"
                              placeholder="Password"
                              value={password}
                              onChange={(e) => setPassword(e.target.value)}
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3"
                            controlId="formBasicPassword"
                          >
                            <Form.Control
                              type="password"
                              placeholder="Confirm Password"
                              value={confirmPassword}
                              onChange={(e) =>
                                setConfirmPassword(e.target.value)
                              }
                            />
                          </Form.Group>
                          <Form.Group
                            className="mb-3 check-boxs"
                            controlId="formBasicCheckbox"
                          >
                            <Form.Check
                              type="checkbox"
                              label="I agree to the MintJar Terms of service and Privacy Policy"
                              style={{ fontSize: "small" }}
                              onChange={(event) =>
                                setIsChecked(event.currentTarget.checked)
                              }
                              checked={isChecked}
                            />
                          </Form.Group>

                          <div className="log-in">
                            <Button
                              variant="primary"
                              className="light-shadow "
                              type="submit"
                            >
                              Sign Up
                            </Button>
                            {errors ? (
                              <p style={{ fontSize: "small", color: "red" }}>
                                {errors}
                              </p>
                            ) : (
                              ""
                            )}
                          </div>
                        </Form>
                      )}
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SignupId;
