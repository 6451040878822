import React, { Component } from "react";
import ReactAwesomePlayer from "react-awesome-player";
import "../../styles/style.css";

class VideoGallery extends Component {
  loadeddata() {
    console.log("loadeddata");
  }
  canplay() {
    console.log("canplay");
  }
  canplaythrough() {
    console.log("canplaythrough");
  }
  play() {
    console.log("play");
  }
  pause() {
    console.log("pause");
  }
  waiting() {
    console.log("waiting");
  }
  playing() {
    console.log("playing");
  }
  ended() {
    console.log("ended");
  }
  error() {
    console.log("error");
  }
  render() {
    const { options } = this.state;
    return (
      <>
        <ReactAwesomePlayer
          onRef={(video) => {
            console.log(video);
          }}
          options={options}
          loadeddata={this.loadeddata}
          canplay={this.canplay}
          canplaythrough={this.canplaythrough}
          play={this.play}
          pause={this.pause}
          waiting={this.waiting}
          playing={this.playing}
          ended={this.ended}
          error={this.error}
        />
      </>
    );
  }
}

export default VideoGallery;
