/* eslint-disable no-undef */
import React, { useState,useRef } from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import PerviewCard from "../componets/cards/PerviewCard";
import { Tooltip,Button, Snackbar } from "@material-ui/core";
import MuiAlert from '@material-ui/lab/Alert';
import FileCopyIcon from "@material-ui/icons/FileCopy";
import AssignmentIcon from "@material-ui/icons/Assignment";
import AddIcon from "@material-ui/icons/Add";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import StarOutlineRoundedIcon from "@material-ui/icons/StarOutlineRounded";
import { windowLocation } from "../helpers/common";
import Web3 from "web3";
import "../styles/style.css";
import LoaderImg from "../assets/simpleLoader.svg";
import { getWeb3 } from "../webServices/loginServices";
import { logMintTrade } from "../webServices/tradeLog";
import { failoverAPI } from "../webServices/failoveAPI";


const BootstrapButton = withStyles({
  root: {
    boxShadow: "none",
    textTransform: "none",
    fontSize: 16,
    padding: "6px 12px",
    border: "1px solid",
    lineHeight: 1.5,
    backgroundColor: "#0063cc",
    borderColor: "#0063cc",
    fontFamily: [
      "-apple-system",
      "BlinkMacSystemFont",
      '"Segoe UI"',
      "Roboto",
      '"Helvetica Neue"',
      "Arial",
      "sans-serif",
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(","),
    "&:hover": {
      backgroundColor: "#0069d9",
      borderColor: "#0062cc",
      boxShadow: "none",
    },
    "&:active": {
      boxShadow: "none",
      backgroundColor: "#0062cc",
      borderColor: "#005cbf",
      color: "#fff",
    },
    "&:focus": {
      boxShadow: "0 0 0 0.2rem rgba(0,123,255,.5)",
    },
  },
})(Button);
const useStyles = makeStyles((theme) => ({
  root: {
    "& > *": {
      margin: theme.spacing(1),
    },
  },
  input: {
    display: "none",
  },
}));
function NewAssets() {
  const [propertiesList, setPropertiesList] = useState([
    { trait_type: "", value: "" },
  ]);
  const [levelLists, setLevelLists] = useState([
    { trait_type: "", value: "" , display_type: "number"},
  ]);
  const [state, setState] = React.useState({
    open: false,
    notificationMessage:'',
    notificationType:'success'
  });
  const [ButtonText, setButtonText] = useState('Chose File');
  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

  const { open, notificationMessage, notificationType } = state;

  const handleNotificationClick = (msg, type) =>  {
    setState({ open: true, notificationMessage:msg, notificationType:type });
  };

  const handleNotificationClose = () => {
    setState({ open: false });
  };


  // handle input change
  const handleInputChange = (e, index) => {
    const { name, value } = e.target;
    const list = [...propertiesList];
    list[index][name] = value;
    setPropertiesList(list);
  };



  const handleInputChanges = (e, index) => {
    const { name, value } = e.target;
    const list = [...levelLists];
    list[index][name] = value;
    setLevelLists(list);
  };

  // handle click event of the Remove button
  const handleRemoveClick = (index) => {
    const list = [...propertiesList];
    list.splice(index, 1);
    setPropertiesList(list);
  };

  const handleRemoveClicks = (index) => {
    const list = [...levelLists];
    list.splice(index, 1);
    setLevelLists(list);
  };

  // handle click event of the button
  const handleAddClick = () => {
    setPropertiesList([...propertiesList, { trait_type: "", value: "" }]);
    console.log("🚀 ~ file: NewAssets.js ~ line 136 ~ handleAddClick ~ propertiesList", propertiesList)
  };

  const handleAddClicks = () => {
    setLevelLists([...levelLists, { trait_type: "", value: "", display_type: "number" }]);
    console.log("🚀 ~ file: NewAssets.js ~ line 141 ~ handleAddClicks ~ levelLists", levelLists)
  };

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleShowProgress = () => setShowProgress(true);
  const handleShowProgressClose = () => setShowProgress(false);
  const [showProgress, setShowProgress] = useState(false);

  const [shows, setShows] = useState(false);

  const handleClosed = () => setShows(false);
  const handleShows = () => setShows(true);

  //--------------------------------------------------------------------
  const contract = require("../build/contracts/Creature.json");
  // const NFT_CONTRACT_ADDRESS = "0x0C469f83AddBd9735da6686f925E0b6C7CCfee59"; //ALPHA
  // const NFT_CONTRACT_ADDRESS = "0x6Ffb10bAC5D6070162B1573426fAA74e60bcF237" // BETA 
  const NFT_CONTRACT_ADDRESS = "0xa025eed5b1213e37085b0f12026145436145c952" // MAINNET 
  
  const mintNFT = async (tokenURI,Id,image) => {  
    handleShowProgress();
    const web3 = await getWeb3();
    let OWNER_ADDRESS = await web3.eth.getAccounts();
    OWNER_ADDRESS = OWNER_ADDRESS[0];
    const contractInstance = new web3.eth.Contract(
      contract.abi,
      NFT_CONTRACT_ADDRESS
    );
    
    const results = await contractInstance.methods
      .mintTo(OWNER_ADDRESS, tokenURI)
      .send({ from: OWNER_ADDRESS, gasPrice: "47000000000" }).then( async (res)=>{
        handleNotificationClick("NFT Minted Successfully","success");
        handleShowProgressClose();
        contractInstance.methods.getCounter().call({ from: OWNER_ADDRESS,gasPrice: "47000000000"  }).then(async (counterId)=>{
          await logMintTrade(title,"Mintjar NFTs",image,NFT_CONTRACT_ADDRESS.toLowerCase(),counterId,res.transactionHash);
          await updateTokenId(res.to,counterId,Id);
        }).catch((e)=>{
          console.log(e);
        }); //get latest nonce
      }).catch((error)=>{
        handleShowProgressClose();
        handleNotificationClick(error.message,"error");
        console.log('error');
        console.log(error);
      }); //get latest nonce
  };

  //--------------------------------------------------------------------
  const classes = useStyles();
  const [selectedImage, setSelectedImage] = useState();
  const [imagePriview, setImagePriview] = useState();
  const [title, setTitle] = useState();
  const [description, setDescription] = useState();
  const [category, setCategory] = useState();
  const [collection, setCollection] = useState('MintJar NFTs');
  const [extLink, setExtLink] = useState();
  
  // This function will be triggered when the file field change
  const imageChange = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setSelectedImage(e.target.files[0]);
      setImagePriview(URL.createObjectURL(e.target.files[0]));
      
      setButtonText('Change Image');
    }
  };
  
  function buildFormData(formData, data, parentKey) {
    if (data && typeof data === 'object' && !(data instanceof Date) && !(data instanceof File) && !(data instanceof Blob)) {
      Object.keys(data).forEach(key => {
        buildFormData(formData, data[key], parentKey ? `${parentKey}[${key}]` : key);
      });
    } else {
      const value = data == null ? '' : data;
  
      formData.append(parentKey, value);
    }
  }

  const handleDragEnter = (event) => {
    event.preventDefault();
    // dispatch({ type: 'AddToDropZone', inDropZone: true });
  };

  const handleDragOver = (event) => {
    event.preventDefault();
    // event.dataTransfer.dropEffect = 'move';
    // dispatch({ type: 'AddToDropZone', inDropZone: true });
  };
  const handleDrop = (event) => {
    event.preventDefault();

    let files = [...event.dataTransfer.files];
    let files_with_preview = [];
    setSelectedImage(event.dataTransfer.files[0]);
    // files.map((file, index) => {
    //   file[`image_${index}`] = URL.createObjectURL(file);
    //   files_with_preview.push(file);
    // });

    // if (files) {
    //   dispatch({ type: 'AddToList', files });
    //   dispatch({ type: 'AddToDropZone', inDropZone: false });
    // }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    handleShowProgress();

    var fileData = new FormData();
    if (selectedImage) {
      fileData.append("image", selectedImage);
    }
    const authObj= JSON.parse(localStorage.getItem("authObj"));
    const userId = authObj.UserId;
    const walletAddress = authObj.WalletAddress;
    fileData.append("userId", userId);
    fileData.append("creator", walletAddress.toLowerCase());
    fileData.append("owner", walletAddress.toLowerCase());
    fileData.append("name", title ?? "Name");
    fileData.append("description", description ?? "");
    fileData.append("external_url", extLink ?? "");
    fileData.append("type", extLink ?? "created");
    fileData.append("contract",  NFT_CONTRACT_ADDRESS.toLowerCase());
    fileData.append("collections",  NFT_CONTRACT_ADDRESS.toLowerCase());
    fileData.append("collection_title", collection);
    fileData.append("category", category ? category : "Collectibles");


    propertiesList.forEach((element,index) => {
      buildFormData(fileData,element,`attributes[${index}]`);
    });
    levelLists.forEach((element,index) => {
      buildFormData(fileData,element,`attributes[${propertiesList.length+index}]`);
    });
    
    
    let url = `${process.env.REACT_APP_API_SERVER_URL}/assets`;
    const AccessToken = JSON.parse(localStorage.getItem("authObj")).AccessToken;
    var requestOptions = {
      method: "POST",
      headers: { Authorization: `Bearer ${AccessToken}` },
      body: fileData,
      redirect: "follow",
    };

    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        let assatObj=JSON.parse(result);
        handleShowProgressClose();
        console.log("🚀 ~ file: NewAssets.js ~ line 299 ~ .then ~ result", result)
        mintNFT(assatObj.tokenURI,assatObj.data.id,assatObj.data.image);
      })
      .catch((error) => {
        handleShowProgressClose();
        handleNotificationClick(error.message,"error");
        console.log("error", error)
      });
  };

  const updateTokenId = (ContractId,tokenCounter,Id)=>{
    let url = `${process.env.REACT_APP_API_SERVER_URL}/assets/${Id}`;
    const AccessToken = JSON.parse(localStorage.getItem("authObj")).AccessToken;
    var requestOptions = {
      method: "PATCH",
      headers: { Authorization: `Bearer ${AccessToken}`,'Content-Type': 'application/json' },
      body: JSON.stringify({ token: tokenCounter}),
      redirect: "follow",
    };
    fetch(url, requestOptions)
      .then((response) => response.text())
      .then((result) => {
        console.log('TokenId Updated');
        console.log(`/asset/${ContractId}/${tokenCounter}`);
        windowLocation(`/asset/${ContractId}/${tokenCounter}`);
      })
      .catch((error) => {
        handleShowProgressClose();
        handleNotificationClick(error.message,"error");
        console.log("error", error)
      });
  }
  const imageRef = useRef();

  const Imagepicker = () => (
    <div style={{ paddingTop: "15%" }} onDrop={(event) => handleDrop(event)}
    onDragOver={(event) => handleDragOver(event)}
    onDragEnter={(event) => handleDragEnter(event)}>
      <p className="MuiTypography-root MuiDropzoneArea-text ">
        JPG, PNG, GIF, SVG, Max size: 40 MB
      </p>
      <label>
        <FileCopyIcon></FileCopyIcon>
      </label>
      <p>Drag & Drop</p>
    </div>
  );

  return (
    <>
      <Container>
        <Row>
          <Col style={{ margin: "0px auto" }} lg={{ span: 9 }}>
            <Col lg={{ span: 12 }}>
              <div className="asset-title">
                <p>Create New NFT </p>
              </div>

              <Row className="mb-3">
                <Col className="mb-3" lg={{ span: 8 }}>
               
                  <h5 class="h5">
                  Upload Image* 
                    </h5>
                  
                  <div tabIndex="0" className="MuiDropzoneArea-root" onDrop={(event) => handleDrop(event)}
                          onDragOver={(event) => handleDragOver(event)}
                          onDragEnter={(event) => handleDragEnter(event)}
                          onClick={(event) => imageRef.current.click()}
                          >
                    <div
                      className="MuiDropzoneArea-textContainer nft-image-dropbox"
                      
                    >
                      {selectedImage ? (
                        <img
                          alt="NFT"
                          src={URL.createObjectURL(selectedImage)}
                          style={{
                            maxHeight: "250px",
                            width: "100%",
                            objectFit: "cover",
                            marginBottom: "10px",
                          }}
                          onDrop={(event) => handleDrop(event)}
                          onDragOver={(event) => handleDragOver(event)}
                          onDragEnter={(event) => handleDragEnter(event)}
                          
                        />
                      ) : (
                        <Imagepicker />
                      )}
                      <input
                        ref={imageRef}
                        accept="image/*"
                        className={classes.input}
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={imageChange}
                      />
                        <Button
                          variant="contained"
                          color="primary"
                          component="span"
                        >
                          {ButtonText}
                        </Button>
                      <input
                        accept="image/*"
                        className={classes.input}
                        id="icon-button-file"
                        type="file"
                      />
                    </div>
                  </div>
                </Col>
                <Col lg={{ span: 4 }}>
                  <h5 class="h5">
                  Preview &nbsp;&nbsp;
                  <Tooltip
                                          title="Preview"
                                          arrow
                                        >
                    {/* <span aria-label="addtitle">Title*</span> */}
                    <InfoOutlinedIcon style={{cursor:'pointer'}} color="primary" fontSize="small"></InfoOutlinedIcon>
                    </Tooltip>
                    
                    </h5>
                  
                    <PerviewCard
                      image={imagePriview ? imagePriview : ""}
                      name={title}
                      collection="Mintjar NFTs"
                    />
                  
                </Col>
              </Row>
            </Col>
            <Col lg={{ span: 9 }}>
              <Row>
                <Col lg={{ span: 12 }}>
                  <div className="forms-label-title">
                    <Form onSubmit={handleSubmit}>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h5 class="h5">
                      Title* 
                    
                    </h5>
                        <Form.Control
                          type="text"
                          placeholder="Monaliza"
                          value={title}
                          onChange={(e) => setTitle(e.target.value)}
                          required
                        />
                      </Form.Group>
                      <Form.Group className="mb-3" controlId="formBasicEmail">
                        
                        <h5 class="h5">
                        External Link &nbsp;&nbsp;
                        <Tooltip
                            title="External Link"
                            aria-label="externalLink"
                            arrow
                          >
                          {/* <span aria-label="externalLink">External Link</span> */}
                          <InfoOutlinedIcon style={{cursor:'pointer'}} color="primary" fontSize="small"></InfoOutlinedIcon>
                        </Tooltip>
                          
                          </h5>
                        <Form.Control
                          type="text"
                          placeholder="https://external-link.com"
                          value={extLink}
                          onChange={(e) => setExtLink(e.target.value)}
                        />
                      </Form.Group>
                      <Form.Group
                        className="mb-3"
                        controlId="exampleForm.ControlTextarea1"
                      >
                        <div className="file-title file-title-fix">
                        <h5 class="h5">Description &nbsp;&nbsp;
                  <Tooltip
                                          title="The description will be included on the items detail page underneath its image."
                                          arrow
                                        >
                    {/* <span aria-label="addtitle">Title*</span> */}
                    <InfoOutlinedIcon style={{cursor:'pointer'}} color="primary" fontSize="small"></InfoOutlinedIcon>
                    </Tooltip>
                          </h5>
                        </div>
                        <Form.Control
                          placeholder="Provide a detailed description of your item"
                          as="textarea"
                          rows={5}
                          value={description}
                          onChange={(e) => setDescription(e.target.value)}
                        />
                      </Form.Group>
                    
                    <Form.Group className="mb-3" controlId="collection">
                    <h5 class="h5">Collection Name &nbsp;&nbsp;
                  <Tooltip
                                          title="This is the collection where your item will appear."
                                          arrow
                                        >
                    {/* <span aria-label="addtitle">Title*</span> */}
                    <InfoOutlinedIcon style={{cursor:'pointer'}} color="primary" fontSize="small"></InfoOutlinedIcon>
                    </Tooltip>
                          </h5>
                      <Form.Select aria-label="Default select example" onChange={(e) => setCollection(e.target.value)}>
                        <option disabled>Select Option</option>
                        <option selected value={NFT_CONTRACT_ADDRESS}>Mintjar NFTs</option>
                      </Form.Select>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="Category">
                    <h5 class="h5">Select Category &nbsp;&nbsp;
                  <Tooltip
                                          title="Category will help make your item more discoverable."
                                          arrow
                                        >
                    {/* <span aria-label="addtitle">Title*</span> */}
                    <InfoOutlinedIcon style={{cursor:'pointer'}} color="primary" fontSize="small"></InfoOutlinedIcon>
                    </Tooltip>
                          </h5>
                      <Form.Select aria-label="Default select example" onChange={(e) => setCategory(e.target.value)}>
                        <option disabled>Select Option</option>
                        <option selected value="Collectibles">Collectibles</option>
                        <option selected value="Art">Art</option>
                        <option selected value="Games">Games</option>
                        <option selected value="Sports">Sports</option>
                      </Form.Select>
                    </Form.Group>

                    </Form>
                  </div>
                </Col>

                <Col lg={{ span: 12 }}>
                  <div className="file-title file-title-fix">
                    {/* <label>This is the collection where your item will appear.</label> */}
                    <div className="description-trade accordion-link-fix">
                      <div
                        className="collection-accordion"
                        style={{ marginBottom: "0px" }}
                      >
                        <Row className="border-styled">
                          <Col
                            style={{ marginTop: "10px" }}
                            lg={{ span: 1 }}
                            xs={{ span: 1 }}
                          >
                            <AssignmentIcon />
                          </Col>
                          <Col lg={{ span: 8 }} xs={{ span: 8 }}>
                            <div
                              style={{ marginBottom: "15px" }}
                              className="accordion-label"
                            >
                              <p> 
                                Properties &nbsp;&nbsp;
                                <Tooltip
                                          title="Properties"
                                          aria-label="addtitle"
                                          arrow
                                        >
                    {/* <span aria-label="addtitle">Title*</span> */}
                    <InfoOutlinedIcon style={{cursor:'pointer'}} color="primary" fontSize="small"></InfoOutlinedIcon>
                    </Tooltip>
                              </p>
                              <label>Textual traits that show up as rectangles</label>
                            </div>
                          </Col>
                          <Col
                            style={{ marginTop: "9px" }}
                            lg={{ span: 3 }}
                            xs={{ span: 3 }}
                          >
                            <div className="multiple-pickers">
                              {/* <Box display="flex" justifyContent="center">
                 <Box borderRadius={16} {...defaultProps}>
                   +
                   </Box>
                 </Box> */}
                              <BootstrapButton
                                variant="contained"
                                color="primary"
                                onClick={handleShow}
                              >
                                <AddIcon />
                              </BootstrapButton>
                            </div>
                          </Col>
                        </Row>
                        <Row
                          className="border-styled"
                          style={{ border: "none", marginBottom: "0px" }}
                        >
                          <Col
                            style={{ marginTop: "10px" }}
                            lg={{ span: 1 }}
                            xs={{ span: 1 }}
                          >
                            <StarOutlineRoundedIcon />
                          </Col>
                          <Col lg={{ span: 8 }} xs={{ span: 8 }}>
                            <div
                              style={{ marginBottom: "0px" }}
                              className="accordion-label"
                            >
                              <p>Levels &nbsp;&nbsp;
                              <Tooltip
                                          title="Levels"
                                          aria-label="addtitle"
                                          arrow
                                        >
                    {/* <span aria-label="addtitle">Title*</span> */}
                    <InfoOutlinedIcon style={{cursor:'pointer'}} color="primary" fontSize="small"></InfoOutlinedIcon>
                    </Tooltip>
                              </p>
                              <label>
                              Numerical traits that show as a progress bar
                              </label>
                            </div>
                          </Col>
                          <Col
                            style={{ marginTop: "9px" }}
                            lg={{ span: 3 }}
                            xs={{ span: 3 }}
                          >
                            <div className="multiple-pickers">
                              <BootstrapButton
                                variant="contained"
                                color="primary"
                                component="span"
                                onClick={handleShows}
                              >
                                <AddIcon />
                              </BootstrapButton>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                  <Row>
                    <Col
                      style={{ marginTop: "30px" }}
                      lg={{ span: 3 }}
                      xs={{ span: 3 }}
                    >
                      
                     <Button
                        style={{ color: "#fff" }}
                        variant="contained"
                        color="primary"
                        component="span"
                        type="submit"
                        onClick={handleSubmit}
                        disabled={title && selectedImage ? false : true}
                      >
                        Create
                      </Button>
                     
                      <Snackbar
        anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
        open={open}
        autoHideDuration={10000}
        onClose={handleNotificationClose}
        message={notificationMessage}
        key={'bottom right'}
      >
        <Alert onClose={handleNotificationClose} severity={notificationType}>
        {notificationMessage}
        </Alert>
      </Snackbar>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Col>
          
        </Row>

        {/* ----------------------------Show Progress Modal---------------------------------------------- */}

        <div className="popup-modals">
          <Modal show={showProgress}>
            <Modal.Header>
              <Modal.Title>PLEASE WAIT....</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: "center" }}>
              <img src={LoaderImg} style={{height:'64px', width:'64px'}} alt="Loading" />
            </Modal.Body>
            {/* <Modal.Footer>
        </Modal.Footer> */}
          </Modal>
        </div>

        {/* --------------------------------------------------------------------------------------------- */}
        {/* ----------------------------Show You Created Modal---------------------------------------------- */}
        {/* 
<div className="popup-modals">

      <Modal show={showProgress} >
        <Modal.Header >
          <Modal.Title>
                  You created `NFT NAME`
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{textAlign:'center'}}>
          <Typography>Cool! Tou just created `NFT NAME</Typography>

        <img src={LoaderImg} />

        <Typography>SHARE</Typography>

        </Modal.Body>
        <Modal.Footer>

        </Modal.Footer>
      </Modal>
            </div> */}

        {/* --------------------------------------------------------------------------------------------- */}
        <div className="popup-modals">
          <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
              <p>Properties</p>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col lg={{ span: 12 }} className="px-0">
                  <p>
                    Properties shows up underneath your item, are clickable, and
                    can be filtered.
                  </p>
                </Col>
              </Row>
              {propertiesList.map((x, i) => {
                return (
                  <>
                    <Row>
                      <Col lg={{ span: 6 }} className="px-0">
                        <Form>
                          <label>Name</label>
                          <InputGroup className="mb-2">
                            {propertiesList.length !== 1 && (
                              <InputGroup.Text
                                onClick={() => handleRemoveClick(i)}
                              >
                                X
                              </InputGroup.Text>
                            )}
                            <FormControl
                              name="trait_type"
                              placeholder="Gender"
                              value={x.trait_type}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </InputGroup>
                        </Form>
                      </Col>
                      <Col lg={{ span: 6 }} className="px-0">
                        <Form>
                          <label>Value</label>
                          <InputGroup className="mb-2">
                            <FormControl
                              className="ml10"
                              name="value"
                              placeholder="Male"
                              value={x.value}
                              onChange={(e) => handleInputChange(e, i)}
                            />
                          </InputGroup>
                        </Form>
                      </Col>
                      <div className="log-in px-0">
                        {propertiesList.length - 1 === i && (
                         
                            <Button onClick={handleAddClick} variant="outlined" >
                            More
                          </Button>
                          
                        )}
                      </div>
                    </Row>
                  </>
                );
              })}
            </Modal.Body>
            <Modal.Footer>
              <BootstrapButton
                variant="contained"
                color="primary"
                onClick={handleClose}
              >
                Save
              </BootstrapButton>
            </Modal.Footer>
          </Modal>
        </div>
        <div className="popup-modals">
          <Modal show={shows} onHide={handleClosed}>
            <Modal.Header closeButton>
              <p>Levels</p>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col lg={{ span: 12 }} className="px-0">
                  <p>
                    Level shows up underneath your item, are clickable, and can
                    be filtered.
                  </p>
                </Col>
              </Row>

              {levelLists.map((x, i) => {
                return (
                  <>
                    <Row>
                      <Col lg={{ span: 6 }} className="px-0">
                        <Form>
                          <label>Name</label>
                          <InputGroup className="mb-2">
                            {levelLists.length !== 1 && (
                              <InputGroup.Text
                                style={{ cursor: "pointer" }}
                                onClick={() => handleRemoveClicks(i)}
                              >
                                X
                              </InputGroup.Text>
                            )}
                            <FormControl
                              name="trait_type"
                              placeholder="Power"
                              value={x.trait_type}
                              onChange={(e) => handleInputChanges(e, i)}
                            />
                          </InputGroup>
                        </Form>
                      </Col>
                      <Col lg={{ span: 3 }} className="px-0">
                        <Form>
                          <label>Value</label>
                          <InputGroup className="mb-2">
                            <Form.Control
                              type="text"
                              name="value"
                              value={x.value}
                              placeholder="23"
                              onChange={(e) => handleInputChanges(e, i)}
                            />
                          </InputGroup>
                        </Form>
                      </Col>
                      
                      <div className="log-in px-0">
                        {levelLists.length - 1 === i && (
                          
                          <Button onClick={handleAddClicks} variant="outlined" >
                          More
                        </Button>
                        )}
                      </div>
                    </Row>
                  </>
                );
              })}

              <Form>
                <div className="save-button">
                  {/* <BootstrapButton variant="primary" className="light-shadow " type="submit"> */}
                </div>
              </Form>
            </Modal.Body>
            <Modal.Footer>
              <BootstrapButton variant="contained" color="primary" onClick={handleClosed}>
                Save
              </BootstrapButton>
            </Modal.Footer>
          </Modal>
        </div>
      </Container>
    </>
  );
}

export default NewAssets;
