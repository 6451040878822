import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import AssetCard from "../componets/cards/AssetCard";
import { GET,dbGET } from "../webServices/fetch.js";
import { Row, Col } from "react-bootstrap";
import CircularProgress from '@material-ui/core/CircularProgress';
import Carousel from "react-multi-carousel";
import { truncateString } from "../helpers/strings";
import {ButtonGroup } from "../helpers/common";
import { getSeaport } from "../webServices/openseaAPI";
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 575 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
  },
};
class ExploreMore extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      LiveAuctions: [],
      isLoaded: false,
      wordsLength: 12,
    };
  }

  componentDidMount() {
    const getFeaturedData = async () => {
      //let rnd = Math.floor(Math.random() * 500);
     // const { data: Items } = await GET(`assets?order_direction=desc&offset=${rnd}`);
     const { data: Items } = await dbGET(`v1/assets/top`)
     let orderData=[];
     Items.forEach((itemData)=>{
       itemData.NFTs.forEach((itm)=>{
         
         orderData.push(itm)
       })
     });
    
      if (orderData) {
        // Set data to state
        this.setState({
          NFTsYouViewed: orderData.reverse(),
          isLoaded: true,
        });
        // console.log(this.state.NFTsYouViewed);
        if (this.state.NFTsYouViewed) {
          // this.state.NFTsYouViewed.map((item) => console.log(item.name));
        }
      } else {
        // error
      }
    };
    const getDataRedudent = () => {
      getFeaturedData().then((itm)=>{
        console.log("FKM Explore More Got Data")
      }).catch((err)=>{
        console.log("FKM Explore More Got Data Error Retrying in 2 Sec", err)
        setTimeout(function(){ getDataRedudent(); }, 2000);
  
      });
    }
    getDataRedudent();
  }

  render() {
    return (
      <Grid sm={12}>
        <Typography variant="h3" component="h3" className={"grid_title"}>
          <Box fontSize={"24px"} about="Explore More NFTs">Explore More</Box>
        </Typography>
        <div
          className="responsive-view homePageCarousel"
          style={{ marginTop: "0px", paddingTop: 0 }}
        >
          <Carousel
                            responsive={responsive}
                            
                            arrows = {false}
                            customButtonGroup={<ButtonGroup
                                next={this.props.next}
                                previous={this.props.previous}
                                rest={this.props.rest}
                            />}
                        >
            {!this.state.isLoaded ? (
              <div className="loading-icons" style={{textAlign: "center", justifyContent: "center", display: "inline-block"}}>
                  <Row>
                  <Col lg={{ span: 11 }} xs={{ span: 12 }}>
                    <div class="loader-container">
                    <CircularProgress disableShrink />
                    </div>
                  </Col>
                  </Row>
                  
                  </div>
            ) : (
              this.state.NFTsYouViewed.map((item) => (
                <AssetCard
                  asset={item}
                  key={item.asset_contract.address + "/" + item.token_id}
                />
              ))
            )}
          </Carousel>
        </div>
      </Grid>
    );
  }
}
export default ExploreMore;
