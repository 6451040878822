import React from "react";
import { withRouter } from "react-router";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import OceanAppFooterDemo from "../containers/footer/Footer";
import PrimarySearchAppBar from "../containers/header/Header";
import WalletProfilePage from "../componets/WalletProfile";
import { getAuthObj } from "../webServices/loginServices";

function WalletProfile() {
  const ProfileData = getAuthObj();
  return (
    <div>
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg">
          <PrimarySearchAppBar />
          {/* <ImagePickes /> */}
          <WalletProfilePage
            name={"Unnamed"}
            email={"NA"}
            bio={"NA"}
            ProfileImage={""}
          />
        </Container>
      </React.Fragment>
      <OceanAppFooterDemo />
    </div>
  );
}

export default withRouter(WalletProfile);
