import axios from "axios";

const apiBaseURL = process.env.REACT_APP_NETWORK==="rinkeby" 
? "https://testnets-api.opensea.io/api/v1"
: "https://api.opensea.io/api/v1";

const headers = {"X-API-KEY": "5573ca76ff5a48e2bd143e60d15d7b42"};
export const GET = (url) => {
  return axios.get(`${apiBaseURL}/${url}`,{
    headers: {
      'X-API-KEY': '5573ca76ff5a48e2bd143e60d15d7b42'
    }});
};
// if need for headers etc.
export const POST = (url, data) => {
  return axios(`${apiBaseURL}/${url}`, {
    method: "POST",
    headers,
    data,
  });
};

//-------------------------OSDB API
const baseAPI = process.env.REACT_APP_API_SERVER_URL;
let accessToken = "";
if(localStorage.getItem("authObj")){
  accessToken=JSON.parse(localStorage.getItem("authObj")).AccessToken ?? "";
}
const dbHeaders = { headers: { Authorization: `Bearer ${accessToken}` }}
export const dbGET = (url) => {
  return axios.get(`${baseAPI}/${url}`,dbHeaders);
}

export const dbPOST = (url,data) => {
  return axios(`${baseAPI}/${url}`, {
    method: "POST",
    headers,
    data,
  });
}