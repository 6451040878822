/* eslint-disable no-unused-vars */
/* eslint-disable react/no-direct-mutation-state */
import React from "react";
import { Button, Grid, Typography } from "@material-ui/core";
import AssetCard from "../componets/cards/AssetCard";
import { GET,dbGET } from "../webServices/fetch.js";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import SearchIcon from "@material-ui/icons/Search";
import ViewListIcon from '@material-ui/icons/ViewList';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ViewQuiltIcon from '@material-ui/icons/ViewQuilt';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
// import Grid from '@material-ui/core/Grid';
// import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import LoaderImg from "../assets/simpleLoader.svg";
// import Input from '@material-ui/core/Input';
// import Slider from "@material-ui/core/Slider";
import ClearIcon from "@material-ui/icons/Clear";
import { truncateString } from "../helpers/strings";
// import { Container, Row, Col, Button } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import CircularProgress from "@material-ui/core/CircularProgress";
// import { alpha, makeStyles } from "@material-ui/core/styles";
// import JsonData from "../helpers/jsonData.json";
import { withStyles } from "@material-ui/styles";
import "../styles/style.css";
import Select from "@material-ui/core/Select";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Web3 from "web3";
import grid from "../../src/assets/images/grid.png";
import InfiniteScroll from "react-infinite-scroll-component";
import { useLocation } from "react-router-dom";
import { withRouter } from "react-router";
import { getFavorites } from "../webServices/nftServices";
import { stringify } from "uuid";

const styles = (theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
});
let resultArray = [];
let statesArray = [];
let sortedActivities = [];
let filterButtons = [];
class MarketplaceCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      MarketplaceItems: [],
      oldCollection:[],
      sotringData: [],
      orignalData: [],
      listShow:true,
      isLoaded: false,
      wordsLength: 15,
      priceCurrency: "USD",
      collections: [1, 2],
      sliderVal: null,
      startPrice: 0,
      endPrice: 1000,
      checkedStatus: false,
      sortingStatus: false,
      sortingFilterStatus: false,
      selectedCollection: [],
      collectionFilterStatus: false,
      filtersData: [],
      applyFilterStatus: false,
      button: "show",
      newArrival: false,
      buyNow: false,
      auctions: false,
      offers: false,
      collection: false,
      collectionschek: false,
      category: false,
      categories: false,
      all: false,
      sliderStatus: true,
      filterButtonsState: [],
      dataOffset:0,
      dataCount:0,
      dataEOF:false,
      selectedCollection:[],
      dataHasMore:true,
      dataEndMessage:"Yay! You have seen it all",
      currencySymbol:'USD',
      priceMax:'',
      priceMin:'',

    };
  }

  sortArrayElement = (objItem, sort_val) => {
    if (sort_val == 0) {
      this.setState({
        sortingFilterStatus: false,
      });
      this.setState({
        MarketplaceItems: objItem,
      });
      return false;
    } else {
      this.setState({
        sortingFilterStatus: true,
      });
    }
    var sortedActivities = [];
    if (sort_val == "n_o") {
      // new to old
      sortedActivities = objItem.sort(
        (a, b) =>
          new Date(b.asset_contract.created_date) -
          new Date(a.asset_contract.created_date)
      );
    } else if (sort_val == "o_n") {
      // old to new
      sortedActivities = objItem.sort(
        (a, b) =>
          new Date(a.asset_contract.created_date) -
          new Date(b.asset_contract.created_date)
      );
    } else if (sort_val == "l_h" || sort_val == "h_l" || sort_val == "e_s") {
      // remove null sale items
      var newArray = objItem.filter(function (el) {
        return el.sell_orders != null;
      });
      if (sort_val == "l_h") {
        // low to high
        sortedActivities = newArray.sort(
          (a, b) => a.sell_orders[0].base_price - b.sell_orders[0].base_price
        );
      } else if (sort_val == "h_l") {
        // high to low
        sortedActivities = newArray.sort(
          (a, b) => b.sell_orders[0].base_price - a.sell_orders[0].base_price
        );
      } else if (sort_val == "e_s") {
        sortedActivities = newArray.filter(function (el) {
          return el.sell_orders[0].closing_date != null;
        });
      }
      // new Date(a.sell_orders[0].base_price) - new Date(b.sell_orders[0].base_price)
    } else if (sort_val == "r_s") {
      sortedActivities = objItem.filter(function (el) {
        return el.num_sales > 0;
      });
    }
    this.setState({
      // MarketplaceItems:hhh,
      MarketplaceItems: sortedActivities,
      sotringData: sortedActivities,
      isLoaded: true,
    });
  };
  storeFilterdArray = (checked, value, dataAatribute) => {
    if (checked) {
      resultArray.push(value);
      filterButtons.push({ value: value, name: dataAatribute });
    } else {
      let newArray = [];
      for (let i = 0; i < resultArray.length; i++) {
        if (resultArray[i] !== value) {
          newArray.push(resultArray[i]);
          filterButtons.push({ value: value, name: dataAatribute });
        }
      }
      var newButtonObj = filterButtons.filter(function (
        el,
        index,
        filterstest
      ) {
        console.log(el.name);
        return el.name != dataAatribute;
      });
      resultArray = newArray;
      filterButtons = newButtonObj;
    }
    this.setState({ filterButtonsState: filterButtons }, () => {
      filterButtons = this.state.filterButtonsState;
    });
    return resultArray;
  };
  showList = (e)=>{
    if(e==='big'){
    this.setState({
      listShow:true,
    });
  }else{
    this.setState({
      listShow:false,
    });
  }
  }

   refreshData = async () => {
    let url=`v1/browse?limit=50`;
    const FromCollection = this.props.match.params.contract;
    const FromContractAddress = this.props.match.params.contractAddress;
    const searchString = new URLSearchParams(window.location.search).get('search');
    const collectionsQuery = new URLSearchParams(window.location.search).get('collections');

     // Price Filter Query Parameters
     const priceQuery = new URLSearchParams(window.location.search).get('priceFilter');
     const symbol = new URLSearchParams(window.location.search).get('symbol');
     const min = new URLSearchParams(window.location.search).get('min');
     const max = new URLSearchParams(window.location.search).get('max');
     
 
     
     if (FromCollection){
       url=`${url}&collection=${FromCollection}`;
     }
     if (FromContractAddress){
       url=`${url}&asset_contract_address=${FromContractAddress}`;
     } 
     if (searchString){
       url=`${url}&search=${searchString}`;
     } 
     if (collectionsQuery){
       url=`${url}&collections=${collectionsQuery}`;
     } 
     // Price Filter Query Parameters URL generator
     if (priceQuery){
       url=`${url}&priceFilter=1`;
     } 
     if (symbol){
       url=`${url}&symbol=${symbol}`;
     } 
     if (min){
       url=`${url}&min=${min}`;
     } 
     if (max){
       url=`${url}&max=${max}`;
     }


    const { data: Items } = await dbGET(url);
    let assetsData=[];
    if(Items)
    {
      Items.map((itemData)=>{
        assetsData.push(itemData)
    });}
   
    if (assetsData) {
      // Set data to state
      this.setState({
        MarketplaceItems: assetsData,
        sortingStatus: false,
        dataCount:assetsData.length,
        MarketplaceItems: assetsData,
        sotringData: assetsData,
        orignalData: assetsData,
        isLoaded: true,
      });
      }
    
  }


  filterCollectionName = (event) => {
    let checked = event.target.checked;
    let value = event.target.value;
    let dataAatribute = event.target.getAttribute("data-filters");
    let currentList = this.state.selectedCollection;

    this.setState({dataHasMore:true,dataEndMessage:"Yay! You have seen it all"});

    if (checked){
      currentList.push(value);
      this.setState({ selectedCollection:currentList});
    } else {
      currentList = currentList.filter((itm)=> itm!==value);
      this.setState({ selectedCollection:currentList});
    }
   
    
    if (currentList.length >0 ){
      this.props.history.push(`${window.location.pathname}?collections=${currentList}`)
    }else {
      this.props.history.push(`${window.location.pathname}`)
    }
    let resultArray = this.storeFilterdArray(checked, value, dataAatribute);
    this.refreshData();
  };

  
  searchCollection = (e)=>{
    const FromCollection = this.props.match.params.contract;
    const collectionsQuery = new URLSearchParams(window.location.search).get('collections');
    const word = e.target.value;
    if(word){
      var result = this.state.oldCollection.filter((itm) => itm.name.toLowerCase().includes(word.toLowerCase()));
      this.setState({
        collections: result,
      });
    }else{
      this.setState({
        collections: this.state.oldCollection,
      });
    }
    // Making Collections checkboxes checked
    if (FromCollection){
      let newCollections = this.state.collections;
      let index = newCollections.findIndex(el => el.slug === FromCollection);
      let isChecked =document.getElementsByName("col-"+index)[0].checked;
      if (!isChecked){
        document.getElementsByName("col-"+index)[0].click();
      }
    }
    if (collectionsQuery){
      let newCollections = this.state.collections;
      let colQuery=collectionsQuery.split(",");
      colQuery.forEach((itm)=>{
        let index = newCollections.findIndex(el => el.slug === itm);
        let isChecked =document.getElementsByName("col-"+index)[0].checked;
        if (isChecked){
          document.getElementsByName("col-"+index)[0].click();
        }
        //this.storeFilterdArray(true, itm, "col-"+index);
      });
    }
    this.setState({dataHasMore:true,dataEndMessage:"Yay! You have seen it all"});
  }
  sortByAssets = (e) => {
    if (this.state.applyFilterStatus) {
      this.sortArrayElement(this.state.filtersData, e.target.value);
    } else {
      this.sortArrayElement(this.state.orignalData, e.target.value);
    }
  };
  handleClicksOpen = (event, newValue) => {
    document.getElementById("maxSliderVal").value = newValue;
    this.sortingBypriceSlide();
  };
  clearFilter = (v) => {
    // alert(v);
    document.getElementsByName(v)[0].click();
    let newButtonObj = filterButtons.filter(function (el, index, filterstest) {
      // console.log(el.name);
      return el.name != v;
    });
    // console.log(newButtonObj);
    this.setState({ filterButtonsState: newButtonObj }, () => {
      filterButtons = this.state.filterButtonsState;
    });
  };



  componentDidMount() {
    this.state = {
      isActive: false,
    };

    getFavorites();

    const getCollections = async () => {
      let url = `v1/collections`;
      const { data: collectionItems } = await dbGET(url);
      console.log("🚀 ~ file: MarketplaceCards.js ~ line 468 ~ MarketplaceCards ~ getCollections ~ collections", collectionItems)
   
      if(collectionItems)
      {
        this.setState({
          collections: collectionItems,
          oldCollection: collectionItems,
        });
      }
      
  
     
  }

  getCollections();

    const getData = async () => {
      let url=`v1/browse?limit=50`;
      const FromCollection = this.props.match.params.contract;
      const FromContractAddress = this.props.match.params.contractAddress;
      const searchString = new URLSearchParams(window.location.search).get('search');
      const collectionsQuery = new URLSearchParams(window.location.search).get('collections');
      
       // Price Filter Query Parameters
       const priceQuery = new URLSearchParams(window.location.search).get('priceFilter');
       const symbol = new URLSearchParams(window.location.search).get('symbol');
       const min = new URLSearchParams(window.location.search).get('min');
       const max = new URLSearchParams(window.location.search).get('max');
       this.setState({currencySymbol:symbol,priceMax:max,priceMin:min});
       
       if (FromCollection){
         url=`${url}&collection=${FromCollection}`;
       }
       if (FromContractAddress){
         url=`${url}&asset_contract_address=${FromContractAddress}`;
       } 
       if (searchString){
         url=`${url}&search=${searchString}`;
       } 
       if (collectionsQuery){
         url=`${url}&collections=${collectionsQuery}`;
       } 
       // Price Filter Query Parameters URL generator
       if (priceQuery){
         url=`${url}&priceFilter=1`;
       } 
       if (symbol){
         url=`${url}&symbol=${symbol}`;
       } 
       if (min){
         url=`${url}&min=${min}`;
       } 
       if (max){
         url=`${url}&max=${max}`;
       }
      

      
      const { data: Items } = await dbGET(url);
      let assetsData=[];
      if(Items)
      {
        Items.map((itemData)=>{
          assetsData.push(itemData)
      });}
     
      if(assetsData.length < 50){
        this.setState({dataHasMore:false});
      }
      if(assetsData.length == 0){
        this.setState({dataEndMessage:"No Records Found!"});
      }

      if (assetsData) {
        // Set data to state
        this.setState({
          MarketplaceItems: assetsData,
          sortingStatus: false,
          dataCount:assetsData.length,
          MarketplaceItems: assetsData,
          sotringData: assetsData,
          orignalData: assetsData,
          isLoaded: true,
        });

        // Making Collections checkboxes checked
        if (FromCollection){
          let newCollections = this.state.collections;
          let index = newCollections.findIndex(el => el.slug === FromCollection);
          document.getElementsByName("col-"+index)[0].click();
        }
        if (collectionsQuery){
          let newCollections = this.state.collections;
          let colQuery=collectionsQuery.split(",");
          colQuery.forEach((itm)=>{
            let index = newCollections.findIndex(el => el.slug === itm);
            document.getElementsByName("col-"+index)[0].click();
            //this.storeFilterdArray(true, itm, "col-"+index);
          });
        }


        }
      
    };
   

    const getDataRedudent = () => {
      getData().then((itm)=>{
        console.log("FKM Browse Got Data")
      }).catch((err)=>{
        console.log("FKM Browse Error Retrying in 2 Sec", err)
        setTimeout(function(){ getDataRedudent(); }, 2000);
  
      });
    }
    getDataRedudent();

  
  

    
  }

  render() {
    const { classes } = styles;
    const unique = (value, index, self) => {
      return self.indexOf(value) === index;
    };
    const filterPrice = () =>{
      let priceMax = this.state.priceMax;
      let priceMin = this.state.priceMin;
      let currencySymbol = this.state.currencySymbol;
      let url = `${window.location.pathname}?priceFilter=1&symbol=${currencySymbol}`;
      if (priceMin ){
        url=`${url}&min=${priceMin}`;
        this.props.history.push(url);
      }
      if (priceMax ){
        url=`${url}&max=${priceMax}`;
        this.props.history.push(url);
      }
      this.refreshData();
    }
    const loadMore = async () => {
      let url=`v1/browse?offset=${this.state.dataOffset}&limit=50`;
      this.setState({dataOffset:this.state.dataOffset+50});
      
      const FromCollection = this.props.match.params.contract;
      const FromContractAddress = this.props.match.params.contractAddress;
      const searchString = new URLSearchParams(window.location.search).get('search');
      const collectionsQuery = new URLSearchParams(window.location.search).get('collections');

      // Price Filter Query Parameters
      const priceQuery = new URLSearchParams(window.location.search).get('priceFilter');
      const symbol = new URLSearchParams(window.location.search).get('symbol');
      const min = new URLSearchParams(window.location.search).get('min');
      const max = new URLSearchParams(window.location.search).get('max');
  
      
      if (FromCollection){
        url=`${url}&collection=${FromCollection}`;
      }
      if (FromContractAddress){
        url=`${url}&asset_contract_address=${FromContractAddress}`;
      } 
      if (searchString){
        url=`${url}&search=${searchString}`;
      } 
      if (collectionsQuery){
        url=`${url}&collections=${collectionsQuery}`;
      } 
      // Price Filter Query Parameters URL generator
      if (priceQuery){
        url=`${url}&priceFilter=1`;
      } 
      if (symbol){
        url=`${url}&symbol=${symbol}`;
      } 
      if (min){
        url=`${url}&min=${min}`;
      } 
      if (max){
        url=`${url}&max=${max}`;
      } 



      const { data: Items } = await dbGET(url);
      let assetsData=[];
      if(Items)
      {
        Items.map((itemData)=>{
          assetsData.push(itemData)
      });}

      // if(dataOffset == 50 && assetsData.length < 50){
      //   this.setState({dataHasMore:false,dataEndMessage:"Yay! You have seen it all"});
      // }
      
      if (assetsData) {
        this.setState({
          sortingStatus: false,
          MarketplaceItems: [...this.state.MarketplaceItems,...assetsData],
          dataCount:this.state.MarketplaceItems.length,
          isLoaded: true,
        });
       
      } else {
        // error
      }
    };

    const handleChange = (event) => {
      this.setState({ currencySymbol: event.target.value });
    };
    const handleMinChange = (event) => {
      this.setState({ priceMin: event.target.value });
    };
    const handleMaxChange = (event) => {
      this.setState({ priceMax: event.target.value });
    };
    
    

    const clearAllFilters = () => {
      this.setState({
        sortingFilterStatus: false,
        collectionFilterStatus: false,
        applyFilterStatus: false,
        MarketplaceItems: this.state.orignalData,
        sotringData: this.state.orignalData,
      });
      resultArray = [];
      statesArray = [];
      // var inputs = document.getElementsByName('collectionCheckBox');
      this.state.filterButtonsState.map(function (singleElement) {
        document.getElementsByName(singleElement.name)[0].click();
        // return singleElement.name;
      });
      this.setState({dataHasMore:true,dataEndMessage:"Yay! You have seen it all"});
    };
    return (
      <div className="container-cmd" >
        <Container>
          {!this.state.isLoaded ? (
            <div style={{ position: "absolute",
              top:"0px",
              left:"0px",
              width: "100%",
              height: "100%",
              zIndex: "999999"}}>

             <div
              style={{
                position: "relative",
                left: "calc(50% - 50px)",
                top:"40%"
              }}>
              <img src={LoaderImg} alt="Loading" />
            </div>
            </div>
            
          ) : (
            <Row>
              
              <Col className="filterMob"  lg={{ span: 3 }}>
                    <div style={{position:'fixed'}}  className="tabslink-top">
                      <Row className="filters-top">
                        <Col lg={{ span: 7 }} xs={{ span: 8 }}>
                          <a >FILTERS</a>
                        </Col>
                      </Row>
                      <div lg={{ span: 3 }} style={{maxWidth:'max-width: calc(67rem / 4)',position:'fixed',overflow:'auto',height:'100%',marginBottom:'200px',paddingRight:'9px'}} className="tabslink-top">
                      <div className="status-accordion">
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>Status</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <FormGroup>
                              {/* <FormControlLabel
                                control={
                                  <Checkbox
                                    id="new_123"
                                    // onClick={this.searchCollection}
                                    inputProps={{
                                      "data-filters": "new_arrival",
                                    }}
                                    value="new_arrival"
                                    name="new_arrival"
                                    color="primary"
                                  />
                                }
                                label="New"
                              /> */}
                              <FormControlLabel
                                control={
                                  <Checkbox
                                    // onClick={this.searchCollection}
                                    inputProps={{ "data-filters": "buy_now" }}
                                    name="buy_now"
                                    value="buy_now"
                                    color="primary"
                                    checked={true}
                                  />
                                }
                                label="Buy Now"
                              />

                              {/* <FormControlLabel
                                control={
                                  <Checkbox
                                    onClick={this.sortByFilterArray}
                                    inputProps={{
                                      "data-filters": "on_auction",
                                    }}
                                    name="on_auction"
                                    value="on_auction"
                                    color="primary"
                                  />
                                }
                                label="On Auction"
                              /> */}
                              {/* <FormControlLabel
                                control={
                                  <Checkbox
                                    name="has_offers"
                                    onClick={this.sortByFilterArray}
                                    inputProps={{
                                      "data-filters": "has_offers",
                                    }}
                                    value="has_offers"
                                    color="primary"
                                  />
                                }
                                label="Has Offers"
                              /> */}
                            </FormGroup>
                          </AccordionDetails>
                        </Accordion>
                      </div>
                      <Row>
                        <Col lg={{ span: 12 }}>
                          <div className="price-accordion">
                            <Accordion defaultExpanded>
                              <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                              >
                              <Typography>Price</Typography>
                              </AccordionSummary>
                              <AccordionDetails>
                                <Row>
                                  <Col lg={{ span: 12 }}>
                                    <FormGroup>
                                      <FormControl variant="outlined">
                                      
                                        <Select
                                          value={this.state.currencySymbol}
                                          onChange={handleChange}
                                        >
                                          <MenuItem value={"ETH"}>Ethereum</MenuItem>
                                          <MenuItem value={"USD"}>USD</MenuItem>
                                        </Select>
                                      </FormControl>
                                      <div className={"input-amount"}>
                                        <input
                                          type="number"
                                          placeholder="Min"
                                          id="sliderStartPrice"
                                          style={{ marginRight: "5px" }}
                                          value={this.state.priceMin}
                                          onChange={handleMinChange}
                                        />
                                        <b>To</b>
                                        <input
                                          type="number"
                                          placeholder="Max"
                                          id="maxSliderVal"
                                          style={{ marginLeft: "5px" }}
                                          value={this.state.priceMax}
                                          onChange={handleMaxChange}
                                        />
                                      </div>
                                      <Button
                                        onClick={filterPrice}
                                        style={{ marginTop: "5px" }}
                                        variant="outlined"
                                        color="primary"
                                      >
                                        Apply
                                      </Button>
                                      {/* <Slider
                                        aria-labelledby="range-slider"
                                        size="small"
                                        disabled={this.state.sliderStatus}
                                        // {...this.state.sliderStatus?'disabled':'disabled'}
                                        // defaultValue={this.state.sliderVal}
                                        defaultValue={[this.state.startPrice]}
                                        max={this.state.endPrice}
                                        onChange={this.handleClicksOpen}
                                        valueLabelDisplay="auto"
                                      /> */}
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                        </Col>
                      </Row>

                      <div style={{marginBottom:'120px',textTransform:'capitalize'}} className="collection-accordion">
                        <Accordion defaultExpanded>
                          <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                          >
                            <Typography>Collections</Typography>
                          </AccordionSummary>
                          <AccordionDetails>
                            <FormGroup style={{marginTop:15}}>
                            <div >
                            <SearchIcon style={{position: 'absolute',fontSize:'18px',fill: 'grey',zIndex: 1,top: '60px',left:'8px',}} />
                            <input  onKeyUp={this.searchCollection} placeholder="Search"  style={{top:'50px'}} class="leading-4 p-2 focus:ring-indigo-500 focus:border-indigo-500 block w-full shadow-sm sm:text-base border border-gray-300 rounded-md" />
                            </div>
                              {this.state.collections.map((item, index) => (
                                <FormControlLabel
                                  key={index}
                                  style={{ display: "block" }}
                                  control={
                                    <Checkbox
                                      inputProps={{
                                        "data-filters": "col-" + index,
                                      }}
                                      onClick={this.filterCollectionName}
                                      value={item.slug}
                                      name={"col-" + index}
                                      color={"primary"}
                                      // checked={item.checked ? true : false}
                                    />
                                  }
                                  label={item?truncateString(item.name, 20) : ""}
                                />
                              ))}
                             
                            </FormGroup>
                          </AccordionDetails>
                        </Accordion>
                        </div>
                      </div>
                    </div>
                  
                
              </Col>
             
              <Col lg={{ span: 9 }} onScroll={console.log('sc')}>
                <div className="search-bar col-lg-9">
                  <Row>
                    <Col lg={{ span: 4 }}>
                      <div className="clear-all-buttons">
                        <ul>
                          {this.state.filterButtonsState.map((item, index) => (
                            <li key={index}>
                              <div>
                                <Button
                                  variant="outlined"
                                  color="primary"
                                  style={{ color: "gray", cursor: "auto" }}
                                >
                                  {item.value.replace("_", " ")}{" "}
                                  <span
                                    style={{
                                      marginBottom: "0px",
                                      marginLeft: "10px",
                                      color: "blue",
                                      cursor: "pointer",
                                      backgroundColor: "lightgray",
                                      borderRadius: "50%",
                                      padding: "1px",
                                      fontSize: "0px",
                                    }}
                                    onClick={() => this.clearFilter(item.name)}
                                  >
                                    {" "}
                                    <ClearIcon />
                                  </span>
                                </Button>
                              </div>
                            </li>
                          ))}
                          <li>
                            {this.state.applyFilterStatus ? (
                              <div className="status-accordion">
                                <Button
                                  onClick={clearAllFilters}
                                  variant="outlined"
                                  style={{
                                    border: "none",
                                    textTransform: "capitalize",
                                    color: "#0d6efd",
                                  }}
                                >
                                  Clear All FILTERS
                                </Button>
                              </div>
                            ) : (
                              ""
                            )}
                          </li>
                        </ul>
                      </div>
                    </Col>

                    <Col lg={{ span: 2 }}>
                      <div className="form-group has-search">
                        <span className="fa fa-search form-control-feedback"></span>
                        <select
                          onChange={this.sortByAssets}
                          className="form-control"
                          placeholder="Sort By"
                        >
                          <option value="0">Sort By:</option>
                          <option value="n_o">Created: New to Old</option>
                          <option value="o_n">Created: Old to New</option>
                          <option value="r_s">Recently Sold</option>
                          {/* <option value="e_s">Ending Soon</option> */}
                          <option value="l_h">Price: Low to High</option>
                          <option value="h_l">Price: High to Low</option>
                          {/* <option value="m_p">Most Popular</option> */}
                        </select>
                      </div>
                    </Col>
                    <Col lg={{ span: 2 }}>
                    <Grid container spacing={2} direction="column" alignItems="center" >
      <Grid item>
      <ToggleButtonGroup size="small" exclusive >
      <ToggleButton onClick={()=>this.showList('big')} value="left">
      <img src={grid} />
          </ToggleButton>
          <ToggleButton onClick={()=>this.showList('small')} value="justify">
            <ViewModuleIcon style={{color:'black'}} />
          </ToggleButton>
    </ToggleButtonGroup>
    </Grid>
    </Grid>
                    </Col>
                  </Row>
                </div>
                {/* <Row >
                <Col lg={{span: 12}}>
                <div className="clear-all-buttons">
                      <ul>
                        <li>
                        <Button variant="outline-info">On Auction <span> <ClearIcon /></span></Button>
                        </li>
                        <li>
                        <Button variant="outline-info">Buy Now <span> <ClearIcon /></span></Button>
                        </li>
                        <li>
                          <Link to="#">
                            Clear All
                          </Link>
                        </li>
                      </ul>
                    </div>
                </Col>
              </Row> */}
                
                  {!this.state.isLoaded ? (
                    <div
                      className="loading-icons"
                      style={{
                        textAlign: "center",
                        justifyContent: "center",
                        display: "inline-block",
                      }}
                    >
                      <Row>
                        <Col lg={{ span: 6 }}>
                          <CircularProgress disableShrink />
                        </Col>
                      </Row>
                    </div>
                  ) : (
                    <div style={{marginTop:"100px"}}>
                        {!this.state.isLoaded ? (
                          <div style={{ position: "absolute",
                          top:"0px",
                          left:"0px",
                          width: "100%",
                          height: "100%",
                          zIndex: "999999"}}>
            
                         <div
                          style={{
                            position: "relative",
                            left: "calc(50% - 50px)",
                            top:"40%"
                          }}>
                          <img src={LoaderImg} alt="Loading" />
                        </div>
                        </div>
                        ) : (

                          <InfiniteScroll
                            dataLength={this.state.MarketplaceItems.length}
                            next={loadMore}
                            hasMore={this.state.dataHasMore}
                            loader={<p>Loading...</p>}
                            endMessage={
                              <p style={{ textAlign: "center" }}>
                                <b>{this.state.dataEndMessage}</b>
                              </p>
                            }
                          >
                            <Grid container >
                              {this.state.MarketplaceItems.map((item) => (
                              <Grid item sm={2} md={this.state.listShow?4:3} >
                                <AssetCard
                                  asset={item}
                                  key={item.asset_contract.address + "/" + item.token_id}
                                />
                                </Grid>
                                ))}
                            </Grid>
                           
                          </InfiniteScroll>
                          

                          
                        )}
                      
                    
                  </div>
                    
                  )}
                
              </Col>
            </Row>
          )}
        </Container>
      </div>
    );
  }
}
export default withRouter(MarketplaceCards);

