import React from "react";
import "../../styles/style.css";

function SingleImage(props) {
  return (
    <>
      <img src={props.image} />
    </>
  );
}

export default SingleImage;
