import MetaMaskOnboarding from '@metamask/onboarding';
import React from 'react';
import Meta from "../assets/meta.png";
import { Button } from '@material-ui/core';
import {setMetamaskAccounts} from "../webServices/loginServices";
import LoaderGif from "../assets/Rolling.gif"
import metamaskGif from "../assets/Metamask.gif"

const forwarderOrigin = 'https://mintjar.com';
const ONBOARD_TEXT = 'Install MetaMask!';
const CONNECT_TEXT = 'Connect Metamask';
const CONNECTED_TEXT = 'Connected';

export function OnboardingButton() {
  const [buttonText, setButtonText] = React.useState(ONBOARD_TEXT);
  const [isUnlocked, setIsUnlocked] = React.useState(true);
  const [isDisabled, setDisabled] = React.useState(false);
  const [action, setAction] = React.useState("");
  const [accounts, setAccounts] = React.useState([]);
  

React.useEffect (() => {
  MetaMaskClientCheck();
},[]);

  //Created check function to see if the MetaMask extension is installed
  const isMetaMaskInstalled = () => {
    //Have to check the ethereum binding on the window object to see if it's installed
    const { ethereum } = window;
    return Boolean(ethereum && ethereum.isMetaMask);
  };
  const isMetamaskUnlocked =async ()=>{
      return await window.ethereum._metamask.isUnlocked();
  }
  const installMetamask = () => {
    //On this object we have startOnboarding which will start the onboarding process for our end user
    // onboarding.startOnboarding();
    const onboarding = new MetaMaskOnboarding();
    onboarding.startOnboarding();

  };

  const connectMetamask = async () => {
    try {
      // Will open the MetaMask UI
      // You should disable this button while the request is pending!
      // startOnboarding();
      await window.ethereum.request({ method: 'eth_requestAccounts' });
    } catch (error) {
      
      console.error(error);
    }
  };

  const MetaMaskClientCheck = async () => {
    //Now we check to see if Metmask is installed
    if (!isMetaMaskInstalled()) {
      //If it isn't installed then install it
      setButtonText(ONBOARD_TEXT);
      
      setAction("installMetamask");
    } else {
      let unlock = await isMetamaskUnlocked();
      console.log("🚀  MetaMaskClientCheck ~ unlock", unlock)
      //If MetaMask is installed we check if it's isUnlocked
      if(unlock){
        setIsUnlocked(true);
        setButtonText(CONNECT_TEXT);
        setAction("connectMetamask");
        console.log("🚀 ~ file: metamaskButton.js ~ line 70 ~ MetaMaskClientCheck ~ accounts", accounts)
        if (window.ethereum._state.accounts.length > 0) {
          
          setAction("alreadyConnected");
          setButtonText(CONNECT_TEXT);
          setIsUnlocked(true);
        }
      }else {
        // Show Message to unlock metamask
        setAction("connectMetamask");
        setButtonText(CONNECT_TEXT);
        setIsUnlocked(false);
        // alert('Please unlock metamask via clicking on the fox icon')
      }
      
    }
  };


  const onClick = ()=>{
    MetaMaskClientCheck();
    switch (action) {
      case "installMetamask":
        installMetamask()
        break;
      case "connectMetamask":
        connectMetamask()
        break;
      case "alreadyConnected":
        if (window.ethereum._state.accounts.length > 0) {
          setMetamaskAccounts(window.ethereum._state.accounts);
        }
        break;
      
    }
  };
  
  React.useEffect(() => {
    function handleNewAccounts(newAccounts) {
      setAccounts(newAccounts);
      setMetamaskAccounts(newAccounts);
      // window.location.href = window.location.href;
    }
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      window.ethereum.on('accountsChanged', handleNewAccounts);
      MetaMaskClientCheck();
      
    }
  }, []);

  
  return (
    <div>
      {isUnlocked ? "" : <div style={{display:"inline-grid"}}><b >Please Unlock Metamask like below to continue sign-in</b><img src={metamaskGif} style={{width:"224px",marginBottom:"20px"}}></img></div>}
    {isUnlocked ? (
      <Button variant="contained" disabled={isDisabled} onClick={onClick}
      style={{ fontSize: "14px" }}>
      <img
        src={Meta}
        style={{ marginRight: 20, width: "20px" }}
      />
      {buttonText}
  
      </Button>
    ) : ""
    }
    
    
    </div>
    
  );
}
export default OnboardingButton;