import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import OceanAppFooterDemo from "../containers/footer/Footer";
import PrimarySearchAppBar from "../containers/header/Header";
import { withRouter } from "react-router";
// import MarketPlacedata from '../componets/MarketPlacedata';
import MarketplaceCards from "../containers/MarketplaceCards";

function MarketPlace() {
  return (
    <div>
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg">
          <PrimarySearchAppBar />
          {/* <ImagePickes /> */}
          <MarketplaceCards />
        </Container>
      </React.Fragment>
      <div >
      {/* <OceanAppFooterDemo /> */}
      </div>
    </div>
  );
}

export default withRouter(MarketPlace);
