import React from "react";
import OnboardingButton from "./metamaskButton";

function tempTest () {
    return (
        <div>
          <OnboardingButton></OnboardingButton>
        </div>
      );
    }


export default tempTest;