import React, { useState, useEffect} from "react";
import * as Web3 from 'web3'
import { OpenSeaPort, Network } from 'opensea-js';
import {
  Container,
  Row,
  Col,
  Form,
  Modal,
  InputGroup,
  FormControl,
  Tab,
  Nav,
} from "react-bootstrap";
import { Button } from "@material-ui/core";
import ImportExportIcon from "@material-ui/icons/ImportExport";
// import "node_modules/lib/bootstrap2-toggle.css";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Switch from "react-switch";
import MuiAlert from '@material-ui/lab/Alert';
import { Snackbar } from "@material-ui/core";
import TimePicker from "react-time-picker";
import Listings from "../componets/Listings";
import ListingsCard from "../componets/cards/ListingsCard";
import AssetCard from "../componets/cards/AssetCard";
import Previews from "../componets/cards/PerviewCard";
import LoaderImg from "../assets/simpleLoader.svg";
import axios from "axios";
import { getSeaport } from "../webServices/openseaAPI";
import { withRouter } from "react-router";
import {getWeb3} from "../webServices/loginServices";
import { windowLocation } from "../helpers/common";
import { logListTrade } from "../webServices/tradeLog";
// import {Slider} from '@material-ui/lab/Slider';
import "../styles/style.css";
const expirationDateSlots = [
  {
    value: Math.round(Date.now() / 1000 + 60 * 60 * 24 * 3),
    label: "In 3 days",
  },
  {
    value: Math.round(Date.now() / 1000 + 60 * 60 * 24 * 5),
    label: "In 5 days",
  },
  {
    value: Math.round(Date.now() / 1000 + 60 * 60 * 24 * 7),
    label: "In 7 days",
  },
  {
    value: Math.round(Date.now() / 1000 + 60 * 60 * 24 * 7 * 2),
    label: "In 2 weeks",
  },
];

const useStyles = makeStyles((theme) => ({
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
      width: "20ch",
    },
  },
}));



function Assetsell(props) {

  function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }
  const [value, onChange] = useState("10:00");
  const contract = props.match.params.contract;
  const token = props.match.params.token;
  const classes = useStyles();
  const [currency, setCurrency] = useState("EUR");
  
  const [open, setOpen] = useState(false);
  const [notificationMessage, setNotificationMessage] = useState("");
  const [notificationType, setNotificationType] = useState("");
  const [showProgress, setShowProgress] = useState("");
  const [assetData, setAssetData] = useState();

  
  const handleShowProgress = () => setShowProgress(true);
  const handleShowProgressClose = () => setShowProgress(false);

  const handleNotificationClick = (msg, type) => {
    setOpen(true);
    if (typeof msg === 'object') {
      setNotificationMessage(JSON.stringify(msg));
    }else {
      setNotificationMessage(msg);
    }
    setNotificationType(type);
  };

  const handleNotificationClose = () => {
    setOpen(false);
  };

  const fetchAssetData = async (token, contract) => {
    const seaport = await getSeaport();
    const Asset = await seaport.api.getAsset({
      tokenAddress:contract,
      tokenId:token
    });
    setAssetData(Asset);
  };
  
  useEffect(()=>{
    fetchAssetData(token,contract);
  },[])

  
  //-------------------------
  const [includeEndPrice, setIncludeEndPrice] = useState(false);
  const [scheduleFutureTime, setScheduleFutureTime] = useState(false);
  const [startPrice, setStartPrice] = useState(0);
  const [endPrice, setEndPrice] = useState(0);
  const [expireDate, setExpireDate] = useState(Math.round(Date.now() / 1000 + 60 * 60 * 24 * 3));
  const [futureDate, setFutureDate] = useState();
  //-------------------------
  const [minimumBid, setMinimumBid] = useState(0);
  const [reservePrice, setReservePrice] = useState(0);
  const [expirationDate, setExpirationDate] = useState();
  const [expirationDateTime, setExpirationDateTime] = useState();
  const [expirationDays, setExpirationDays] = useState();
  const [userAssets, setUserAssets] = useState();
  const [isLoaded, setIsLoaded] = useState(false);
  const [bundledItems, setBundledItems] = useState([]);
  const [assets, setAssets] = useState([]);
  const [isBundleSelected, setIsBundleSelected] = useState(false);
  const [bundleName, setBundleName] = useState("");
  const [bundleDescription, setBundleDescription] = useState("");
  const [bundleExternalLink, setBundleExternalLink] = useState("");


  const addItemToBundle = (item) =>{
    var index = bundledItems.findIndex(x => x.name==item.name); 
    var assetsIndex = assets.findIndex(x => x.tokenId==item.tokenId); 
    // here you can check specific property for an object whether it exist in your array or not
    if (index === -1) {
      bundledItems.push(item)
    } else {
      if (bundledItems.indexOf(item) > -1) {
        bundledItems.splice(index, 1);
      }
    }

    if (assetsIndex === -1) {
      assets.push({tokenId:item.token,tokenAddress:item.collections})
    } else {
      if (assets.indexOf({tokenId:item.token,tokenAddress:item.collections}) > -1) {
        assets.splice(assetsIndex, 1);
      }
    }
    
    console.log("🚀 ~ file: Assetsell.js ~ line 120 ~ addItemToBundle ~ bundledItems", bundledItems)
    console.log("🚀 ~ file: Assetsell.js ~ line 131 ~ addItemToBundle ~ assets", assets)
    
  }
  

  const usdRate=2898.85;
  
  const [checked, setChecked] = useState(false);
  const handleToggleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  const [check, setCheck] = useState(false);
  const handleToggleChanged = (nextCheck) => {
    setCheck(nextCheck);
  };
  const [checks, setChecks] = useState(false);
  const handleToggleChanges = (nextChecks) => { 
    setChecks(nextChecks);
  };

  const getUserAssets = async () => {
    let accessToken = JSON.parse(localStorage.getItem("authObj")).AccessToken;
    let url = `${process.env.REACT_APP_API_SERVER_URL}/assets`;
    let config = {
      headers: { Authorization: `Bearer ${accessToken}`}
    };
    await axios
    .get(`${url}`, config)
    .then((res) => {
      console.log(res.data);
      setUserAssets(res.data);
      setIsLoaded(true);
    })
    .catch((err) => {
      console.log(err);
      setIsLoaded(false);
    });
  }
  
  const handleExpirationDate= (val)=>{
    setExpirationDate(val);
    var today = new Date().getTime();
    var Difference_In_Time =  ( Date.parse(val) - today);
    setExpirationDateTime(Difference_In_Time);
    setExpirationDays((Math.round(Difference_In_Time / (1000 * 3600 * 24))));
  }
  

  const createSellOrder = async (tokenAddress,tokenId)=> {
    let accountAddress = JSON.parse(localStorage.getItem("authObj")).WalletAddress;

    if (!startPrice) {
      handleNotificationClick("Please enter start price","error");
      return 
    }
    if (includeEndPrice) {
      if (!endPrice) {
        handleNotificationClick("Please enter end price","error");
        return
      }
      if (!expireDate) {
        handleNotificationClick("Please select expire date","error");
        return
      }
    }
    
    if (startPrice < endPrice  ) {
      handleNotificationClick("Ending price must be less than or equal to the starting price","error");
      return
    }
    if (!startPrice) {
      handleNotificationClick("Please select expire date","error");
      return
    }

    if (scheduleFutureTime) {
      if (!expireDate) {
        handleNotificationClick("Please select expire date","error");
        return
      }
    }
    //-------------------------
    const providerWeb3= await getWeb3();
    const seaport = new OpenSeaPort(providerWeb3.currentProvider, {
        networkName: process.env.REACT_APP_NETWORK==="rinkeby" ? Network.Rinkeby : Network.Main
    });
    let expirationTime = expireDate;
  
    if (!includeEndPrice && !scheduleFutureTime ){
      handleShowProgress();
       seaport.createSellOrder({
        asset: {
          tokenId,
          tokenAddress,
        },
        accountAddress,
        startAmount: startPrice
      }).then((res)=>{
        logListTrade(assetData.name ? assetData.name : assetData.assetContract.name,assetData.assetContract.name,assetData.imageUrl ? assetData.imageUrl : assetData.assetContract.imageUrl,tokenAddress,tokenId,res.transactionHash,startPrice).then(()=>{
          handleNotificationClick("Sell Order Created Successfully","success");
          handleShowProgressClose();
          windowLocation(`${window.location.origin}/asset/${tokenAddress}/${tokenId}`);
        });
      }).catch((e)=>{
        handleShowProgressClose();
        handleNotificationClick(e,"error");
        console.log(e);
  
      });
    }
      if (includeEndPrice ){
        handleShowProgress();
         seaport.createSellOrder({
          asset: {
            tokenId,
            tokenAddress,
          },
          accountAddress,
          startAmount: startPrice,
          endAmount: endPrice,
          expirationTime
        }).then((res)=>{
          logListTrade(assetData.name ? assetData.name : assetData.assetContract.name,assetData.assetContract.name,assetData.imageUrl ? assetData.imageUrl : assetData.assetContract.imageUrl,tokenAddress,tokenId,res.transactionHash,startPrice).then(()=>{
            handleNotificationClick("Sell Order Created Successfully","success");
            handleShowProgressClose();
            windowLocation(`${window.location.origin}/asset/${tokenAddress}/${tokenId}`);
          });
        }).catch((e)=>{
          handleShowProgressClose();
          handleNotificationClick(e,"error");
          console.log(e);
    
        });
      }
      if (!includeEndPrice && scheduleFutureTime ){
        handleShowProgress();
        seaport.createSellOrder({
          asset: {
            tokenId,
            tokenAddress,
          },
          accountAddress,
          startAmount: startPrice,
          expirationTime
        }).then((res)=>{
          logListTrade(assetData.name ? assetData.name : assetData.assetContract.name,assetData.assetContract.name,assetData.imageUrl ? assetData.imageUrl : assetData.assetContract.imageUrl,tokenAddress,tokenId,res.transactionHash,startPrice).then(()=>{
            handleNotificationClick("Sell Order Created Successfully","success");
            handleShowProgressClose();
            windowLocation(`${window.location.origin}/asset/${tokenAddress}/${tokenId}`);
          });
        }).catch((e)=>{
          handleShowProgressClose();
          handleNotificationClick(e,"error");
          console.log(e);
    
        });
      } 
  }

  const createSellOrderHigiestBid = async (tokenAddress,tokenId)=> {
    const providerWeb3= await getWeb3();
    const seaport = new OpenSeaPort(providerWeb3.currentProvider, {
        networkName: process.env.REACT_APP_NETWORK==="rinkeby" ? Network.Rinkeby : Network.Main
    });

    let accountAddress = JSON.parse(localStorage.getItem("authObj")).WalletAddress;
    console.log("🚀 ~ file: Assetsell.js ~ line 265 ~ createSellOrderHigiestBid ~ expirationDate", expirationDate)
    let expirationTime= Math.round(Date.now() / 1000 + 60 * 60 * 24 * expirationDays);
    console.log("🚀 ~ file: Assetsell.js ~ line 260 ~ createSellOrderHigiestBid ~ expirationDateTime", expirationTime)
    
    const paymentTokenAddress = (await seaport.api.getPaymentTokens({ symbol: 'MANA'})).tokens[0].address;

    if (!minimumBid) {
      handleNotificationClick("Please set minimum bid","error");
      return 
    }
    
    
    if (!reservePrice) {
      handleNotificationClick("Please set reserved price","error");
      return
    }
    if (!expirationDays) {
      handleNotificationClick("Please select expiration date","error");
      return
    }
    //-------------------------
    
  
      handleShowProgress();
       seaport.createSellOrder({
        asset: {
          tokenId,
          tokenAddress,
        },
        accountAddress,
        startAmount: minimumBid,
        reserveAmount: reservePrice,
        expirationTime,
        paymentTokenAddress,
        waitForHighestBid: true

      }).then((res)=>{
        logListTrade(assetData.name ? assetData.name : assetData.assetContract.name,assetData.assetContract.name,assetData.imageUrl ? assetData.imageUrl : assetData.assetContract.imageUrl,tokenAddress,tokenId,res.transactionHash,startPrice).then(()=>{
          handleNotificationClick("Sell Order Created Successfully","success");
          handleShowProgressClose();
          windowLocation(`${window.location.origin}/asset/${tokenAddress}/${tokenId}`);
        });
      }).catch((e)=>{
        handleShowProgressClose();
        handleNotificationClick(e,"error");
        console.log(e);
  
      });   
  }

  const createSellOrderBundle = async (tokenAddress,tokenId)=> {
    let accountAddress = JSON.parse(localStorage.getItem("authObj")).WalletAddress;
    
    if (!bundleName) {
      handleNotificationClick("Please enter name of bundle","error");
      return 
    }

    if (!startPrice) {
      handleNotificationClick("Please enter start price","error");
      return 
    }
    if (includeEndPrice) {
      if (!endPrice) {
        handleNotificationClick("Please enter end price","error");
        return
      }
      if (!expireDate) {
        handleNotificationClick("Please select expire date","error");
        return
      }
    }
    
    if (startPrice < endPrice  ) {
      handleNotificationClick("Ending price must be less than or equal to the starting price","error");
      return
    }
    if (!startPrice) {
      handleNotificationClick("Please select expire date","error");
      return
    }

    if (scheduleFutureTime) {
      if (!expireDate) {
        handleNotificationClick("Please select expire date","error");
        return
      }
    }
    //-------------------------
    const providerWeb3= await getWeb3();
    const seaport = new OpenSeaPort(providerWeb3.currentProvider, {
        networkName: process.env.REACT_APP_NETWORK==="rinkeby" ? Network.Rinkeby : Network.Main
    });
    let expirationTime = expireDate;
  
    if (!includeEndPrice && !scheduleFutureTime ){
      handleShowProgress();
       seaport.createBundleSellOrder({
        bundleName, bundleDescription, bundleExternalLink,
        assets,
        accountAddress,
        startAmount: startPrice
      }).then((res)=>{
        logListTrade(assetData.name ? assetData.name : assetData.assetContract.name,assetData.assetContract.name,assetData.imageUrl ? assetData.imageUrl : assetData.assetContract.imageUrl,tokenAddress,tokenId,res.transactionHash,startPrice).then(()=>{
          handleNotificationClick("Sell Order Created Successfully","success");
          handleShowProgressClose();
          windowLocation(`${window.location.origin}/asset/${tokenAddress}/${tokenId}`);
        });
      }).catch((e)=>{
        handleShowProgressClose();
        handleNotificationClick(e,"error");
        console.log(e);
  
      });
    }
      if (includeEndPrice ){
        handleShowProgress();
         seaport.createBundleSellOrder({
          bundleName, bundleDescription, bundleExternalLink,
          assets,
          accountAddress,
          startAmount: startPrice,
          endAmount: endPrice,
          expirationTime
        }).then((res)=>{
          logListTrade(assetData.name ? assetData.name : assetData.assetContract.name,assetData.assetContract.name,assetData.imageUrl ? assetData.imageUrl : assetData.assetContract.imageUrl,tokenAddress,tokenId,res.transactionHash,startPrice).then(()=>{
            handleNotificationClick("Sell Order Created Successfully","success");
            handleShowProgressClose();
            windowLocation(`${window.location.origin}/asset/${tokenAddress}/${tokenId}`);
          });
        }).catch((e)=>{
          handleShowProgressClose();
          handleNotificationClick(e,"error");
          console.log(e);
    
        });
      }
      if (!includeEndPrice && scheduleFutureTime ){
        handleShowProgress();
        seaport.createBundleSellOrder({
          bundleName, bundleDescription, bundleExternalLink,
          assets,
          accountAddress,
          startAmount: startPrice,
          expirationTime
        }).then((res)=>{
          logListTrade(assetData.name ? assetData.name : assetData.assetContract.name,assetData.assetContract.name,assetData.imageUrl ? assetData.imageUrl : assetData.assetContract.imageUrl,tokenAddress,tokenId,res.transactionHash,startPrice).then(()=>{
            handleNotificationClick("Sell Order Created Successfully","success");
            handleShowProgressClose();
            windowLocation(`${window.location.origin}/asset/${tokenAddress}/${tokenId}`);
          });
         
        }).catch((e)=>{
          handleShowProgressClose();
          handleNotificationClick(e,"error");
          console.log(e);
    
        });
      } 
  }

  

  return (
    <>
      <Container>
        <Row>
          <br/>
          <br/>
          <br/>
          <Col lg={{ span: 12 }}>
            <div className="asset-title collection-title">
              <p>Sell Method</p>
            </div>
          </Col>
        </Row>
        <Row>
          <Tab.Container defaultActiveKey="first">
            <Row className="tabs-background-theme">
              <Col sm={4}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="first">
                      <Col lg={{ span: 12 }}>
                        <div className="multiple-pickers create-assets">
                        <Button variant="outlined" style={{width:'100%'}}   size="large">
                        <p className="sellButton">Set Price
                        <br />
                        <label style={{cursor:'pointer'}}>Sell at a fixed or declining price</label>
                        </p>
                       
        </Button>
                          {/* <Button
                            variant="outline-secondary"
                            className="light-shadow "
                            type="radio"
                          >
                            <p>Set Price</p>
                            <label>Sell at a fixed or declining price</label>
                          </Button> */}
                        </div>
                      </Col>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={4}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="second">
                      <Col lg={{ span: 12 }}>
                        <div className="multiple-pickers create-assets">
                        <Button variant="outlined" style={{width:'100%'}}   size="large">
                        <p className="sellButton">Highest Bid
                        <br />
                        <label style={{cursor:'pointer'}}>Sell at a fixed or declining price</label>
                        </p>
                       
                      </Button>
                          {/* <Button
                            variant="outline-secondary"
                            className="light-shadow "
                            type="radio"
                          >
                            <p>Higiest Bid</p>
                            <label>Sell at a fixed or declining price</label>
                          </Button> */}
                        </div>
                      </Col>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col sm={4}>
                <Nav variant="pills" className="flex-column">
                  <Nav.Item>
                    <Nav.Link eventKey="third" onClick={getUserAssets}>
                      <Col lg={{ span: 12 }}>
                        <div className="multiple-pickers create-assets">
                        <Button variant="outlined" style={{width:'100%'}}   size="large">
                        <p className="sellButton">Bundle
                        <br />
                        <label style={{cursor:'pointer'}}>Sell at a fixed or declining price</label>
                        </p>
                       
                      </Button>
                          {/* <Button
                            variant="outline-secondary"
                            className="light-shadow "
                            type="radio"
                          >
                            <p>Bundle</p>
                            <label>Sell at a fixed or declining price</label>
                          </Button> */}
                        </div>
                      </Col>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
            </Row>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Row className="summary-price">
                    <Col lg={{ span: 8 }}>
                      <div className="price-section">
                        <Row>
                          <Col lg={{ span: 9 }}>
                            {includeEndPrice ? (
                              <div className="count-price">
                                <p>Starting Price</p>
                                <label>Set an initial price.</label>
                              </div>
                            ) : (
                              <div className="count-price">
                                <p>Price</p>
                                <label>
                                  Will be on sell until you transfer this item or
                                  cancel it.
                                </label>
                              </div>
                            )}
                          </Col>
                          <Col lg={{ span: 3 }}>
                            <div className="count-number">
                              <Form>
                                <InputGroup>
                                  <InputGroup.Text>
                                    <ImportExportIcon />
                                  </InputGroup.Text>
                                  <FormControl
                                    id="inlineFormInputGroupUsername"
                                    type="number"
                                    value={startPrice}
                                    placeholder="0"
                                    min={0}
                                    onChange={ (e) => setStartPrice(e.target.value)}
                                  />
                                </InputGroup>
                              </Form>
                            </div>
                          </Col>
                        </Row>
                        <hr />
                        <Row>
                          <Col lg={{ span: 9 }} xs={{ span: 9 }}>
                            {includeEndPrice ? (
                              <div className="count-price">
                                <p>Include ending price</p>
                              </div>
                            ) : (
                              <div className="count-price">
                                <p>Include ending price</p>
                                <label>
                                  Adding and ending price will low this listing
                                  to expire or for a priced to be reduced until.
                                </label>
                              </div>
                            )}
                          </Col>
                          <Col lg={{ span: 3 }} xs={{ span: 3 }}>
                            <div className="material-toggle">
                              <Switch
                                onChange={()=>{setIncludeEndPrice(!includeEndPrice)}}
                                checked={includeEndPrice}
                                className="react-switch"
                              />
                              <label htmlFor="toggle" className=""></label>
                            </div>

                           
                          </Col>
                        </Row>
                          
                        
                        {includeEndPrice ? (
                          <div>
                            <hr />
                          <Row>
                            <Col lg={{ span: 9 }}>
                              <div className="count-price">
                                <p>Ending Price</p>
                                <label>
                                  Must be less than or equal to the starting
                                  price. The price will be progress linearly to
                                  this amount until the expiration date.
                                </label>
                              </div>
                            </Col>
                            <Col lg={{ span: 3 }}>
                              <div className="count-number">
                                <Form>
                                  <InputGroup>
                                    <InputGroup.Text>
                                      <ImportExportIcon />
                                    </InputGroup.Text>
                                    <FormControl
                                      id="inlineFormInputGroupUsername"
                                      type="number"
                                      placeholder="0"
                                      min={0}
                                      onChange={ (e) => setEndPrice(e.target.value)}
                                    />
                                  </InputGroup>
                                </Form>
                              </div>
                            </Col>
                          </Row>
                           <hr/>
                           <Row>
                      <Col lg={{ span: 9 }} xs={{ span: 9 }}>
                              <div className="count-price">
                                <p>Expiration date</p>
                                <label>
                                Your listing will automatically end at this time. No need to cancel it!
                                </label>
                              </div>
                            </Col>
                            <Col lg={{ span: 3 }} xs={{ span: 12 }}>
                                <div className="dropdown-filter">
                                  <form
                                    className={classes.root}
                                    noValidate
                                    autoComplete="off"
                                  >
                                    <TextField
                                      id="outlined-select-currency-native"
                                      select
                                      
                                      onChange={(e)=>{setExpireDate(e.target.value)}}
                                      SelectProps={{
                                        native: true,
                                      }}
                                      variant="outlined"
                                    >
                                      {expirationDateSlots.map((option) => (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                          selected={option.value===expireDate ? true : false}
                                        >
                                          {option.label}
                                        </option>
                                      ))}
                                    </TextField>
                                  </form>
                                </div>
                              
                              
                                <div className="time-picks">
                                  <TimePicker
                                    onChange={onChange}
                                    value={value}
                                  />
                                </div>
                            </Col>
                      </Row>
                           
                           </div>
                          
                          
                        ) : (
                          <Row>
                           <span></span>
                          </Row>

                        )}
                       
                       {!includeEndPrice ? ( 
                      <div> 
                         <hr/>
                      <Row>
                      <Col lg={{ span: 9 }} xs={{ span: 9 }}>
                              <div className="count-price">
                                <p>Schedule for a future time</p>
                                <label>
                                  You Can schedule this listing to only be buy
                                  able at a future date.
                                </label>
                              </div>
                            </Col>
                            <Col lg={{ span: 3 }} xs={{ span: 12 }}>
                              <div className="material-toggle">
                                <Switch
                                  onChange={()=>{setScheduleFutureTime(!scheduleFutureTime)}}
                                  checked={scheduleFutureTime}
                                  className="react-switch"
                                />
                                <label className=""></label>
                              </div>
                              {scheduleFutureTime ? (
                                <div className="dropdown-filter">
                                  <form
                                    className={classes.root}
                                    noValidate
                                    autoComplete="off"
                                  >
                                    <TextField
                                      id="outlined-select-currency-native"
                                      select
                                      onChange={(e)=>{setExpireDate(e.target.value)}}
                                      SelectProps={{
                                        native: true,
                                      }}
                                      variant="outlined"
                                    >
                                      {expirationDateSlots.map((option) => (
                                        <option
                                          key={option.value}
                                          value={option.value}
                                          selected={option.value===expireDate ? true : false}
                                        >
                                          {option.label}
                                        </option>
                                      ))}
                                    </TextField>
                                  </form>
                                </div>
                              ) : (
                                <p></p>
                              )}
                              {scheduleFutureTime ? (
                                <div className="time-picks">
                                  <TimePicker
                                    onChange={onChange}
                                    value={value}
                                  />
                                </div>
                              ) : (
                                <div></div>
                              )}
                            </Col>
                      </Row>
                      </div>
                      ) : (
                        <div></div>
                      )}
                        
                        <div>
                          {checked ? (
                            <Row className="">
                              <Col lg={{ span: 9 }}>
                                <div className="count-price">
                                  <p>Expiration Date</p>
                                  <label>
                                    Your auction will automatically end at this
                                    time and the highest bidder will win. No
                                    need to cancel it..
                                  </label>
                                </div>
                              </Col>
                              <Col lg={{ span: 3 }}>
                                <div className="count-number">
                                  <Form>
                                    <InputGroup>
                                      <FormControl
                                        id="inlineFormInputGroupUsername"
                                        type="date"
                                        placeholder="1/1/21"
                                      />
                                    </InputGroup>
                                  </Form>
                                </div>
                              </Col>
                            </Row>
                          ) : (
                            <div></div>
                          )}
                        </div>
                      </div>
                    </Col>

                    <Col lg={{ span: 4 }}>
                      <div className="summary-section">
                        <div className="summary-text">Summary</div>

                        <hr />
                        
                        <Row>
                          <Col lg={{ span: 12 }}>
                            <div className="price-text">Price</div>
                          </Col>
                          <Col lg={{ span: 12 }}>
                            <div className="count-number">
                              <ImportExportIcon /> <label> {startPrice} ($ {startPrice * usdRate})</label>
                            </div>
                          </Col>
                          <Col lg={{ span: 12 }}>
                            <div className="multiple-pickers post-listing">
                              <Button
                                style={{marginBottom:'10px'}}
                                variant="outlined"
                                type="text"
                                onClick={()=>{createSellOrder(contract,token)}}
                              >
                                Post your listing
                              </Button>
                 

        {/* --------------------------------------------------------------------------------------------- */}
                            </div>
                            <hr />
                          </Col>
                          {/* <Col lg={{ span: 12 }}>
                            <div className="price-text">
                              Bounties
                              <label className="pure-material-slider">
                                <input type="range" min="0" max="100" />
                              </label>
                            </div>
                          </Col>
                          <Col lg={{ span: 12 }}>
                            <div className="text-descriptions">
                              Lorem ispum dolar sit amet, consetetur sending
                              data as well, consetetur sending data as well.
                            </div>
                            <hr />
                          </Col> */}
                          <Col lg={{ span: 12 }}>
                            <div className="price-text">Fees</div>
                            <div className="text-descriptions">
                              Lorem ispum dolar sit amet, consetetur sending
                              data as well, consetetur sending data as well.
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Row className="summary-price">
                    <Col lg={{ span: 8 }}>
                      <div className="price-section">
                        <Row className="row-define">
                          <Col lg={{ span: 9 }}>
                            <div className="count-price">
                              <p>Minimum Bid</p>
                              <label>Set your starting bid price.</label>
                            </div>
                          </Col>
                          <Col lg={{ span: 3 }}>
                            <div className="count-number">
                              <Form>
                                <InputGroup>
                                  <InputGroup.Text>
                                    <ImportExportIcon />
                                  </InputGroup.Text>
                                  <FormControl
                                    id="inlineFormInputGroupUsername"
                                    type="number"
                                    min={0}
                                    onChange={(e) => setMinimumBid(e.target.value)}
                                    placeholder="0"
                                  />
                                </InputGroup>
                              </Form>
                            </div>
                          </Col>
                        </Row>
                        <Row className="row-define">
                          <Col lg={{ span: 9 }}>
                            <div className="count-price">
                              <p>Reserve Price</p>
                              <label>
                                Create a hidden limit setting a reserve price.
                              </label>
                            </div>
                          </Col>
                          <Col lg={{ span: 3 }}>
                            <div className="count-number">
                              <Form>
                                <InputGroup>
                                  <InputGroup.Text>
                                    <ImportExportIcon />
                                  </InputGroup.Text>
                                  <FormControl
                                    id="inlineFormInputGroupUsername"
                                    type="number"
                                    min={0}
                                    onChange={(e) => setReservePrice(e.target.value)}
                                    placeholder="0"
                                  />
                                </InputGroup>
                              </Form>
                            </div>
                          </Col>
                        </Row>
                        <Row className="row-define">
                          <Col lg={{ span: 9 }}>
                            <div className="count-price">
                              <p>Expiration Date</p>
                              <label>
                                Your auction will automatically end at this time
                                and the highest bidder will win. No need to
                                cancel it..
                              </label>
                            </div>
                          </Col>
                          <Col lg={{ span: 3 }}>
                            <div className="count-number">
                              <Form>
                                <InputGroup>
                                  <FormControl
                                    id="inlineFormInputGroupUsername"
                                    type="date"
                                    min={new Date().toISOString().split('T')[0]} 
                                    placeholder="1/1/21"
                                    onChange={(e)=>{handleExpirationDate(e.target.value)}}
                                  />
                                </InputGroup>
                              </Form>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col lg={{ span: 4 }}>
                      <div className="summary-section">
                        <div className="summary-text">Summary</div>

                        <hr />
                        <Row>
                          <Col lg={{ span: 12 }}>
                          <div className="auction-end">
                          {expirationDays ? ("Auctions end in "+expirationDays+" days") : ("") }
                            </div>
                            

                            <div className="price-text">Minimum Bid</div>
                            <div className="count-number">
                              <ImportExportIcon /> <label> {minimumBid} ($ {minimumBid * usdRate})</label>
                            </div>
                          </Col>
                          <Col lg={{ span: 12 }}>
                            <div className="price-text">Reserve Amount</div>
                            <div className="count-number">
                              <ImportExportIcon /> <label> {reservePrice} ($ {reservePrice * usdRate})</label>
                            </div>
                          </Col>
                          <Col lg={{ span: 12 }}>
                            <div className="multiple-pickers post-listing">
                            <Button
                                style={{marginBottom:'10px'}}
                                variant="outlined"
                                type="text"
                                onClick={()=>createSellOrderHigiestBid(contract,token)}
                              >
                                Post your listing
                              </Button>
                              {/* <Button
                                variant="outline-secondary"
                                className="light-shadow"
                                type="text"
                                onClick={()=>createSellOrderHigiestBid(contract,token)}
                              >
                                Post your listing a
                              </Button> */}
                            </div>
                            <hr />
                          </Col>
                          {/* <Col lg={{ span: 12 }}> 
                            <div className="price-text">
                              Bounties
                              <label className="pure-material-slider">
                                <input type="range" min="0" max="100" />
                              </label>
                            </div>
                          </Col>
                          <Col lg={{ span: 12 }}>
                            <div className="text-descriptions">
                              Lorem ispum dolar sit amet, consetetur sending
                              data as well, consetetur sending data as well.
                            </div>
                            <hr />
                          </Col> */}
                          <Col lg={{ span: 12 }}>
                            <div className="price-text">Fees</div>
                            <div className="text-descriptions">
                              Lorem ispum dolar sit amet, consetetur sending
                              data as well, consetetur sending data as well.
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <Row className="summary-price">
                    <div className="">
                    <Row>
          <Col lg={{ span: 4 }}>
            {/* <div className="form-group has-search sort-search">
              <span className="fa fa-search form-control-feedback"></span>
              
              <select className="form-control" placeholder="Sort By">
                <option>Sort By:</option>
                <option>Created: New to Old</option>
                <option>Created: Old to New</option>
                <option>Recently Sold</option>
                <option>Ending Soon</option>
                <option>Price: Low to High</option>
                <option>Price: High to Low</option>
                <option>Most Popular</option>
              </select>
            </div> */}
            { !isBundleSelected && bundledItems ? (
            <Button
            variant="outlined-secondary"
            onClick={()=>{setIsBundleSelected(!isBundleSelected)}}
            >Select Bundle</Button>
            ) :
             "" 
            }
          </Col>
        </Row>
        
                    <Row>
                      {isLoaded && ! isBundleSelected ? (
                        userAssets.map((item, index) => (<Col lg={{span: 3}} onClick={()=>{addItemToBundle(item)}}><ListingsCard name={item.name} image={item.image} /></Col>))
                      )
                      : (
                        <span></span>
                      )}

                      { isBundleSelected ? (
                          <Col sm={12}>
                          <Row className="summary-price">
                                        <Col lg={{ span: 8 }}>
                                          <div className="price-section">
                                            <Row>
                                              <Col lg={{ span: 9 }}>
                                              <div className="count-price">
                                                    <p>Name of the bundle</p>
                                                    <label>Set a name for your bundle</label>
                                                  </div>
                                              </Col>
                                              <Col lg={{ span: 3 }}>
                                              <div className="count-number">
                                                  <Form>
                                                    <InputGroup>
                                                      <FormControl
                                                        id="inlineFormInputGroupUsername"
                                                        type="text"
                                                        value={bundleName}
                                                        onChange={ (e) => setBundleName(e.target.value)}
                                                        placeholder="Name of bundle"
                                                        
                                                      />
                                                    </InputGroup>
                                                  </Form>
                                                </div>
                                              </Col> 
                                              </Row> 
                                              <hr/>
                                              <Row>
                                              <Col lg={{ span: 9 }}>
                                                {includeEndPrice ? (
                                                  <div className="count-price">
                                                    <p>Starting Price</p>
                                                    <label>Set an initial price.</label>
                                                  </div>
                                                ) : (
                                                  <div className="count-price">
                                                    <p>Price</p>
                                                    <label>
                                                      Will be on sell until you transfer this item or
                                                      cancel it.
                                                    </label>
                                                  </div>
                                                )}
                                              </Col>
                                              <Col lg={{ span: 3 }}>
                                                <div className="count-number">
                                                  <Form>
                                                    <InputGroup>
                                                      <InputGroup.Text>
                                                        <ImportExportIcon />
                                                      </InputGroup.Text>
                                                      <FormControl
                                                        id="inlineFormInputGroupUsername"
                                                        type="number"
                                                        value={startPrice}
                                                        placeholder="0"
                                                        min={0}
                                                        onChange={ (e) => setStartPrice(e.target.value)}
                                                      />
                                                    </InputGroup>
                                                  </Form>
                                                </div>
                                              </Col>
                                            </Row>
                                            <hr />
                                            <Row>
                                              <Col lg={{ span: 9 }} xs={{ span: 9 }}>
                                                {includeEndPrice ? (
                                                  <div className="count-price">
                                                    <p>Include ending price</p>
                                                  </div>
                                                ) : (
                                                  <div className="count-price">
                                                    <p>Include ending price</p>
                                                    <label>
                                                      Adding and ending price will low this listing
                                                      to expire or for a priced to be reduced until.
                                                    </label>
                                                  </div>
                                                )}
                                              </Col>
                                              <Col lg={{ span: 3 }} xs={{ span: 3 }}>
                                                <div className="material-toggle">
                                                  <Switch
                                                    onChange={()=>{setIncludeEndPrice(!includeEndPrice)}}
                                                    checked={includeEndPrice}
                                                    className="react-switch"
                                                  />
                                                  <label htmlFor="toggle" className=""></label>
                                                </div>
                    
                                               
                                              </Col>
                                            </Row>
                                              
                                            
                                            {includeEndPrice ? (
                                              <div>
                                                <hr />
                                              <Row>
                                                <Col lg={{ span: 9 }}>
                                                  <div className="count-price">
                                                    <p>Ending Price</p>
                                                    <label>
                                                      Must be less than or equal to the starting
                                                      price. The price will be progress linearly to
                                                      this amount until the expiration date.
                                                    </label>
                                                  </div>
                                                </Col>
                                                <Col lg={{ span: 3 }}>
                                                  <div className="count-number">
                                                    <Form>
                                                      <InputGroup>
                                                        <InputGroup.Text>
                                                          <ImportExportIcon />
                                                        </InputGroup.Text>
                                                        <FormControl
                                                          id="inlineFormInputGroupUsername"
                                                          type="number"
                                                          placeholder="0"
                                                          min={0}
                                                          onChange={ (e) => setEndPrice(e.target.value)}
                                                        />
                                                      </InputGroup>
                                                    </Form>
                                                  </div>
                                                </Col>
                                              </Row>
                                               <hr/>
                                               <Row>
                                          <Col lg={{ span: 9 }} xs={{ span: 9 }}>
                                                  <div className="count-price">
                                                    <p>Expiration date</p>
                                                    <label>
                                                    Your listing will automatically end at this time. No need to cancel it!
                                                    </label>
                                                  </div>
                                                </Col>
                                                <Col lg={{ span: 3 }} xs={{ span: 12 }}>
                                                    <div className="dropdown-filter">
                                                      <form
                                                        className={classes.root}
                                                        noValidate
                                                        autoComplete="off"
                                                      >
                                                        <TextField
                                                          id="outlined-select-currency-native"
                                                          select
                                                          
                                                          onChange={(e)=>{setExpireDate(e.target.value)}}
                                                          SelectProps={{
                                                            native: true,
                                                          }}
                                                          variant="outlined"
                                                        >
                                                          {expirationDateSlots.map((option) => (
                                                            <option
                                                              key={option.value}
                                                              value={option.value}
                                                              selected={option.value===expireDate ? true : false}
                                                            >
                                                              {option.label}
                                                            </option>
                                                          ))}
                                                        </TextField>
                                                      </form>
                                                    </div>
                                                  
                                                  
                                                    <div className="time-picks">
                                                      <TimePicker
                                                        onChange={onChange}
                                                        value={value}
                                                      />
                                                    </div>
                                                </Col>
                                          </Row>
                                               
                                               </div>
                                              
                                              
                                            ) : (
                                              <Row>
                                               <span></span>
                                              </Row>
                    
                                            )}
                                           
                                           {!includeEndPrice ? ( 
                                          <div> 
                                             <hr/>
                                          <Row>
                                          <Col lg={{ span: 9 }} xs={{ span: 9 }}>
                                                  <div className="count-price">
                                                    <p>Schedule for a future time</p>
                                                    <label>
                                                      You Can schedule this listing to only be buy
                                                      able at a future date.
                                                    </label>
                                                  </div>
                                                </Col>
                                                <Col lg={{ span: 3 }} xs={{ span: 12 }}>
                                                  <div className="material-toggle">
                                                    <Switch
                                                      onChange={()=>{setScheduleFutureTime(!scheduleFutureTime)}}
                                                      checked={scheduleFutureTime}
                                                      className="react-switch"
                                                    />
                                                    <label className=""></label>
                                                  </div>
                                                  {scheduleFutureTime ? (
                                                    <div className="dropdown-filter">
                                                      <form
                                                        className={classes.root}
                                                        noValidate
                                                        autoComplete="off"
                                                      >
                                                        <TextField
                                                          id="outlined-select-currency-native"
                                                          select
                                                          onChange={(e)=>{setExpireDate(e.target.value)}}
                                                          SelectProps={{
                                                            native: true,
                                                          }}
                                                          variant="outlined"
                                                        >
                                                          {expirationDateSlots.map((option) => (
                                                            <option
                                                              key={option.value}
                                                              value={option.value}
                                                              selected={option.value===expireDate ? true : false}
                                                            >
                                                              {option.label}
                                                            </option>
                                                          ))}
                                                        </TextField>
                                                      </form>
                                                    </div>
                                                  ) : (
                                                    <p></p>
                                                  )}
                                                  {scheduleFutureTime ? (
                                                    <div className="time-picks">
                                                      <TimePicker
                                                        onChange={onChange}
                                                        value={value}
                                                      />
                                                    </div>
                                                  ) : (
                                                    <div></div>
                                                  )}
                                                </Col>
                                          </Row>
                                          </div>
                                          ) : (
                                            <div></div>
                                          )}
                                            
                                            <div>
                                              {checked ? (
                                                <Row className="">
                                                  <Col lg={{ span: 9 }}>
                                                    <div className="count-price">
                                                      <p>Expiration Date</p>
                                                      <label>
                                                        Your auction will automatically end at this
                                                        time and the highest bidder will win. No
                                                        need to cancel it..
                                                      </label>
                                                    </div>
                                                  </Col>
                                                  <Col lg={{ span: 3 }}>
                                                    <div className="count-number">
                                                      <Form>
                                                        <InputGroup>
                                                          <FormControl
                                                            id="inlineFormInputGroupUsername"
                                                            type="date"
                                                            placeholder="1/1/21"
                                                          />
                                                        </InputGroup>
                                                      </Form>
                                                    </div>
                                                  </Col>
                                                </Row>
                                              ) : (
                                                <div></div>
                                              )}
                                            </div>
                                          </div>
                                        </Col>
                    
                                        <Col lg={{ span: 4 }}>
                                          <div className="summary-section">
                                            <div className="summary-text">Summary</div>
                    
                                            <hr />
                                            
                                            <Row>
                                              <Col lg={{ span: 12 }}>
                                                <div className="price-text">Price</div>
                                              </Col>
                                              <Col lg={{ span: 12 }}>
                                                <div className="count-number">
                                                  <ImportExportIcon /> <label> {startPrice} ($ {startPrice * usdRate})</label>
                                                </div>
                                              </Col>
                                              <Col lg={{ span: 12 }}>
                                                <div className="multiple-pickers post-listing">
                                                <Button
                                style={{marginBottom:'10px'}}
                                variant="outlined"
                                type="text"
                                onClick={()=>createSellOrderBundle(contract,token)}
                              >
                                Post your listing
                              </Button>
                                                  {/* <Button
                                                    variant="outline-secondary"
                                                    className="light-shadow"
                                                    type="text"
                                                    onClick={()=>{createSellOrderBundle(contract,token)}}
                                                  >
                                                    Post your listing
                                                  </Button> */}
                                                </div>
                                                <hr />
                                              </Col>
                                              </Row>
                                                </div>
                                     
                                              </Col>
                                              </Row>
                                              </Col>
                      ) : ( 
                        <span></span>
                      )
                      
                      }

                    </Row>
                    <Row>
                      { isBundleSelected && bundledItems ? (
                          bundledItems.map((item, index) => (<Col lg={{span: 2}}><ListingsCard name={item.name} image={item.image}/></Col>))
                        )
                        : (
                          ""
                        )}
                    </Row>
                     
                    </div>
                  </Row>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Tab.Container>
          <Snackbar
        anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
        open={open}
        autoHideDuration={10000}
        onClose={handleNotificationClose}
        message={notificationMessage}
        key={'bottom right'}
      >
        <Alert onClose={handleNotificationClose} severity={notificationType}>
        {notificationMessage}
        </Alert>
      </Snackbar>
      {/* ----------------------------Show Progress Modal---------------------------------------------- */}

      <div className="popup-modals">
          <Modal show={showProgress}>
            <Modal.Header>
              <Modal.Title>PLEASE WAIT....</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ textAlign: "center" }}>
              <img src={LoaderImg} style={{height:'64px', width:'64px'}} alt="Loading" />
            </Modal.Body>
            {/* <Modal.Footer>
        </Modal.Footer> */}
          </Modal>
        </div>
        </Row>
      </Container>
    </>
  );
}

export default withRouter(Assetsell);
