import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
// import MobileStepper from "@material-ui/core/MobileStepper";
import { Typography, Paper } from "@material-ui/core";
import Button from "@material-ui/core/Button";
// import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
// import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import SwipeableViews from "react-swipeable-views";
import { autoPlay } from "react-swipeable-views-utils";
import Banner from "../../assets/banners/banner-1.svg"
import NFT from "../../assets/banners/banner1.png"
// import Banner from '../../assets/banners/banner.png';
// import Logo from '../../assets/logo.png';
const AutoPlaySwipeableViews = autoPlay(SwipeableViews);

const tutorialSteps = [
  {
    label: "",
    imgPath:Banner,
  }
];

const styles = (theme) => ({
  //   root: {
  //     maxWidth: "100vh",
  //     flexGrow: 1
  //   },
  header: {
    display: "flex",
    alignItems: "center",
    height: 100,
    // paddingLeft: theme.spacing.unit * 4,
    backgroundColor: theme.palette.background.default,
  },
  img: {
    // height: 300,
    display: "block",
    // maxWidth: "100vh",
    overflow: "hidden",
    width: "100%",
  },
  sliderWrap: {
    position: "relative",
  },
  calltoAction: {
    position: "absolute",
    textAlign: "center",
    width: "100%",
    bottom: 30,
    color: "#fff",
  },

  bannerTitle: {
    fontWeight: "bold",
    marginBottom: 30,
    fontSize: "1.5rem",
  },
});

class SwipeableTextMobileStepper extends React.Component {
  state = {
    activeStep: 0,
  };

  handleNext = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep + 1,
    }));
  };

  handleBack = () => {
    this.setState((prevState) => ({
      activeStep: prevState.activeStep - 1,
    }));
  };

  handleStepChange = (activeStep) => {
    this.setState({ activeStep });
  };

  render() {
    const { classes, theme } = this.props;
    const { activeStep } = this.state;
    // const maxSteps = tutorialSteps.length;
    return (
      <div className={classes.root}>
        <div className={classes.sliderWrap}>
          <Paper square elevation={0} className={classes.header}></Paper>
          <AutoPlaySwipeableViews
            axis={theme.direction === "rtl" ? "x-reverse" : "x"}
            index={activeStep}
            onChangeIndex={this.handleStepChange}
            enableMouseEvents
            interval={5000}
          >
            {tutorialSteps.map((step, index) => (
              <div key={step.label}>
                {Math.abs(activeStep - index) <= 2 ? (
                  <img
                    className={classes.img}
                    src={step.imgPath}
                    alt={step.label}
                  />
                ) : null}
              </div>
            ))}
          </AutoPlaySwipeableViews>
          <div className={classes.calltoAction}>
            <Typography className={classes.bannerTitle}>
              {tutorialSteps[activeStep].label}
            </Typography>
{/* 
            <Button classes={styles} variant={"contained"} color={"primary"}>
              Contained
            </Button> */}
          </div>
        </div>

        {/* <MobileStepper
          steps={maxSteps}
          position="static"
          activeStep={activeStep}
          className={classes.mobileStepper}
          nextButton={
            <Button
              size="small"
              onClick={this.handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              {theme.direction === "rtl" ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={this.handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === "rtl" ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
        /> */}
      </div>
    );
  }
}
SwipeableTextMobileStepper.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(
  SwipeableTextMobileStepper
);
