import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import AssetCard from "../componets/cards/AssetCard";
import { GET,dbGET } from "../webServices/fetch.js";
import { Row, Col } from "react-bootstrap";
import CircularProgress from '@material-ui/core/CircularProgress';
import Carousel from "react-multi-carousel";
import { truncateString } from "../helpers/strings";
import {ButtonGroup } from "../helpers/common";
import "react-multi-carousel/lib/styles.css";
import Button from "@restart/ui/esm/Button";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 575 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
  },
};
// import { GET, POST } from "../webServices/fetch.js";
class CustomerPicks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      CustomerPicks: [],
      isLoaded: false,
      wordsLength: 12,
    };
  }
  componentDidMount() {

    //let picks = "asset_contract_addresses=0xcc14dd8e6673fee203366115d3f9240b079a4930&asset_contract_addresses=0x6666f1122580156bfe6ad3123d24da42a336e143&asset_contract_addresses=0x381748c76f2b8871afbbe4578781cd24df34ae0d&asset_contract_addresses=0x49d320eea3a59d661fc08d8824b4c65cd09b4f49&asset_contract_addresses=0x725bfb25bd7eaf70193818b570f49359b6bcc66c&asset_contract_addresses=0x88b48f654c30e99bc2e4a1559b4dcf1ad93fa656&asset_contract_addresses=0xd94669bd2ae1bfa674ee9edf401a05e83e2cf0f7";
    //picks = encodeURI(picks);
    //let Ids = "token_ids=1&token_ids=2&token_ids=3&token_ids=4&token_ids=1&token_ids=1&token_ids=1&token_ids=1&token_ids=1";
    const getFeaturedData = async () => {
        const { data: Items } = await dbGET(`v1/assets/top`)
        let orderData=[];
        Items.forEach((itemData)=>{
          itemData.NFTs.forEach((itm)=>{
            
            orderData.push(itm)
          })
        });
       
        if (orderData) {
          // Set data to state
          this.setState({
            CustomerPicks: orderData,
            isLoaded: true,
          });
          // console.log(this.state.NFTsYouViewed);
          if (this.state.CustomerPicks) {
            
          }
        } else {
          // error
        }
      };
      const getDataRedudent = () => {
        getFeaturedData().then((itm)=>{
          console.log("FKM Top Ticks Got Data")
        }).catch((err)=>{
          console.log("FKM Error Retrying in 2 Sec", err)
          setTimeout(function(){ getDataRedudent(); }, 2000);
    
        });
      }
     
      getDataRedudent();
  }

  render() {
     const { Items, isLoaded } = this.state;
    return (
      <Grid sm={12}>
        <Typography variant="h3" component="h3" className={"grid_title"}>
          <Box fontSize={"24px"}>Top Picks</Box>
        </Typography>
        <div
          className="responsive-view homePageCarousel"
          style={{ marginTop: "0px", paddingTop: 0 }}
        >
           <Carousel
                            responsive={responsive}
                            arrows = {false}
                            customButtonGroup={<ButtonGroup
                                next={this.props.next}
                                previous={this.props.previous}
                                rest={this.props.rest}
                            />}
                        >
            {!this.state.isLoaded ? (
              <div className="loading-icons" style={{textAlign: "center", justifyContent: "center", display: "inline-block"}}>
                  <Row>
                  <Col lg={{ span: 11 }} xs={{ span: 12 }}>
                    <div class="loader-container">
                    <CircularProgress disableShrink />
                    </div>
                  </Col>
                  </Row>
                  
                  </div>
            ) : (
              this.state.CustomerPicks.map((item) => (
                <AssetCard
                  asset={item}
                  key={item.asset_contract.address + "/" + item.token_id}
                />
              ))
            )}
          </Carousel>
        </div>
      </Grid>
    );
  }
}
export default CustomerPicks;