import React from "react";
import { useEffect } from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import OceanAppFooterDemo from "../containers/footer/Footer";
import SwipeableTextMobileStepper from "../containers/content/BannerSlider";
import PrimarySearchAppBar from "../containers/header/Header";
import { getFavorites } from "../webServices/nftServices";
// import { GET, POST } from '../webServices/fetch.js';
import FeaturedCards from "../containers/FeaturedCards";
import CustomerPicks from "../containers/CustomerPicks";
import LiveAuctions from "../containers/LiveAuctions";
import NFTsYouViewed from "../containers/NFTsYouViewed";
import ExploreMore from "../containers/ExploreMore";

function HomeDataReady() {
useEffect(() => {
  getFavorites();
}, [])
const RecentViews = JSON.parse(localStorage.getItem("RecentViews"));
  return (
    <div>
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg">
          <PrimarySearchAppBar />
          <SwipeableTextMobileStepper />
          <FeaturedCards />
          <CustomerPicks />
          {/* <LiveAuctions /> */}
          <ExploreMore />
          { RecentViews && RecentViews.length > 0 && <NFTsYouViewed /> }
          {/* <NFTsYouViewed /> */}
        </Container>
      </React.Fragment>
      <OceanAppFooterDemo />
    </div>
  );
}

export default HomeDataReady;
