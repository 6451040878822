export const truncateString = (string, limit) => {
  if (string) {
    if (string.length > limit) {
      return string.substring(0, limit) + "...";
    } else {
      return string;
    }
  }
};
export const formatAmnt = (amnt) => {
  var formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
  });
  
  return formatter.format(amnt);
};
export const truncateAddress = (string) => {
  var startChars,endChars,result;
  if (string) {
    startChars=string.substring(0, 6)
    endChars=string.substring((string.length-4))
    result=startChars+"...."+endChars;
    return result
  }
};

export const formateAddress = (string) => {
  var result;
  if (string) {
  if (string!=="NullAddress") {
    result=string.substring(2, 8)
    return result.toUpperCase()
  }else {
    return string
  }
}
};

