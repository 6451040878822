/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import Web3 from "web3";
import MetaMaskOnboarding from '@metamask/onboarding';
import Cookies from "js-cookie";
import axios from "axios";
import detectEthereumProvider from '@metamask/detect-provider';
export const infraWeb3 = async () => {
  const web3 = new Web3(new Web3.providers.HttpProvider('https://rinkeby.infura.io/v3/e0c0129197fa4fa0bdbdd530842f6134'))
  return web3;
}
export const getWeb3 = async () => {
  if (window.ethereum) {
    const web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    return web3;
  } else if (window.web3) {
    const web3 = window.web3;
    console.log("Injected web3 detected.");
    return web3;
  } else {
    const provider = new Web3.providers.HttpProvider("http://127.0.0.1:8545");
    const web3 = new Web3(provider);
    console.log("No web3 instance injected, using Local web3.");
    return web3;
  }
};

export const getNetworkName =  (chainID) => {
  const networks = {
      1 : "Mainnet",
      3 : "Ropsten Testnet",
      4 : "Rinkeby Testnet",
      5 : "Goerli Testnet",
      42 : "Kovan Testnet",
      97 : "Binance",
      80001 : "Polygon"
    }
    return networks[chainID];
  }
  
  export const getNetwork = async () => {
    const web3 = await getWeb3();
    const chainId = await web3.eth.net.getId();
    return getNetworkName(chainId);
  //  return chainId;
  }
  
export const getWalletBalance = async () => {
    const web3 = await getWeb3();
    const accounts = await web3.eth.getAccounts();
    return await web3.eth.getBalance(accounts[0], function(err, result) {
      if (err) {
        console.log(err)
        return 0;
      } else {
        
        return web3.utils.fromWei(result, "ether").toString();
      }
    });

}

export const loginViaMetamask = async () => {
  if (window.ethereum) {
    const web3 = new Web3(window.ethereum);
    await window.ethereum.enable();
    return web3;
  } else if (window.web3) {
    const web3 = window.web3;
    console.log("Injected web3 detected.");
    return web3;
  } else {
    const provider = new Web3.providers.HttpProvider("http://127.0.0.1:8545");
    const web3 = new Web3(provider);
    // window.open("https://metamask.io/download");
    console.log("No web3 instance injected, using Local web3.");
    return web3;
  }
  // throw Error('Nope. Try again.');
};

export const onboardMetamask = async () => {
  const onboarding = new MetaMaskOnboarding();
  if (MetaMaskOnboarding.isMetaMaskInstalled()) {
    window.ethereum
      .request({ method: 'eth_requestAccounts' })
      .then((newAccounts) => setMetamaskAccounts(newAccounts));
  }else {
    MetaMaskOnboarding.startOnboarding();
  }
}
export const isMetaMaskInstalled = () => {
  //Have to check the ethereum binding on the window object to see if it's installed
  const { ethereum } = window;
  return Boolean(ethereum && ethereum.isMetaMask);
};
export const setMetamaskAccounts = async (newAccounts) => {
   //-------------------------
   const balance = await getWalletBalance();
   const serverURL = process.env.REACT_APP_API_SERVER_URL;
   const headers = "";
   const data = { provider: "metamask", walletAddress: newAccounts[0] };
    await axios(`${serverURL}/auth`, {
    method: "POST",
    mode: "no-cors",
    headers,
    data,
    }).then((res) => {
    console.log("🚀 ~ file: loginServices.js ~ line 101 ~ setMetamaskAccounts ~ res", res)
   setLoginStorage(
     res.data.profile.userId,
     res.data.profile.name,
     res.data.profile.email ? res.data.profile.email :'N/A T',
     "Matamask",
     true,
     newAccounts[0],
     balance,
     res.data.profile.profileImage ? res.data.profile.profileImage : '',
     res.data.profile.bio ? res.data.profile.bio : 'N/A T',
     res.data.accessToken,
     res.data.refreshToken
   );
   console.log(res);
 })
 .catch((err) => {
   console.log("err");
   console.log(err);
 });
   //-------------------------
}



export const loginMetamask = async () => {



  try {
    const web3 = await loginViaMetamask();
    const accounts = await web3.eth.getAccounts();
    const balance = await web3.eth.getBalance(accounts[0]);
    if (accounts) {
      //-------------------------
      const serverURL = process.env.REACT_APP_API_SERVER_URL;
      const headers = "";
      const data = { provider: "metamask", walletAddress: accounts[0] };
  await axios(`${serverURL}/auth`, {
    method: "POST",
    mode: "no-cors",
    headers,
    data,
  })
    .then((res) => {
      setLoginStorage(
        res.data.profile.userId,
        res.data.profile.name,
        res.data.profile.email ? res.data.profile.email :'N/A T',
        "Matamask",
        true,
        accounts[0],
        balance,
        res.data.profile.profileImage ? res.data.profile.profileImage : '',
        res.data.profile.bio ? res.data.profile.bio : 'N/A T',
        res.data.accessToken,
        res.data.refreshToken
      );
      console.log(res);
    })
    .catch((err) => {
      console.log("err");
      console.log(err);
    });
      //-------------------------
    }
    const networkId = await web3.eth.net.getId();
  } catch (error) {
    // Catch any errors for any of the above operations.
    window.open("https://metamask.io/download");
    console.error(error);
  }
};
export const connectToWallet = async (from='/') => {
  try {
    // alert(1);
    const web3 = await loginViaMetamask();
    const accounts = await web3.eth.getAccounts();
    const accessToken = JSON.parse(localStorage.getItem("authObj")).AccessToken;
    const userId = JSON.parse(localStorage.getItem("authObj")).UserId;
    const balance = await web3.eth.getBalance(accounts[0]);
    // alert(accessToken);
    // const balance = await web3.eth.getBalance(accounts[0]);
    if (accounts) {
      //-------------------------
      const serverURL = process.env.REACT_APP_API_SERVER_URL;
      const headers = {
        Authorization: `Bearer ${accessToken}`,
      };
      const data = { walletAddress: accounts[0] };
  await axios(`${serverURL}/users/${userId}`, {
    method: "PATCH",
    mode: "no-cors",
    headers,
    data,
  })
    .then((res) => {
      updateWalletAdress(accounts[0],balance);
      from = localStorage.getItem("prevUrl");
      window.location.href = from;
    })
    .catch((err) => {
      console.log("err");
      console.log(err);
    });
      //-------------------------
    }
    const networkId = await web3.eth.net.getId();
  } catch (error) {
    // Catch any errors for any of the above operations.
    window.open("https://metamask.io/download");
    console.error(error);
  }
};
export const setLoginCookie = (
  Username = "Unknown",
  Email = "NA",
  AuthType = "",
  Token = "",
  IsWalletConnected = false,
  WalletAddress = "",
  WalletBalance = 0,
  ProfileImage = "",
  Bio = "NA",
  AccessToken = "",
  RefreshToken = ""
) => {
  const expires = 60 * 60 * 1000;
  const inOneHour = new Date(new Date().getTime() + expires);
  const AuthObj = {
    Username: Username,
    Email: Email,
    AuthType: AuthType,
    Token: Token,
    IsWalletConnected: IsWalletConnected,
    WalletAddress: WalletAddress,
    WalletBalance: WalletBalance,
    ProfileImage: ProfileImage,
    Bio: Bio,
  };
  Cookies.set("auth", AuthObj, { expires: inOneHour });
  window.location.reload();
};
export const setLoginStorage = (
  UserId = "",
  Username = "Unknown",
  Email = "NA",
  AuthType = "",
  IsWalletConnected = false,
  WalletAddress = "",
  WalletBalance = 0,
  ProfileImage = "",
  Bio = "NA",
  AccessToken = "",
  RefreshToken = ""
) => {
  localStorage.setItem("isAuthenticated", "true");
  const AuthObj = {
    UserId,
    Username: Username,
    Email: Email,
    AuthType: AuthType,
    IsWalletConnected: IsWalletConnected,
    WalletAddress: WalletAddress,
    WalletBalance: WalletBalance,
    ProfileImage: ProfileImage,
    Bio: Bio,
    AccessToken: AccessToken,
    RefreshToken: RefreshToken,
  };
  localStorage.setItem("authObj", JSON.stringify(AuthObj));
  document.location.href = "/";
};

export const updateLoginStorage = (
  Username = "Unknown",
  Email = "NA",
  Bio = "NA",
  ProfileImage = ""
) => {
  var currentData=JSON.parse(localStorage.getItem("authObj"));
  const AuthObj = {
    UserId:currentData.UserId,
    Username: Username,
    Email: Email,
    AuthType: currentData.AuthType,
    IsWalletConnected: currentData.IsWalletConnected,
    WalletAddress: currentData.WalletAddress,
    WalletBalance: currentData.WalletBalance,
    ProfileImage: currentData.ProfileImage ?? '',
    Bio: Bio,
    AccessToken: currentData.AccessToken,
    RefreshToken: currentData.RefreshToken,
  };
  localStorage.setItem("authObj", JSON.stringify(AuthObj));
};
export const updateWalletAdress = (
  WalletAddress, WalletBalance 
) => {
  var currentData=JSON.parse(localStorage.getItem("authObj"));
  const AuthObj = {
    UserId:currentData.UserId,
    Username: currentData.Username,
    Email: currentData.Email,
    AuthType: currentData.AuthType,
    IsWalletConnected: true,
    WalletAddress: WalletAddress,
    WalletBalance: WalletBalance,
    ProfileImage: currentData.ProfileImage ? currentData.ProfileImage : "",
    Bio: currentData.Bio,
    AccessToken: currentData.AccessToken,
    RefreshToken: currentData.RefreshToken,
  };
  localStorage.setItem("authObj", JSON.stringify(AuthObj));
  window.location.reload();
};
export const updateAvatarLoginStorage = (
  ProfileImage = ""
) => {
  var currentData=JSON.parse(localStorage.getItem("authObj"));
  const AuthObj = {
    UserId:currentData.UserId,
    Username: currentData.Username,
    Email: currentData.Email,
    AuthType: currentData.AuthType,
    IsWalletConnected: currentData.IsWalletConnected,
    WalletAddress: currentData.WalletAddress,
    WalletBalance: currentData.WalletBalance,
    ProfileImage: ProfileImage,
    Bio: currentData.Bio,
    AccessToken: currentData.AccessToken,
    RefreshToken: currentData.RefreshToken,
  };
  localStorage.setItem("authObj", JSON.stringify(AuthObj));
};

export const isAuthenticated = () => {
  // alert(JSON.stringify(xyz));
  const isAuth = localStorage.getItem("isAuthenticated");
  let result = false;
  if (isAuth == "true") {
    result = true;
  }
  return result;
};

export const IsWalletConnected = () => {
  const authObj = localStorage.getItem("authObj");
  if (authObj) {
    let auth = JSON.parse(authObj);
    return auth.IsWalletConnected
  } else {
    return false;
  }
}

export const connectViaMetaMask = () => {
  const authObj = localStorage.getItem("authObj");
  let result = false;
  if(authObj){
    let cruntObj = JSON.parse(authObj);
    if(cruntObj.WalletAddress && cruntObj.WalletAddress !== 'NAN'){
      result = true;
    }
  }
  return result;
};
export const responseGoogleSuccess = async (response) => {
  const profile = response.profileObj;
  const serverURL = process.env.REACT_APP_API_SERVER_URL;
  const headers = "";
  const data = { provider: "google", id_token: response.tokenId };

  console.log(JSON.stringify(response));

  await axios(`${serverURL}/auth`, {
    method: "POST",
    mode: "no-cors",
    headers,
    data,
  })
    .then((res) => {
      //console.log('success');
      // console.log(JSON.stringify(res.data));
      setLoginStorage(
        res.data.profile.userId,
        res.data.profile.name,
        res.data.profile.email,
        "Google",
        false,
        "NAN",
        0,
        res.data.profile.profileImage,
        res.data.profile.bio,
        res.data.accessToken,
        res.data.refreshToken
      );
    })
    .catch((err) => {
      console.log("err");
      console.log(err);
    });
};

export const responseGoogleFailed = (response) => {};
export const logout = () => {
  localStorage.removeItem("isAuthenticated");
  localStorage.removeItem("authObj");
  document.location.href = "/";
};
export const getAuthObj = () => {
  let defaultProfile = {
    Username: "NAN",
    Email: "NA",
    AuthType: "",
    Token: "",
    IsWalletConnected: false,
    WalletAddress: "",
    WalletBalance: 0,
    ProfileImage: "NAN",
    Bio: "",
  };
  if (localStorage.getItem("authObj")) {
    return JSON.parse(localStorage.getItem("authObj"));
  } else {
    return defaultProfile;
  }
};

export const loginViaEmail = (email, password) => {
  const serverURL = process.env.REACT_APP_API_SERVER_URL;
  const headers = "";
  const data = { email: email, password: password };
  return axios(`${serverURL}/auth`, {
    method: "POST",
    headers,
    data,
  });
};

export const signupViaEmail = (name, email, password) => {
  const serverURL = process.env.REACT_APP_API_SERVER_URL;
  const headers = "";
  const data = { name: name, email: email, password: password };
  return axios(`${serverURL}/users`, {
    method: "POST",
    headers,
    data,
  });
};
