import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import OceanAppFooterDemo from "../containers/footer/Footer";
import PrimarySearchAppBar from "../containers/header/Header";
import WalletId from "../componets/WalletId";

function ConnectWallet() {
  return (
    <div>
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg">
          <PrimarySearchAppBar />
          {/* <ImagePickes /> */}
          <WalletId />
        </Container>
      </React.Fragment>
      <OceanAppFooterDemo />
    </div>
  );
}

export default ConnectWallet;
