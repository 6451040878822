import React from "react";
import { withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";
import AssessmentIcon from "@material-ui/icons/Assessment";
import FileCopyOutlinedIcon from "@material-ui/icons/FileCopyOutlined";
import CopyToClipBoard from "../../componets/CopyToClipBoard";

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
});
const buttonStyle = {
  width: "100%",
  borderTop: "none",
  border: "1px solid rgb(229, 232, 235)",
  marginTop: "-2px",
  borderTopLeftRadius: "0px",
  borderTopRightRadius: "0px",
};

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

export default function CustomizedDialogs(props) {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button
        variant="contained"
        disableElevation
        style={buttonStyle}
        color="primary"
        onClick={handleClickOpen}
      >
        Add Fund
      </Button>
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle
          style={{ textAlign: "center", padding: "5%", fontWeight: "bold" }}
          id="customized-dialog-title"
          onClose={handleClose}
        >
          Deposit ETH from your exchange
        </DialogTitle>
        <DialogContent dividers>
          <p
            style={{ textAlign: "center", padding: "2px" }}
            className="Blockreact__Block-sc-1xf18x6-0 Textreact__Text-sc-1w94ul3-0 igwhzn cCfKUE"
          >
            Deposit ETH from your exchange to the following address:
          </p>
          <div className="AddressBox">
            <div className="AddressText">{props.wallet}</div>
            <button aria-label="Copy" className="AddressCopyIcon" type="button">
            <CopyToClipBoard copyText={"props.wallet"} displayText={<FileCopyOutlinedIcon fontSize={"small"} />} />
              
            </button>
          </div>
          <p
            style={{ textAlign: "center", padding: "2px" }}
            className="Blockreact__Block-sc-1xf18x6-0 Textreact__Text-sc-1w94ul3-0 igwhzn cCfKUE"
          >
            Only send ETH or any other ERC-20 token to this address.
          </p>

          <Typography gutterBottom>
            <div className="mdc-layout-grid" style={{ padding: "12px 104px" }}>
              <Button
                variant="contained"
                style={{ width: "100%", height: 50 }}
                color="dark"
                onClick={handleClose}
              >
                <AssessmentIcon style={{ marginRight: 10 }} /> I have made my
                deposit
              </Button>
            </div>
          </Typography>
        </DialogContent>
      </Dialog>
    </div>
  );
}
