/* eslint-disable no-undef */
/* eslint-disable react/no-direct-mutation-state */
import React from "react";
import {
  Container,
  Row,
  Col,
  Modal,
  Button,
  Form,
  InputGroup,
  FormControl,
} from "react-bootstrap";

import { Snackbar } from "@material-ui/core";

import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import EmailIcon from "@material-ui/icons/Email";
import CameraAltIcon from "@material-ui/icons/CameraAlt";
import DesktopWindowsIcon from "@material-ui/icons/DesktopWindows";
import EventNoteIcon from "@material-ui/icons/EventNote";
import { Alert, AlertTitle } from "@material-ui/lab";
import LoaderImg from "../assets/simpleLoader.svg";
import { windowLocation } from "../helpers/common";
import "../styles/style.css";

class YourCollection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      image: "",
      featuredImage: "",
      collectionName: "",
      collectionDescription: "",
      royalties: "",
      fee: "",
      isLoaded: false,
      show: false,
      errors: "",
      open: false,
      notificationMessage:'',
      notificationType:'success',
    };
  }

  componentDidMount() {}

  render() {
    const handleShowProgress = () => this.setState({ showProgress: true });
    const handleShowProgressClose = () =>
      this.setState({ showProgress: false });
    const imageChange = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        this.setState({ image: e.target.files[0] });
      }
    };
    const featureImageChange = (e) => {
      if (e.target.files && e.target.files.length > 0) {
        this.setState({ featureImage: e.target.files[0] });
      }
    };

  const { open, notificationMessage, notificationType } = this.state;
  const handleNotificationClick = (msg, type) =>  {
    this.setState({ open: true, notificationMessage:msg, notificationType:type });
  };
  const handleNotificationClose = () => {
    this.setState({ open: false });
  };
  

    const handleSubmit = async (e) => {
      e.preventDefault();
      handleShowProgress();

      var fileData = new FormData();
      if (this.state.image) {
        fileData.append("image", this.state.image);
      }
      if (this.state.featuredImage) {
        fileData.append("featuredImage", this.state.featuredImage);
      }
      const accessToken = JSON.parse(
        localStorage.getItem("authObj")
      ).AccessToken;
      const userId = JSON.parse(localStorage.getItem("authObj")).UserId;
      fileData.append("userId", userId);
      fileData.append("name", this.state.collectionName ?? "A");
      fileData.append("description", this.state.collectionDescription ?? "Z");

      let url = `${process.env.REACT_APP_API_SERVER_URL}/collections`;

      var requestOptions = {
        method: "POST",
        headers: { Authorization: `Bearer ${accessToken}` },
        body: fileData,
      };

      fetch(url, requestOptions)
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
          return Promise.reject(response.json()); // 2. reject instead of throw
          // document.location.href = "/collections";
        })
        .then((result) => {
          handleShowProgressClose();
          handleNotificationClick("Collection created successfully","success");
          windowLocation(`/collections`);
          console.log(result);
        })
        .catch((error) => {
          handleShowProgressClose();
          error.then((res) => {
            handleNotificationClick(error.message,"error");
            this.setState({ errors: res.error.message });
          });
        });
    };

    return (
      <>
        <Container>
          <Col style={{ margin: "0px auto" }} lg={{ span: 9 }}>
            <Row className="">
              <Col lg={{ span: 12 }}>
                <div className="asset-title">
                  <p>Create Your Collection</p>
                </div>
              </Col>
              <Col lg={{ span: 12 }}>
                <Row>
                  <Col lg={{ span: 6 }}>
                    <div className="file-title">
                      <h5>Background Image</h5>
                    </div>
                    <Form.Group controlId="formFileLg" className="mb-3">
                      <Form.Control
                        type="file"
                        size="lg"
                        onChange={imageChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row>
                  <Col lg={{ span: 6 }}>
                    <div className="file-title">
                      <h5>Featured Image</h5>
                    </div>
                    <Form.Group controlId="formFileLg" className="mb-3">
                      <Form.Control
                        type="file"
                        size="lg"
                        onChange={featureImageChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <div className="file-title title-detail title-description">
                  <Form>
                    <Form.Group className="mb-3" controlId="formBasicEmail">
                      <h5>Name*</h5>
                      <Form.Control
                        type="text"
                        placeholder="Enter Name"
                        required
                        onChange={(e) => {
                          this.setState({ collectionName: e.target.value });
                        }}
                      />
                    </Form.Group>
                    <Form.Group
                      className="mb-3"
                      controlId="exampleForm.ControlTextarea1"
                    >
                      <h5>Description</h5>
                      <label>
                        The description will be included on the items detail
                        page underneath its image
                      </label>
                      <Form.Control
                        placeholder="Spread some words about your token collection"
                        as="textarea"
                        rows={5}
                        onChange={(e) => {
                          this.setState({
                            collectionDescription: e.target.value,
                          });
                        }}
                      />
                    </Form.Group>
                  </Form>
                </div>
              </Col>
            </Row>
            <Row>
              <Col lg={{ span: 12 }}>
                <div className="file-title file-title-fix">
                  {/* <p>Collection</p> */}
                  <h5>Royalties</h5>
                  {/* <label>This is the collection where your item will appear.</label> */}
                  <div
                    style={{ marginBottom: "3%" }}
                    className="description-trade accordion-link-fix"
                  >
                    <div
                      className="collection-accordion"
                      style={{ marginBottom: "0%" }}
                    >
                      <div className="accordion-link ">
                        <Row>
                          <Col lg={{ span: 12 }}>
                            <Row className="border-styled border-style-none">
                              <Col lg={{ span: 12 }} xs={{ span: 12 }}>
                                <div className="accordion-label">
                                  <label>
                                    Royalities traits that show up as
                                    rectangles, Royalities traits that show up
                                    as rectangles Royalities traits that show up
                                    as rectangles
                                  </label>
                                </div>
                              </Col>
                            </Row>
                            <Row className="border-styled border-style-none">
                              <Col lg={{ span: 12 }} xs={{ span: 12 }}>
                                <div className="accordion-label">
                                  <Form>
                                    <Form.Group
                                      style={{ marginBottom: "0px !important" }}
                                      className="mb-3"
                                      controlId="formBasicEmail"
                                    >
                                      <Form.Label>Percentage Fees</Form.Label>
                                      <Form.Control
                                        type="number"
                                        placeholder="$ 9.9"
                                        onChange={(e) => {
                                          this.setState({
                                            royalties: e.target.value,
                                          });
                                        }}
                                      />
                                    </Form.Group>
                                  </Form>
                                </div>
                              </Col>
                            </Row>
                          </Col>
                        </Row>
                      </div>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
            <Col lg={{ span: 12 }}>
              <div
                className="file-title file-title-fix"
                style={{ marginTop: "16px" }}
              >
                <h5>Link</h5>
                <div className="description-trade accordion-link-fix">
                  <div
                    className="collection-accordion"
                    style={{ marginBottom: "2%" }}
                  >
                    <div className="accordion-link ">
                      <Row>
                        <Col lg={{ span: 12 }}>
                          <Row className="border-styled border-style-none">
                            <Col lg={{ span: 12 }}>
                              <Form>
                                <Col xs="auto">
                                  <InputGroup className="mb-2">
                                    <InputGroup.Text>
                                      <ArrowForwardIosIcon />{" "}
                                    </InputGroup.Text>
                                    <FormControl
                                      id="inlineFormInputGroup"
                                      placeholder="yoursite.co"
                                    />
                                  </InputGroup>
                                </Col>
                              </Form>
                            </Col>
                            <Col lg={{ span: 12 }}>
                              <Form>
                                <Col xs="auto">
                                  <InputGroup className="mb-2">
                                    <InputGroup.Text>
                                      {" "}
                                      <EmailIcon />
                                    </InputGroup.Text>
                                    <FormControl
                                      id="inlineFormInputGroup"
                                      placeholder="https://discord.gg/abcdef"
                                    />
                                  </InputGroup>
                                </Col>
                              </Form>
                            </Col>
                            <Col lg={{ span: 12 }}>
                              <Form>
                                <Col xs="auto">
                                  <InputGroup className="mb-2">
                                    <InputGroup.Text>
                                      <CameraAltIcon />
                                    </InputGroup.Text>
                                    <FormControl
                                      id="inlineFormInputGroup"
                                      placeholder="https://twitter.com/yourtwitterhandle"
                                    />
                                  </InputGroup>
                                </Col>
                              </Form>
                            </Col>
                            <Col lg={{ span: 12 }}>
                              <Form>
                                <Col xs="auto">
                                  <InputGroup className="mb-2">
                                    <InputGroup.Text>
                                      <DesktopWindowsIcon />
                                    </InputGroup.Text>
                                    <FormControl
                                      id="inlineFormInputGroup"
                                      placeholder="https://medium.com/yourmediumhandle"
                                    />
                                  </InputGroup>
                                </Col>
                              </Form>
                            </Col>
                            <Col lg={{ span: 12 }}>
                              <Form>
                                <Col xs="auto">
                                  <InputGroup className="mb-2">
                                    <InputGroup.Text>
                                      <EventNoteIcon />
                                    </InputGroup.Text>
                                    <FormControl
                                      id="inlineFormInputGroup"
                                      placeholder="https://t.me/abcdef"
                                    />
                                  </InputGroup>
                                </Col>
                              </Form>
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </div>
              </div>
            </Col>
            <Row>
              <Col
                style={{ marginTop: "30px" }}
                lg={{ span: 12 }}
                xs={{ span: 12 }}
              >
                {this.state.errors ? (
                  <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    {this.state.errors}
                  </Alert>
                ) : (
                  ""
                )}
                <Button
                  variant={"contained"}
                  color={"primary"}
                  component={"span"}
                  type={"submit"}
                  onClick={handleSubmit}
                >
                  Create
                </Button>

                <Snackbar
        anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
        open={open}
        autoHideDuration={10000}
        onClose={handleNotificationClose}
        message={notificationMessage}
        key={'bottom right'}
      >
        <Alert onClose={handleNotificationClose} severity={notificationType}>
        {notificationMessage}
        </Alert>
      </Snackbar>
              </Col>
            </Row>
          </Col>

          {/* ----------------------------Show Progress Modal---------------------------------------------- */}

          <div className="popup-modals">
            <Modal show={this.state.showProgress}>
              <Modal.Header>
                <Modal.Title>PLEASE WAIT....</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ textAlign: "center" }}>
                <img src={LoaderImg}  style={{height:'64px', width:'64px'}} />
              </Modal.Body>
              {/* <Modal.Footer>
        </Modal.Footer> */}
            </Modal>
          </div>

          {/* --------------------------------------------------------------------------------------------- */}
        </Container>
      </>
    );
  }
}

export default YourCollection;
