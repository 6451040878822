import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import OceanAppFooterDemo from "../containers/footer/Footer";
import PrimarySearchAppBar from "../containers/header/Header";
import Profiledata from "../componets/Profiledata";
import { getAuthObj } from "../webServices/loginServices";

function Profile() {
  const ProfileData = getAuthObj();
  return (
    <div>
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg">
          <PrimarySearchAppBar />
          {/* <ImagePickes /> */}
          <Profiledata
            name={ProfileData.Username}
            email={ProfileData.Email}
            bio={ProfileData.Bio}
            ProfileImage={ProfileData.ProfileImage}
          />
        </Container>
      </React.Fragment>
      <OceanAppFooterDemo />
    </div>
  );
}

export default Profile;
