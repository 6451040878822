import React, { useEffect, useState } from "react";
// import ReactDOM from 'react-dom';
import moment from "moment";

function Timer(props) {
  const [days, setDays] = useState(0);
  const [hours, setHours] = useState(0);
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  const [showSemicolon] = useState(false);

  useEffect(() => {
    setInterval(() => {
      const now = moment(props.created_date);
      // const then = moment('2020-10-15 12:12:12', 'YYYY-MM-DD hh:mm:ss');
      const then = moment().add(props.duration, "seconds");
      const countdown = moment(then - now);
      setDays(countdown.format("D"));
      setHours(countdown.format("HH"));
      setMinutes(countdown.format("mm"));
      setSeconds(countdown.format("ss"));
    }, 1000);
  }, []);

  return (
    <div>
      <div className="timer-container">
        <div className="timer">
          <span className="number">{days}</span>
          <span>days</span>
        </div>
        {showSemicolon ? <div className="semicolon">:</div> : null}
        <div className="timer">
          <span className="number">{hours}</span>
          <span>hour</span>
        </div>
        {showSemicolon ? <div className="semicolon">:</div> : null}
        <div className="timer">
          <span className="number">{minutes}</span>
          <span>minute</span>
        </div>
        {showSemicolon ? <div className="semicolon">:</div> : null}
        <div className="timer">
          <span className="number">{seconds}</span>
          <span>second</span>
        </div>
      </div>
    </div>
  );
}

export default Timer;
