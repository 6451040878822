import React from "react";
import moment from 'moment'
import { Form,InputGroup,FormControl,FormGroup,Modal,Container, Row, Col } from "react-bootstrap";
import FavoriteIcon from "@material-ui/icons/Favorite";
import VisibilityIcon from "@material-ui/icons/Visibility";
import "react-image-gallery/styles/css/image-gallery.css";
import AssetCard from "../componets/cards/AssetCard";
import { ThemeProvider } from "@material-ui/core";
import { Button,Table,TableHead,TableBody,TableRow,TableCell,Tooltip,Snackbar } from "@material-ui/core";
import SingleImage from "../componets/cards/SingleImage";
import ImageGallery from "../componets/cards/VideoGallery";
import lightTheme from "../componets/theme";
import Carousel from "react-multi-carousel";
import {ButtonGroup } from "../helpers/common";
import MuiAlert from '@material-ui/lab/Alert';
import EmptyIcon from "../containers/content/EmptyIcon";
import ethrLogo from "../assets/ethereum-logo.png";
import axios from "axios";
import { GET,dbGET } from "../webServices/fetch.js";
import { truncateString,truncateAddress,formateAddress,formatAmnt } from "../helpers/strings";
import Accordion from "react-bootstrap/Accordion";
import { withRouter } from "react-router";
import DescriptionIcon from "@material-ui/icons/Description";
import LabelImportantIcon from "@material-ui/icons/LabelImportant";
import LocalOfferIcon from "@material-ui/icons/LocalOffer";
import FormatListBulletedIcon from "@material-ui/icons/FormatListBulleted";
import DeveloperBoardIcon from "@material-ui/icons/DeveloperBoard";
import SwapVertIcon from "@material-ui/icons/SwapVert";
import SubjectIcon from "@material-ui/icons/Subject";
import MenuIcon from "@material-ui/icons/Menu";
import CircularProgress from "@material-ui/core/CircularProgress";
import AccountBalanceWalletIcon from "@material-ui/icons/AccountBalanceWallet";
import LoaderImg from "../assets/simpleLoader.svg";
import NotFoundImg from "../assets/notfound.png";
import Typography from "@material-ui/core/Typography";
import MetaTags from 'react-meta-tags';

import "react-multi-carousel/lib/styles.css";
import Web3 from "web3";
import {getWeb3,isAuthenticated,IsWalletConnected,getWalletBalance} from "../webServices/loginServices";
import {windowLocation} from "../helpers/common";
import { getAllFavorites,addRecentViews } from "../webServices/nftServices";
import { getSeaport } from "../webServices/openseaAPI";
import { OrderSide } from 'opensea-js/lib/types';
import { BigNumber } from "bignumber.js";
import { logSaleTrade,logTransferTrade,transferAsset } from "../webServices/tradeLog";
import ModalImage from "react-modal-image-responsive";
// import ethrLogo from "../../assets/ethereum-logo.png";
import "../styles/style.css";
class Assetsdata extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: "",
      moreData: [],
      collectionSlug: "",
      isLoaded: false,
      isNotFound: false,
      show: false,
      WalletAddress: "",
      ownerName:"",
      rows: [],
      open:false,
      notificationMessage:"",
      notificationType:"",
      showProgress:false,
      showMakeOffer: false,
      offerPrice:0,
      assetTradeHistory:[],
      favCount:0,
      ViewCount:0,
    };
  }

  componentDidMount() {

    const contract = this.props.match.params.contract;
    const token = this.props.match.params.token;
    const network = window.location.search;

    getAllFavorites().then(()=>{
      const allFavourites = localStorage.getItem("allFavourites") ? JSON.parse(localStorage.getItem("allFavourites")) : [];
    if (allFavourites){
      let Count = allFavourites.filter(function(itm) {
        return  itm.contract == contract && itm.token==token;
      }).length;
      this.setState({favCount:Count});
    }
    }).catch((err)=>{

    });


    let authObj=localStorage.getItem("authObj");
    if (authObj){
      authObj= JSON.parse(authObj);
      if(authObj.WalletAddress){
        this.setState({WalletAddress:authObj.WalletAddress});
      }
    }
    

    


    const handleShowProgress = () => this.setState({showProgress: true});
    const handleShowProgressClose = () => this.setState({showProgress: false});

    
  
    const handleNotificationClick = (msg, type) => {
      this.setState({open: true});
      this.setState({notificationMessage: msg});
      this.setState({notificationType: type});
    };
  
    const handleNotificationClose = () => {
      this.setState({open: false});
    };

    const fetchCollectionData = async (contract) => {
      
      // const seaport = await getSeaport();
      // const Assets = await seaport.api.getAssets({
      //   asset_contract_address:contract,
      //   include_orders:true,
      //   order_by:'sale_price',
      //   order_by:'sale_date'
      // });
      const { data: Assets } = await dbGET(`v1/more/${contract}`)
      this.setState({moreData: Assets });
    }

    const fetchData = async (token, contract) => {    
      // const seaport = await getSeaport();
      // const Asset = await seaport.api.getAsset({
      //   tokenAddress:contract,
      //   tokenId:token,
      //   include_orders: true
      // }).catch((err)=>{
      //   this.setState({isLoaded: true,isNotFound:true});
      //   console.log("AZS",err);
      // });
      // console.log("🚀 ~ file: Assetsdata.js ~ line 130 ~ Assetsdata ~ fetchData ~ Asset", Asset)
      // this.setState({ data:Asset, isLoaded: true })
      //  if (Asset) {
      //    addRecentViews(contract,token);
      //    fetchCollectionData(contract);
      //  }
      const { data: asset } = await dbGET(`v1/asset/${contract}/${token}`)
      console.log("🚀 ~ file: Assetsdata.js ~ line 151 ~ Assetsdata ~ fetchData ~ asset", asset[0])
      this.setState({ data:asset[0], isLoaded: true })
      if (asset) {
           addRecentViews(contract,token);
           fetchCollectionData(contract);
         }
    };

    
    fetchData(token, contract);

    const getViewsCount = async (contractAddress,tokenId) =>{
      const serverURL = process.env.REACT_APP_API_SERVER_URL;
      await axios
            .get(`${serverURL}/view/${contractAddress}/${tokenId}`)
            .then((res) => {
            
            if (res.data.count){
              this.setState({ViewCount:res.data.count});
            }
              
            })
            .catch((err) => {
              console.log("Unable to get ViewCount", err);
            });

    }
    getViewsCount(contract,token);

    const getassetTradeHistory = async (contractAddress,tokenId) => {
      if (isAuthenticated()) {
          const accessToken = JSON.parse(localStorage.getItem("authObj")).AccessToken;
          const serverURL = process.env.REACT_APP_API_SERVER_URL;
          const config = {
            headers: { Authorization: `Bearer ${accessToken}` }
          };
          await axios
            .get(`${serverURL}/assetTradeHistory/${contractAddress}/${tokenId}`, config)
            .then((res) => {
              console.log('getting assetTradeHistory');
              console.log(res);
              this.setState({assetTradeHistory:res.data});
              
            })
            .catch((err) => {
              console.log("Unable to get assetTradeHistory", err);
            });
        }
  }
    getassetTradeHistory(contract,token);
  }

  render() {
    const handleShowProgress = () => this.setState({showProgress: true});
    const handleShowProgressClose = () => this.setState({showProgress: false});
    const handleShowMakeOffer = () => this.setState({showMakeOffer: true});
    const handleCloseMakeOffer = () => this.setState({showMakeOffer: false});
    const handleInputOfferPrice = (e) => this.setState({offerPrice:e});
  
    const handleNotificationClick = (msg, type) => {
      this.setState({open: true});
      if (typeof msg === 'object') {
        this.setState({notificationMessage: JSON.stringify(msg)});
      }else {
        this.setState({notificationMessage: msg});
      }
      
      this.setState({notificationType: type});
    };
  
    const handleNotificationClose = () => {
      this.setState({open: false});
    };

    const { isLoaded, data, WalletAddress,orders,count,sellListings } = this.state;
    const contract = this.props.match.params.contract;
    const token = this.props.match.params.token;
    const responsive = {
      superLargeDesktop: {
        // the naming can be any, depends on you.
        breakpoint: { max: 4000, min: 3000 },
        items: 4,
      },
      desktop: {
        breakpoint: { max: 3000, min: 1024 },
        items: 4,
      },
      tablet: {
        breakpoint: { max: 1024, min: 464 },
        items: 2,
      },
      mobile: {
        breakpoint: { max: 464, min: 0 },
        items: 1,
      },
    };
    
    function Alert(props) {
      return <MuiAlert elevation={6} variant="filled" {...props} />;
    }

    const btnMakeOffer = ()=>{
      if(IsWalletConnected()){
      handleShowMakeOffer();
      }else {
        windowLocation('/login');
      }
    }

    const makeOffer = async ()=>{

      if(IsWalletConnected()){
        const seaport = await getSeaport();
      const accountAddress = WalletAddress;
      const referrerAddress = "0xad313b6F1c68cC0176eDB444E097B7B1806f40ec" // The referrer's wallet address
      handleCloseMakeOffer();
      handleShowProgress();
      await seaport.createBuyOrder({
        asset: {
          tokenId:token,
          tokenAddress:contract,
        },
        accountAddress, // Address of the bidder
        startAmount: this.state.offerPrice,
        referrerAddress // Address of the referrer
      }).then((res)=>{
          handleNotificationClick("Offer submited Successfully","success");
          handleShowProgressClose();
          window.location.reload();
      }).catch((error)=>{
        handleShowProgressClose();
        handleNotificationClick(error.message,"error");
        console.log("🚀  makeOffer Error", error);
      })
      }else {
        windowLocation('/login');
      }
      
    
      

    }


    const validateBalanceToBuy = async () =>{
      let itemPrice = Web3.utils.fromWei(Web3.utils.toBN(data.orders.filter(itm => itm.side === OrderSide.Sell)[0].basePrice).toString(), "ether") * 1e4 / 1e4
      let accountBalance = await getWalletBalance();
      itemPrice=parseFloat(itemPrice);
      accountBalance=parseFloat(accountBalance);
      
      
      if (accountBalance >= itemPrice){
        return true
      }else {
        return false
      }
    }
    const acceptOffer = async ()=> {
      if(IsWalletConnected()){
        let validation = await validateBalanceToBuy();
        if (validation){
          handleShowProgress();
      const seaport = await getSeaport();
      const order = await seaport.api.getOrder({ asset_contract_address:contract,token_id:token, side: OrderSide.Sell})
      const accountAddress = WalletAddress; // The owner's wallet address, also the taker
      const referrerAddress = "0xad313b6F1c68cC0176eDB444E097B7B1806f40ec"
      try {
        await seaport.fulfillOrder({ order,accountAddress,referrerAddress}).then(async (res)=>{
          await logSaleTrade(data.name ? data.name : data.asset_contract.name,data.asset_contract.name,data.image_url ? data.image_url : data.asset_contract.image_url,contract,token,res.transactionHash,0,data.owner.address.toLowerCase(),WalletAddress);
          await logTransferTrade(data.name ? data.name : data.asset_contract.name,data.asset_contract.name,data.image_url ? data.image_url : data.asset_contract.image_url,contract,token,res.transactionHash,0,data.owner.address.toLowerCase(),WalletAddress);
          await transferAsset(contract,token,WalletAddress,data);
          console.log("🚀 ~ file: Assetsdata.js ~ line 252 ~ Assetsdata ~ awaitseaport.fulfillOrder ~ res", res)
          handleNotificationClick("Item Transfered Successfully","success");
          handleShowProgressClose();
          window.location.reload();
        });
      } catch (error) {
        handleShowProgressClose();
        handleNotificationClick(error.message,"error");
        console.log("🚀 ~ file: Assetsdata.js ~ line 216 ~ Assetsdata ~ acceptOffer ~ error", error);
      }
        } else {
          handleNotificationClick("Insufficient balance please top up your wallet and try again.","error");
        }
      


    }else {
      windowLocation('/login');
    }
      
  }

    function OwnerName ()  {    
      let OwnerName,OwnerAddress;
      if(!isLoaded){
        OwnerName="Owner Name"
      }
      if (isLoaded) {
        if(WalletAddress.toLowerCase()==data.owner.address.toLowerCase()){
          OwnerName="You";
        }else {
          if(data.owner.user){
            if (data.owner.user.username == "NullAddress"){
              console.log("🚀 ~ file: Assetsdata.js ~ line 323 ~ Assetsdata ~ OwnerName ~ data", data)
              if (data.creator){
                OwnerName=data.creator.user.username
              }
            }
            if (data.owner.user.username ){
              OwnerName=data.owner.user.username;
            } else {
              OwnerName=truncateAddress(data.owner.address);
            }
          } else {
            OwnerName=truncateAddress(data.owner.address);
          }
        }
        OwnerAddress=data.owner.address;
      }
      return (
        // <a href={OwnerAddress}>
        <div>
          <span>Owned by</span>: {OwnerName}
        </div>
        // </a>
      )
    }; 

    function ShowBuyOrSell () {
  
      let OwnerName;
      let sellOrders =data.orders;
      if(WalletAddress.toLowerCase()==data.owner.address.toLowerCase()){
        OwnerName="You";
      }
      if (OwnerName=="You"){
          if (sellOrders.length> 0){
            return (
              <div className="price-buy">
                <ul>
                  <li>
                    <a>
                      <span>Current Price</span>     
                        <p>
                          {Math.round(Web3.utils.fromWei(
                            data.orders.filter(itm => itm.side === OrderSide.Sell)[0].basePrice.toString(), "ether") * 1e4) / 1e4}{" "}
                          ETH |{"  "}
                          {Math.round(Web3.utils.fromWei(
                            data.orders.filter(itm => itm.side === OrderSide.Sell)[0].basePrice.toString(),
                            "ether"
                          ) *
                            data.orders[0]
                              .paymentTokenContract.usdPrice.toNumber()) * 1e4 / 1e4}{" "}
                          USD
                        </p>
                    </a>
                  </li>
                  <li
                    style={{ float: "right", verticalAlign: "bottom" }}
                  >
                    <a href={token+"/sell"}>
                      <div className="buy-span">
                        <span>
                          <Button variant="primary">
                            <AccountBalanceWalletIcon style={{ color: "white" }} /> <span style={{ color: "white",marginLeft:'7px' }} disabled="disabled">Sell</span>
                          </Button>
                        </span>
                  </div>
                    </a>
                  </li>
                </ul>
              </div> )
          } else {
            return (
              <div className="price-buy" >
                <ul>
                  <li>
                    <a>
                      <span>LIST FOR SELL</span>
                     <p>NA</p> 
                    </a>
                  </li>
                  <li
                    style={{ float: "right", verticalAlign: "bottom" }}
                  >
                    <a href={token+"/sell"}>
                      <div className="buy-span">
                        <span>
                          <Button  variant="primary">
                            <AccountBalanceWalletIcon style={{ color: "white" }} /> <span style={{ color: "white",marginLeft:'7px' }} disabled="disabled">Sell</span>
                          </Button>
                        </span>
                  </div>
                    </a>
                  </li>
                </ul>
              </div> )
          }
      }
      if (sellOrders != null && sellOrders.length > 0 && data != null && data.orders != null && data.orders.filter(itm => itm.side === OrderSide.Sell).length > 0 ){
        return (
          <div className="price-buy">
            <ul>
              <li>
                <a>
                  <span>Price</span>
                    <p>
                    <img src={ethrLogo} height={20} width={20} style={{ marginBottom:'6px' }} />
                      <b style={{fontSize:'larger',color:'#000'}}> 
                      {Math.round(Web3.utils.fromWei(
                        Web3.utils.toBN(data.orders.filter(itm => itm.side === OrderSide.Sell)[0].basePrice).toString(), "ether") * 1e4) / 1e4}{" "}
                      </b>
                      (<span style={{fontSize:'13px',color:'#0000008a'}}>                    
                      {formatAmnt(Math.round(Web3.utils.fromWei(
                        Web3.utils.toBN(data.orders.filter(itm => itm.side === OrderSide.Sell)[0].basePrice).toString(),
                        "ether"
                      ) *
                        data.orders[0]
                          .paymentTokenContract.usdPrice) * 1e4 / 1e4)}{""}</span>)
 
                      
                    </p>
                </a>
              </li>
              <li
                style={{ float: "right", verticalAlign: "bottom" }}
              >
                <a>
                  <div className="buy-span">
                    <span>
                      <Button  variant="primary" onClick={()=>{acceptOffer()}}>
                        <AccountBalanceWalletIcon style={{ color: "white" }} /> <span style={{ color: "white",marginLeft:'7px' }}>Buy</span>
                      </Button>
                    </span>
              </div>
                </a>
              </li>
            </ul>
          </div> )
      
      } else {
        if (sellOrders!= null && sellOrders.length > 0 && data != null && data.orders != null && data.orders.filter(itm => itm.side === OrderSide.Buy).length > 0){
          return (
            <div className="price-buy">
              <ul>
                <li>
                  <a>
                    <span>Top Offer</span>
                      <p>
                      <img src={ethrLogo} height={20} width={20} style={{ marginBottom:'6px' }} />
                      <b style={{fontSize:'larger',color:'#000'}}> 
                        {Math.round(Web3.utils.fromWei(
                          Web3.utils.toBN(data.orders.filter(itm => itm.side === OrderSide.Buy)[0].basePrice).toString(), "ether") * 1e4) / 1e4}{" "}
                        </b>
                        (<span style={{fontSize:'13px',color:'#0000008a'}}>
                        {formatAmnt(Math.round(Web3.utils.fromWei(
                          Web3.utils.toBN(data.orders.filter(itm => itm.side === OrderSide.Buy)[0].basePrice).toString(),
                          "ether"
                        ) *
                          data.orders[0]
                            .paymentTokenContract.usdPrice) * 1e4 / 1e4)}{""}</span>)
                      </p>
                  </a>
                </li>
                <li
                  style={{ float: "right", verticalAlign: "bottom" }}
                >
                  <a>
                    <div className="buy-span">
                      <span>
                        <Button  variant="primary" onClick={() => btnMakeOffer()}>
                          <AccountBalanceWalletIcon style={{ color: "white" }} /> <span style={{ color: "white",marginLeft:'7px' }}>Make Offer</span>
                        </Button>
                      </span>
                </div>
                  </a>
                </li>
              </ul>
            </div> )

        } else {
          return (
            <span></span>
          )

        }

      }
  };


   function ListingsOffers ()  {
    // Get offers (bids), a.k.a. orders where `side == 0`.
    if (data){
      return (
        <Col lg={{ span: 6 }}>
                  <div style={{ marginTop: 40 }}>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <LocalOfferIcon /> Listings
                        </Accordion.Header>
                        <Accordion.Body>
                            {data != null && data.orders != null && data.orders.length == 0 ? ("No Sell Orders") :
                            (
                              <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th>Price</th>
                                  <th>Listing Time</th>
                                  <th>From</th>
                                </tr>
                              </thead>
                              <tbody>
                              { data != null && data.orders != null ?
                                data.orders.map(
                                      (order, i) => (
                                        order.side==1 ? (
                                        <tr key={i}>
                                          <td>
                                          {Web3.utils.fromWei(Web3.utils.toBN(order.basePrice).toString(),"ether")}
                                        
                                            {"  "}{order.paymentTokenContract.symbol} 
                                          </td>
                                          <td>{moment(order.listingTime.toNumber()*1000).local().fromNow()}</td>
                                          <td><a href={order.maker}>{truncateAddress(order.maker)}</a></td>
                                        </tr>
                                        )
                                        : ""
                                      )
                                    )
                                  : <tr><td></td> <td></td><td></td></tr>}
                              </tbody>
                            </Table>
                            )
                            }
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <FormatListBulletedIcon /> Offers
                        </Accordion.Header>
                        <Accordion.Body> 
                        {data.orders != null && data.orders.length == 0 ? ("No Offers") :
                            (
                              <Table striped bordered hover>
                              <thead>
                                <tr>
                                  <th>Price</th>
                                  <th>Expiration Time</th>
                                  <th>From</th>
                                </tr>
                              </thead>
                              <tbody>
                        { data.orders != null ? data.orders.map(
                                      (order, i) => (
                                        order.side==0 ? (
                                        <tr key={i}>
                                          <td>
                                          {Web3.utils.fromWei(order.currentPrice.toString(),"ether")}
                                            {"  "}{order.paymentTokenContract.symbol} 
                                          </td>
                                          <td>{moment.duration(moment.unix(order.expirationTime).diff(moment())).humanize()}</td>
                                          <td><a href={order.maker}>{truncateAddress(order.maker)}</a></td>
                                        </tr>
                                        )
                                        : ""
                                      )
                                    ) : <tr><td></td><td></td><td></td></tr>
                                  }
                                   </tbody>
                            </Table>
                            )
                            }
                          </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </Col>
      )
    }
    
  }
    const { rows } = this.state;
    return (
      <>
       <ThemeProvider theme={lightTheme}>
          <div className="container-cmd">
          {!this.state.data
      ? (<span></span>)
        
      : (<MetaTags>
      <title>{ this.state.data.name ??
               this.state.data.asset_contract.name} | MintJar.co</title>
      <meta name="description" content={ this.state.data.description ??
               this.state.data.asset_contract.description} />
      <meta property="og:title" content={ this.state.data.name ??
               this.state.data.asset_contract.name} />
      <meta property="og:image" content={this.state.data.image_url ? this.state.data.image_url.split("=")[0] : ( this.state.data.asset_contract.image_url ? this.state.data.asset_contract.image_url.split("=")[0] : "") } />
    </MetaTags>)}
          
            <Container>

              {!this.state.data
      ? <div style={{alignItems:'center',textAlign: "center",verticalAlign:'center',marginTop:'10%' }}>
        <img src={this.state.isNotFound ? NotFoundImg : LoaderImg}  alt="Loading" /></div>

      : <Row>
                <Col lg={{ span: 6 }}>
                  <div
                    style={{ borderRadius: "10px",margin:0,padding:0 }}
                    className="img-src bgAssetPage"
                  >
                    { this.state.data.animationUrl 
                    ? (
                      <video style={{height:"-webkit-fill-available",width:"-webkit-fill-available",borderRadius:"inherit"}} loop muted autoPlay={true} controls = ''>
                        <source src={this.state.data.animationUrl } type="video/mp4" />
                      Your browser does not support the video tag.
                      </video>
                    )
                    : (<ModalImage
                      small={this.state.data.image_url.split("=")[0]}
                      large={this.state.data.image_url_original?this.state.data.image_url+0:this.state.data.image_url.split("=")[0]}
                      hideDownload = {true}
                      hideZoom = {false}
                      showRotate = {true}
                      alt={this.state.data.name}
                    />)
                    }
                  </div>
                </Col>
                <Col lg={{ span: 6 }}>
                  <div className="right-content-data">
                    <div className="title-text">
                     { this.state.data.name ??
                       this.state.data.asset_contract.name}
                        <a href={`/marketplace/contract/${this.state.data.asset_contract.address}`}><p style={{color: "#057bfe",fontWeight: 500}}>{this.state.data.collection.name}</p></a>
                    </div>
                    <ul style={{ padding: 0, margin: 0 }}>
                      <li>
                        <a href={`/wallet/${data.owner.address}`}><OwnerName /></a>
                      </li>
                      <li>
                          <FavoriteIcon style={{ color: "gray" }} /> {this.state.favCount}
                      </li>
                      <li>
                          <VisibilityIcon style={{ color: "gray" }} /> {this.state.ViewCount > 0 ? this.state.ViewCount + " Views" : 0 + "Views"}
                      </li>
                      
                    </ul>

                    <ShowBuyOrSell/>

                    <Row>
                      <Col lg={{ span: 12 }}>
                        <div className="description-trade bgAssetPage">
                          <div className="card-heading">
                            <DescriptionIcon /> Description
                          </div>
                         
                            <p>
                              {this.state.data.description ? truncateString(this.state.data.description,400) : ''}
                            </p>
                          
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={{ span: 6 }}>
                  <div style={{ marginTop: 40 }}>
                    <Accordion defaultActiveKey="0">
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          <LabelImportantIcon /> Properties
                        </Accordion.Header>
                        {this.state.data.traits.length > 0 ?
                        <Accordion.Body>
                          <Row> 
                            { this.state.data.traits.map((trait, i) => (
                                  <Col
                                    key={i}
                                    lg={{ span: 4 }}
                                    style={{
                                      textAlign: "center",
                                      padding: "5px",
                                    }}
                                  >
                                    <Button
                                      variant="outlined"
                                      style={{ width: "100%" }}
                                    >
                                      <span>
                                        <b
                                          style={{
                                            color: "#24a0ed",
                                            fontWeight: "400",
                                          }}
                                        >
                                          {trait.trait_type}
                                        </b>
                                        <br></br>
                                        <b>{trait.value}</b>
                                      </span>
                                    </Button>
                                  </Col>
                                ))
                              }
                          </Row>
                        </Accordion.Body> : 
                        <EmptyIcon />
                        }
                      </Accordion.Item>
                      <Accordion.Item eventKey="1">
                        <Accordion.Header>
                          <SubjectIcon />
                          About{" "}
                           {this.state.data.collection.name }
                        </Accordion.Header>
                        <Accordion.Body>
                         
                            <div style={{position:'relative'}}>
                              <img
                                src={this.state.data.collection.image_url}
                                style={{ display: "inline" }}
                              />{" "}
                              <span style={{position:'absolute',paddingLeft:'7px'}}>
                                {truncateString(this.state.data.asset_contract.description, 300)}
                              </span>
                            </div>
                          
                        </Accordion.Body>
                      </Accordion.Item>
                      <Accordion.Item eventKey="2">
                        <Accordion.Header>
                          <DeveloperBoardIcon /> Details
                        </Accordion.Header>
                        <Accordion.Body>
                          <table className={'DetailsTable'}>
                            <tr>
                              <td>Contract Address</td>
                              <td>
                                {truncateString(
                                  this.props.match.params.contract
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Token ID</td>
                              <td>
                                {truncateString(
                                  this.props.match.params.token,
                                  40
                                )}
                              </td>
                            </tr>
                            <tr>
                              <td>Blockchain</td>
                              <td>Ethereum</td>
                            </tr>
                          </table>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </Col>

                <ListingsOffers/>
                
                {/* <Col lg={{ span: 12 }}>
                  <div
                    className="history-trade responsive-alignment bgAssetPage"
                    style={{ marginTop: "25px" }}
                  >
                    <span>
                      <SwapVertIcon /> Trade History
                    </span>
                    <hr />
                    <div className="data-tables table-responsive">
              {this.state.assetTradeHistory.length > 0 ?
                      <Table striped bordered hover>
                        <thead>
                          <tr>
                            <th>Event</th>
                            <th>From</th>
                            <th>To</th>
                            <th>Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          { this.state.assetTradeHistory.map((row, i) => (
                            <tr key={i}>
                              <td component="th" scope="row">
                              <Typography >{row.eventType.toUpperCase()}</Typography>
                              </td>
                              <td><Typography>{row.from ? row.from : ""}</Typography></td>
                              <td><Typography>{row.to ? row.to : ""}</Typography></td>
                              <td><Typography>{moment(row.created).local().fromNow()}</Typography></td>
                            </tr>
                          ))}
                        </tbody>
                      </Table>:
                      <EmptyIcon />
                      }
                    </div>
                  </div>
                </Col> */}
                <Col lg={{ span: 12 }} xs={{ span: 12 }}>
                  <div
                    className="card-carousel responsive-view bgAssetPage"
                    style={{ marginTop: "53px" }}
                  >
                    <p>
                      <MenuIcon /> NFTs You May Like
                    </p>
                    <hr />

                    <Row>
                      <Col style={{position:'relative'}} className="assetPage" lg={{ span: 12 }}>
                        <Carousel  responsive={responsive}
                        arrows = {false}
                        customButtonGroup={<ButtonGroup
                            next={this.props.next}
                            previous={this.props.previous}
                            rest={this.props.rest}
                        />}
                        >
                          {!this.state.isLoaded ? (
                            <div
                              className="loading-icons"
                              style={{
                                textAlign: "center",
                                justifyContent: "center",
                                display: "inline-block",
                              }}
                            >
                              <CircularProgress disableShrink />
                            </div>
                          ) : (
                            this.state.moreData.map((item, i) => (
                              <Col lg={{ span: 12 }} key={i}>
                                <div className="cards-iii">
                                  {" "}
                                  <AssetCard
                                    asset={item}
                                    // collection_name={item.collection.name}
                                    // item_name={item.name}
                                    // description={item.description}
                                    // image={item.imagePreviewUrl}
                                    // asset_address={
                                    //   item.asset_contract.address +
                                    //   "/" +
                                    //   item.tokenId
                                    // }
                                    key={
                                      item.asset_contract.address +
                                      "/" +
                                      item.token_id
                                    }
                                  />
                                </div>
                              </Col>
                            ))
                          )}
                        </Carousel>
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row> }
              {/* ----------------------------Show Progress Modal---------------------------------------------- */}

          <div className="popup-modals">
            <Modal show={this.state.showProgress}>
              <Modal.Header>
                <Modal.Title>PLEASE WAIT....</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ textAlign: "center" }}>
                <img src={LoaderImg} style={{height:'64px', width:'64px'}} alt="Loading" />
              </Modal.Body>
              {/* <Modal.Footer>
          </Modal.Footer> */}
            </Modal>
          </div>

        {/* --------------------------------------------------------------------------------------------- */}
          {/* ----------------------------Show Make Offer Modal---------------------------------------------- */}

          <div className="popup-modals">
            <Modal show={this.state.showMakeOffer} onHide={handleCloseMakeOffer}>
              <Modal.Header closeButton>
                <Modal.Title>Make an offer</Modal.Title>
              </Modal.Header>
              <Modal.Body style={{ textAlign: "center" }}>
              <Row>
                      <Col lg={{ span: 12 }} className="px-0">
                        <Form>
                          <label style={{fontWeight:"400"}}>Price</label>
                          <InputGroup className="mb-2">
                          <label style={{
                            backgroundColor: 'aliceblue',
                            padding: '5px',
                            fontWeight: '400',
                            border: 'solid 1px blue'}}>
                            
                            <img src={ethrLogo} height={24} width={24
                            }/>
                            </label>
                            <FormControl
                              name="price"
                              placeholder="Amount"
                              value={this.state.offerPrice}
                              onChange={(e) => handleInputOfferPrice(e.target.value)}
                            />
                          </InputGroup>
                        </Form>
                      </Col>
              </Row>
                        
              </Modal.Body>
              <Modal.Footer>
              <Button
                variant="contained"
                color="primary"
                onClick={makeOffer}
              >
                Make Offer
              </Button>
              </Modal.Footer>
            </Modal>
          </div>

        {/* --------------------------------------------------------------------------------------------- */}
              <Snackbar
                anchorOrigin={{ vertical:'bottom', horizontal:'center' }}
                open={this.state.open}
                autoHideDuration={10000}
                onClose={this.handleNotificationClose}
                message={this.state.notificationMessage}
                key={'bottom right'}
                >
                <Alert onClose={this.handleNotificationClose} severity={this.state.notificationType}>
                {this.state.notificationMessage}
                </Alert>
              </Snackbar>
            </Container>
          </div>
        </ThemeProvider>
        
      </>
    );
  }
}

export default withRouter(Assetsdata);
