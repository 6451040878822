import React from "react";
import { useState } from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography, Box, IconButton } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import { Row, Column, Item } from "@mui-treasury/components/flex";
import { windowLocation } from "../../helpers/common";
import { useFourThreeCardMediaStyles } from "@mui-treasury/styles/cardMedia/fourThree";
import { red } from "@material-ui/core/colors";
import Timer from "../../containers/content/Timer";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import ethrLogo from "../../assets/ethereum-logo.png";
import {
  markFavoriteNFT,
  unmarkFavoriteNFT,
} from "../../webServices/nftServices";

const useStyles = makeStyles(() => ({
  card: {
    border: "2px solid",
    borderColor: "#E7EDF3",
    borderRadius: 16,
    transition: "0.4s",
    "&:hover": {
      borderColor: "#5B9FED",
    },
  },
  media: {
    borderRadius: 6,
    paddingBottom: "100%",
    marginBottom: 15,
  },
}));

export const AuctionCard = React.memo(function ShowcaseCard(props) {
  const styles = useStyles();
  const mediaStyles = useFourThreeCardMediaStyles();
  const [isClick, setClick] = useState(false);
  const markFavorite = (val) => {
    if (isClick) {
      unmarkFavoriteNFT(val);
    } else {
      markFavoriteNFT(val);
    }
    setClick(!isClick);
  };
  const gap = { xs: 1, sm: 1.5, lg: 2 };
  const getColor = (duration) => {
    if (duration < 3600) return "red";
    console.log("duration");
    console.log(props.duration);
    return "";
  };
  const totalWords = 20;
  const getlength = (length) => {
    if (totalWords < length) return "....";
    return "";
  };

  const getTrimedSentence = (Sentence) => {
    if (Sentence) {
      return Sentence.substring(0, totalWords) + getlength(Sentence.length);
    } else {
      return "";
    }
  };
  return (
     
    <Grid item xs={12} sm={6} md={3}>
      <a
        onClick={() => windowLocation(`/asset/${props.asset_address}`)}
        style={{ cursor: "pointer" }}
      >
        <Column
          className={styles.card}
          p={{ xs: 0.5, sm: 0.75, lg: 1 }}
          gap={gap}
        >
          <CardMedia
            className={cx(styles.media, mediaStyles.root)}
            image={props.image}
          />
          <Row
            className="count-down"
            style={{ color: getColor(props.duration) }}
            justifyContent="center"
          >
            <Timer
              created_date={props.created_date}
              duration={props.duration}
            />
          </Row>
          <Row>
            <Item position={"start"}>
              <Typography fontSize={8}>
                <Box style={{ fontSize: "11px", color: "rgb(112, 122, 131)" }}>
                  {getTrimedSentence(props.collection_name)}
                </Box>
                <Box fontSize={"0.75rem"}>
                  {getTrimedSentence(props.item_name)}
                </Box>
              </Typography>
            </Item>
            <Item position={"right"} style={{ textAlign: "right" }}>
              <span
                style={{
                  fontSize: "11px",
                  color: "rgb(112, 122, 131)",
                  textAlign: "right",
                }}
              >
                Top Bid
              </span>
              <br></br>
              <span
                style={{ fontSize: "12px", color: "black", textAlign: "right" }}
              >
                {props.price}
              </span>
              <img
                src={ethrLogo}
                height={16}
                width={16}
                style={{ padding: "2px" }}
              />
            </Item>
          </Row>
          <Row justifyContent="space-between" pr={1} pl={1} {...props}>
            <Item position={"middle"} pl={{ sm: 0.5, lg: 0.5 }}></Item>
            <a
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <IconButton
                aria-label="add to favorites"
                onClick={() => markFavorite(props.asset_address)}
              >
                {isClick ? (
                  <FavoriteIcon fontSize="small" style={{ color: red[500] }} />
                ) : (
                  <FavoriteBorderIcon
                    fontSize="small"
                    style={{ color: red[500] }}
                  />
                )}
              </IconButton>
            </a>
          </Row>
        </Column>
      </a>
    </Grid>
  );
});
export default AuctionCard;
