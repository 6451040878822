import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import Boxes from "../assets/images/box.png";

function Coming() {
  return (
    <>
      <div className="container">
        <div className="coming-soon-icon">
          <Row>
            <Col style={{height:'500px'}} lg={{ span: 12 }}>
              <Button style={{borderRadius:'0px',marginTop:'15%'}} variant="secondary" className="light-shadow">
                COMING SOON
              </Button>
              <div className="details-text">
              {/* Coming Soon about each warning..... */}
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Coming;
