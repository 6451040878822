/* eslint-disable no-undef */
/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable no-unused-vars */
import React from "react";
import moment from 'moment';
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import { Tooltip, Button, Grid } from "@material-ui/core";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import defaultAvatar from "../Component/assets/images/defaultAvatar.jpeg";
import Checkbox from "@material-ui/core/Checkbox";
import { GET } from "../webServices/fetch.js";
import FormGroup from "@material-ui/core/FormGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import AssetCard from "../componets/cards/AssetCard";
import Feature from "../componets/cards/TabsCards";
import CameraAlt from "@material-ui/icons/CameraAlt";
import Slider from "@material-ui/core/Slider";
import { truncateString,formateAddress } from "../helpers/strings";
import "bootstrap/dist/css/bootstrap.min.css";

import {  isAuthenticated,getAuthObj,updateLoginStorage,updateAvatarLoginStorage } from "../webServices/loginServices";

import { Container, Row, Col, Tab, Nav, Form, Card } from "react-bootstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { getFavorites } from "../webServices/nftServices";
import ClearIcon from "@material-ui/icons/Clear";
import { windowLocation } from "../helpers/common";
import "../styles/style.css";
import axios from "axios";

let resultArray = [];
let statesArray = [];
let sortedActivities = [];
let filterButtons = [];
class Profiledata extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "first",
      favorites: [],
      show: false,
      rows: [],
      isLoaded: false,
      fixPoint: 3,
      MarketplaceItems: [],
      sotringData: [],
      orignalData: [],
      isLoadedFavorites: false,
      wordsLength: 15,
      hobbies: [],
      collections: [],
      sliderVal: null,
      startPrice: 0,
      endPrice: 1000,
      checkedStatus: false,
      sortingStatus: false,
      sortingFilterStatus: false,
      selectedCollection: [],
      collectionFilterStatus: false,
      filtersData: [],
      applyFilterStatus: false,
      // newArrivalFilter:false,
      // buyNowFilter:false,
      // onAuctionFilter:false,
      // hasOfferFilter:false,
      // priceRangeFilter:false,
      button: "show",
      shows: true,
      newArrival: false,
      buyNow: false,
      auctions: false,
      offers: false,
      collection: false,
      collectionschek: false,
      category: false,
      categories: false,
      all: false,
      sliderStatus: true,
      ProfileImage: defaultAvatar,
      filterButtonsState: [],
      userAssets:[],
      userCollections:[],
      userTradeHistory:[],
      name: "",
      bio: "",
      email: "",
      authName: "",
      authBio: "",
      authEmail: ""
    };
  }

  
  

  getUserAssets = async ()=> {
    let authObj = localStorage.getItem("authObj");
    authObj = JSON.parse(authObj);
    const AccessToken = authObj.AccessToken;
    const userWalletAddress = authObj.WalletAddress;

    if (AccessToken) {
      const config = {
        headers: { Authorization: `Bearer ${AccessToken}` },
      };
      await axios
        .get(`${process.env.REACT_APP_API_SERVER_URL}/assets/created/${userWalletAddress}`, config)
        .then((res) => {
          this.setState({
            userAssets: res.data,
            isLoaded: true,
          });
        })
        .catch((err) => {
          console.log("err");
          console.log(err);
        });
    }
  }

  updateProfile = () => {
    if (this.state.name && this.state.bio && this.state.email) {
      this.setState({ shows: !this.state.shows })
      const accessToken = JSON.parse(
        localStorage.getItem("authObj")
      ).AccessToken;
      const userId = JSON.parse(localStorage.getItem("authObj")).UserId;
      let url = `${process.env.REACT_APP_API_SERVER_URL}/users/${userId}`;
      var requestOptions = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };
      var body =  {
          name: this.state.name,
          bio: this.state.bio,
          email: this.state.email,
        };
      
      axios.patch(url,body, requestOptions)
        // .then((response) => response.text())
        .then((result) => {
          updateLoginStorage(this.state.name,this.state.email,this.state.bio);
          this.setState({authName:getAuthObj().Username,authEmail:getAuthObj().Email,authBio:getAuthObj().Bio});
          console.log(result);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };
  
  
  
  handleClicksOpen = (event, newValue) => {
    document.getElementById("maxSliderVal").value = newValue;
    this.sortingBypriceSlide();
  };

  setFavorites =  () => {
    getFavorites().then((fav)=>{
      console.log("🚀 ~ file: Profiledata.js ~ line 165 ~ Profiledata ~ getFavorites ~ fav", fav)
      this.setState({favorites: fav});
      console.log("🚀 ~ file: Profiledata.js ~ line 180 ~ Profiledata ~ this.state.favorites", this.state.favorites)
    }).catch((error)=>{
      console.log(error);
    });
    
  };
   getMyCollections = async () => {
    let authObj = localStorage.getItem("authObj");
    authObj = JSON.parse(authObj);
    if (authObj.AccessToken) {
      const config = {
        headers: { Authorization: `Bearer ${authObj.AccessToken}` },
      };
      // await axios
      //   .get(`${process.env.REACT_APP_API_SERVER_URL}/assets/collected/${authObj.WalletAddress}`, config)
      //   .then((res) => {
      //   console.log("🚀 ~ file: Profiledata.js ~ line 184 ~ Profiledata ~ .then ~ res", res.data)
          
      //     this.setState({
      //       collections: res.data,
      //       isLoaded: true,
      //     });
      //   })
      //   .catch((err) => {
      //     console.log("err");
      //     console.log(err);
      //   });
      await GET(`assets?owner=${authObj.WalletAddress}&order_by=pk&order_direction=desc&limit=50&include_orders=true`)
         .then((res) => {
          this.setState({
            collections: res.data.assets,
            isLoaded: true,
          });
        })
        .catch((err) => {
          console.log("err");
          console.log(err);
        });
    }
  };
  getUserTradeHistory = async () => {
    if (isAuthenticated()) {
        const accessToken = JSON.parse(localStorage.getItem("authObj")).AccessToken;
        const serverURL = process.env.REACT_APP_API_SERVER_URL;
        const userWalletAddress = JSON.parse(localStorage.getItem("authObj")).WalletAddress;
        const config = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        await axios
          .get(`${serverURL}/userTradeHistory/${userWalletAddress}`, config)
          .then((res) => {
            console.log('getting userTradeHistory');
            this.setState({userTradeHistory:res.data});
          })
          .catch((err) => {
            console.log("Unable to get userTradeHistory", err);
          });
      }
}

 
  
  


  

  componentDidMount() {
    this.setState({
      ProfileImage: (this.props.ProfileImage)
        ? this.props.ProfileImage
        : defaultAvatar,
    });
    const url = new URL(window.location.href);
    const query_activeTab = url.searchParams.get('tab');
    console.log("🚀 ~ file: Profiledata.js ~ line 227 ~ Profiledata ~ componentDidMount ~ query_activeTab", query_activeTab)
    
      switch (query_activeTab) {
        case "collected":
          this.setState({activeTab:"first"});
          
          break;
        case "created":
          this.setState({activeTab:"second"});
          this.setState({ activeTab: 'second' }, () => {
            console.log(this.state.activeTab)
          }
         );
          console.log("🚀 ~ second");
          
          break;
        case "favorites":
          this.setState({activeTab:"third"});
          break;
        case "trade":
          this.setState({activeTab:"four"});
          break;
        default:
          this.setState({activeTab:"four"});
          break;

      
      } 
    
    

    this.getMyCollections();
    this.getUserAssets();
    this.setFavorites();
    this.getUserTradeHistory();
    this.setState({name:getAuthObj().Username,email:getAuthObj().Email,bio:getAuthObj().Bio})
    this.setState({authName:getAuthObj().Username,authEmail:getAuthObj().Email,authBio:getAuthObj().Bio})
    
  }

  render() {
    const { shows,activeTab } = this.state;
    const { newArrival } = this.state;
    let newArrivalChecked = this.state.newArrival;
    const { buyNow } = this.state;
    let buyNowChecked = this.state.buyNow;
    const { auctions } = this.state;
    let auctionsChecked = this.state.auctions;
    const { offers } = this.state;
    let offersChecked = this.state.offers;
    const { isLoaded, rows, show, favorites } = this.state;
    const changeProfilePic = (e) => {
      const userId = JSON.parse(localStorage.getItem("authObj")).UserId;
      const AccessToken = JSON.parse(localStorage.getItem("authObj")).AccessToken;
      let url = `${process.env.REACT_APP_API_SERVER_URL}/avatar/${userId}`;
      const file = e.target.files[0];
      var fileData = new FormData();
      if (file) {
        fileData.append("image", file);
      }
      var requestOptions = {
        method: "PATCH",
        headers: { Authorization: `Bearer ${AccessToken}` },
        body: fileData,
        redirect: "follow",
      };
      fetch(url, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result.avatarURL);
        updateAvatarLoginStorage(result.avatarURL);
      })
      .catch((error) => console.log("error", error));

      if (e.target.files.length !== 0) {
        this.url = URL.createObjectURL(file);
        if (this.url) {
          this.setState({
            ProfileImage: this.url,
          });
        }
      }
      // alert(this.url);
    };

    const onTabChanageHandle = async (tab) => {
      switch (tab) {
        case "first":
          this.getMyCollections();
          break;
        case "second":
          this.getUserAssets();
            break;
        case "third":
          this.setFavorites();
            break;
        case "four":
          this.getUserTradeHistory();
            break;
      
        default:
          break;
      }
      this.setState({activeTab:tab})
    }
    
    return (
      <>
        <div className="container-cmd">
          <Container>
            <Tab.Container id="left-tabs-example" defaultActiveKey={activeTab} activeKey={this.state.activeTab} onSelect={(k) => onTabChanageHandle(k)}>
              <Row>
                <Col lg={{ span: 3 }}>
                  <div className="sidebar-fix">
                    <div className="card-profileuser">
                      <Card border="primary">
                        <Card.Body>
                          <Card.Text>
                            <Row>
                              <Col lg={{ span: 4 }}>
                                <div style={{position:'relative'}} className="img-src-card profileImg">
                                  <img
                                    style={{ width: "60px", height: "60px" }}
                                    alt="ProfileImage"
                                    src={
                                      this.state.ProfileImage
                                        ? this.state.ProfileImage
                                        : defaultAvatar
                                    }
                                  />
                                  <div className="middle">
                                    <div className="text">
                                      <label htmlFor="upload-photo">
                                        <input
                                          onChange={changeProfilePic}
                                          style={{ display: "none" }}
                                          id="upload-photo"
                                          name="upload-photo"
                                          type="file"
                                        />
                                        <Tooltip
                                          title="Edit picture"
                                          aria-label="edit"
                                          arrow
                                        >
                                          <CameraAlt
                                            style={{ cursor: "pointer" }}
                                          />
                                        </Tooltip>
                                      </label>
                                    </div>
                                  </div>
                                </div>
                              </Col>
                              <Col lg={{ span: 8 }}>
                                <div className="info-user">
                                  <div className="user-name edit-profile-icons">
                                    {this.state.authName}{" "}
                                  </div>
                                  <div className="email-user">
                                    {this.state.authEmail}
                                  </div>
                                  <div className="bio-data">
                                    {this.state.authBio}
                                  </div>
                                </div>
                                <Col style={{ textAlign: "right" }} span={12}>
                                  {/* <Button style={{float:'right'}} variant="contained" >Edit Profile</Button> */}
                                  <button
                                    title="Edit profile"
                                    onClick={() =>
                                      this.setState({ shows: !shows })
                                    }
                                    className="MuiButtonBase-root MuiIconButton-root"
                                    tabIndex="0"
                                    type="button"
                                    aria-label="Edit in CodeSandbox"
                                    data-ga-event-category="demo"
                                    data-ga-event-label="pages/components/avatars/IconAvatars.js"
                                    data-ga-event-action="codesandbox"
                                  >
                                    <span className="MuiIconButton-label">
                                      <svg
                                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeSmall"
                                        focusable="false"
                                        viewBox="0 0 24 24"
                                        aria-hidden="true"
                                      >
                                        <path d="M3 17.25V21h3.75L17.81 9.94l-3.75-3.75L3 17.25zM20.71 7.04c.39-.39.39-1.02 0-1.41l-2.34-2.34a.9959.9959 0 00-1.41 0l-1.83 1.83 3.75 3.75 1.83-1.83z"></path>
                                      </svg>
                                    </span>
                                    <span className="MuiTouchRipple-root"></span>
                                  </button>
                                </Col>
                              </Col>

                              <Col lg={{ span: 12 }}>
                                {shows ? null : (
                                  <div className="edit-data bio-date-edit">
                                    <hr />
                                    <Form>
                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicEmail"
                                      >
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                          type="text"
                                          placeholder="name"
                                          value={this.state.name}
                                          onChange={(e) =>
                                            (this.setState({name:e.target.value})  )
                                          }
                                        />
                                      </Form.Group>

                                      <Form.Group
                                        className="mb-3"
                                        controlId="exampleForm.ControlTextarea1"
                                      >
                                        <Form.Label>Bio</Form.Label>
                                        <Form.Control
                                          as="textarea"
                                          rows={2}
                                          value={this.state.bio}
                                          placeholder="Little about yourself "
                                          onChange={(e) =>
                                            (this.setState({bio:e.target.value})  )
                                          }
                                        />
                                      </Form.Group>

                                      <Form.Group
                                        className="mb-3"
                                        controlId="formBasicPassword"
                                      >
                                        <Form.Label>Email</Form.Label>
                                        <Form.Control
                                          type="email"
                                          value={this.state.email}
                                          placeholder="email@abc.com"
                                          onChange={(e) =>
                                            (this.setState({email:e.target.value})  )
                                          }
                                        />
                                      </Form.Group>
                                      <div className="form-submit-button">
                                        {/* <Button variant="primary">Save</Button> */}
                                        <Button
                                          style={{ float: "right" }}
                                          variant="contained"
                                          color="primary"
                                          onClick={this.updateProfile}
                                        >
                                          Update
                                        </Button>
                                      </div>
                                    </Form>
                                  </div>
                                )}
                              </Col>
                            </Row>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>

                    <Row>
                      <Col lg={12}>
                        <div className="tabslink-top">
                          <b className="b" style={{ textAlign: "left" }}>WALLET ACTIVITY</b>
                          <Nav  variant="pills" className="flex-column">
                            <Nav.Item style={{cursor: "pointer" }}>
                              <Nav.Link eventKey="first" >
                                Collected
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item style={{cursor: "pointer" }}>
                              <Nav.Link eventKey="second" >
                               Created
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item style={{cursor: "pointer" }}>
                              <Nav.Link eventKey="third">Favorites</Nav.Link>
                            </Nav.Item>
                            <Nav.Item style={{cursor: "pointer" }}>
                              <Nav.Link eventKey="four">Trade History</Nav.Link>
                              </Nav.Item>
                          </Nav>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="first"></Tab.Pane>
                          <Tab.Pane eventKey="second"></Tab.Pane>
                        </Tab.Content>
                      </Col>
                      
                      <Col lg={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="first"></Tab.Pane>
                          <Tab.Pane eventKey="second"></Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={{ span: 9 }} xs={{ span: 12 }}>
                 
                  
                  <div className="tabs-cards-component">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <Row>
                          <Grid container spacing={1}>
                            
                            {this.state.collections.length ==0 ? (
                              <div style={{margin: "24px 36px",
                              width: "inherit",
                              height: "inherit",
                              fontSize: "28px",
                              textAlign: "center"}}>
                                <h4>You haven't collected any items yet</h4>
                              </div>
                            ) : (
                              this.state.collections.map((item) => (
                                <Grid item sm={2} md={4}>
                                <AssetCard
                                asset={item}
                              />
                              </Grid>
                              ))
                            )}
                          </Grid>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          <Grid container spacing={1}>
                            {this.state.userAssets.length ==0 ? (
                              <div style={{margin: "24px 36px",
                              width: "inherit",
                              height: "inherit",
                              fontSize: "28px",
                              textAlign: "center"}}>
                                <h4>You didn't created any items yet</h4>
                              </div>
                            ) : (
                              this.state.userAssets.map((item) => (
                                <Grid item sm={2} md={4}>
                                <AssetCard
                                  asset={item}
                                  key={
                                    item.collections +
                                    "/" +
                                    item.token
                                  }
                                />
                                </Grid>
                              ))
                            )}
                          </Grid>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="third"
                      >
                        <Row>
                        <Grid container>
                            {this.state.favorites.length < 1 ? (
                              <div style={{margin: "24px 36px",
                                width: "inherit",
                                height: "inherit",
                                fontSize: "28px",
                                textAlign: "center"}}>
                                <h4>You haven't favorited any items yet</h4>
                                
                              </div>
                            ) : (
                              this.state.favorites.map((item, i) => (
                                <Grid item sm={2} md={4}>
                                <AssetCard
                                  favorite={true}
                                  update={this.setFavorites}
                                  asset={item}
                                  key={i}
                                />
                                </Grid>
                              ))
                            )}
                          </Grid>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="four">
                        <Row>
                          <Col lg={{ span: 12 }}>
                            <TableContainer component={Paper}>
                              <Table aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <Typography
                                      variant="h6"
                                      id="tableTitle"
                                      component="div"
                                      style={{ padding: "20px" }}
                                    >
                                      Trade History
                                    </Typography>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Event</TableCell>
                                    <TableCell>Item</TableCell>
                                    {/* <TableCell>Unit Price</TableCell>
                                    <TableCell>Quantity</TableCell> */}
                                    <TableCell>From</TableCell>
                                    <TableCell>To</TableCell>
                                    <TableCell>Date</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.state.userTradeHistory.map((row, i) => (
                                    <TableRow key={i}>
                                      <TableCell component="th" scope="row">
                                      <Typography >{row.eventType.toUpperCase()}</Typography>
                                      </TableCell>
                                      <TableCell>
                                      <div style={{display:"flex",cursor: "pointer"}} onClick={() => windowLocation(`/asset/${row.contractAddress}/${row.tokenId}`)}>
                                       <div className={'asset-cell-image'}>
                                       <img src={row.image} className={'asset-image'} />
                                      </div>
                                      <div style={{padding:"5px"}}>
                                      <div >
                                        <p className={'asset-cell-collectionName'}>{row.collectionName}</p>
                                        </div>
                                        <div className={'asset-cell-nftName'}>
                                        <Typography>{row.name}</Typography>
                                        </div>
                                      </div>
                                      </div>
                                        </TableCell>
                                      <TableCell><Tooltip title={row.from ? row.from.toUpperCase() : ""} arrow><Typography>{row.from ? formateAddress(row.from) : ""}</Typography></Tooltip></TableCell>
                                      <TableCell><Tooltip title={row.to ? row.to.toUpperCase() : ""} arrow><Typography>{row.to ? formateAddress(row.to) : ""}</Typography></Tooltip></TableCell>
                                      <TableCell><Typography>{moment(row.created).local().fromNow()}</Typography></TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Col>
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </div>
      </>
    );
  }
}

export default Profiledata;
