/* eslint-disable no-undef */
import axios from "axios";
import { isAuthenticated } from "./loginServices";

export const getFavorites = async () => {
  const serverURL = process.env.REACT_APP_API_SERVER_URL;
  let data;
  if (isAuthenticated()) {
    const accessToken = JSON.parse(localStorage.getItem("authObj")).AccessToken;
    const userId = JSON.parse(localStorage.getItem("authObj")).UserId;
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    await axios
      .get(`${serverURL}/favourites/${userId}`, config)
      .then((res) => {
       // console.log(JSON.stringify(res.data));
        if (res.data) {
          localStorage.setItem("favItems", JSON.stringify(res.data));
          data = res.data;
        } else {
          data = [];
        }
      })
      .catch((err) => {
        console.log("🚀 Can not get favourites", err);
        return [];
      });
    return data;
  } else {
    return [];
  }
};

export const getAllFavorites = async () => {
  const serverURL = process.env.REACT_APP_API_SERVER_URL;
  let data;
    await axios
      .get(`${serverURL}/favourites`)
      .then((res) => {
        if (res.data) {
          localStorage.setItem("allFavourites", JSON.stringify(res.data));
          data = res.data;
        } else {
          data = [];
        }
      })
      .catch((err) => {
        return [];
      });
    return data;
};

export const markFavoriteNFT = async (contract, token,itemName,collectionName,description,image) => {
  const serverURL = process.env.REACT_APP_API_SERVER_URL;
  if (isAuthenticated()) {
    if (contract && token) {
      
      const authObj = JSON.parse(localStorage.getItem("authObj"));
      const accessToken = authObj.AccessToken;
      const userId = authObj.UserId;
      const walletAddress = authObj.WalletAddress;
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` },
      };
      const bodyParameters = {
        userId: userId,
        token: token,
        contract: contract,
        name:itemName,
        description: description,
        image: image,
        collectionName: collectionName
      };

      await axios
        .post(`${serverURL}/favourites`, bodyParameters, config)
        .then((res) => {
          console.log(JSON.stringify(res.data));
        })
        .catch((err) => {
          console.log("err");
          console.log(err);
        });
    } else {
      return "invalid request";
    }
  }
};

export const unmarkFavoriteNFT = async (contract,token) => {
  const serverURL = process.env.REACT_APP_API_SERVER_URL;
  if (isAuthenticated()) {
    if (contract && token) {
      const accessToken = JSON.parse(
        localStorage.getItem("authObj")
      ).AccessToken;
      const userId = JSON.parse(localStorage.getItem("authObj")).UserId;
      const bodyParameters = {
        userId: userId,
        token: token,
        contract: contract,
      };
      await axios
        .delete(`${serverURL}/favourites`, {
          headers: {
            Authorization: `Bearer ${accessToken}`,
          },
          data: bodyParameters,
        })
        .then((res) => {
          console.log(JSON.stringify(res.data));
        })
        .catch((err) => {
          console.log("err");
          console.log(err);
        });
    } else {
      return "invalid request";
    }
  }
};

export const getWarehouseData = async () => {
  const serverURL = process.env.REACT_APP_API_SERVER_URL;
  let data;
  if (isAuthenticated()) {
    const accessToken = JSON.parse(localStorage.getItem("authObj")).AccessToken;
    const userId = JSON.parse(localStorage.getItem("authObj")).UserId;
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` },
    };
    await axios
      .get(`${serverURL}/warehouse/`, config)
      .then((res) => {
       // console.log(JSON.stringify(res.data));
        if (res.data) {
          localStorage.setItem("warehouse", JSON.stringify(res.data));
          data = res.data;
        } else {
          data = [];
        }
      })
      .catch((err) => {
        console.log("🚀 Can not get warehouse", err);
        return [];
      });
    return data;
  } else {
    return [];
  }
};

export const addRecentViews =  (contract,token) => {
let RecentViews = JSON.parse(localStorage.getItem("RecentViews")) ? JSON.parse(localStorage.getItem("RecentViews")) : [];
const index = RecentViews.findIndex((item) => item.contract === contract);
  if (index > -1) {
    RecentViews.splice(index, 1);
    RecentViews.push({contract:contract,token:token});
    localStorage.setItem("RecentViews",JSON.stringify(RecentViews));
  } else {
    RecentViews.push({contract:contract,token:token});
    localStorage.setItem("RecentViews",JSON.stringify(RecentViews));
  } 
}