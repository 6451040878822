/* eslint-disable no-undef */
/* eslint-disable react/no-direct-mutation-state */
/* eslint-disable no-unused-vars */
import React from "react";
import moment from 'moment';
import { withRouter } from "react-router";
import { Tooltip, Button, Grid } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import defaultAvatar from "../Component/assets/images/defaultAvatar.jpeg";
import { GET } from "../webServices/fetch.js";
import AssetCard from "../componets/cards/AssetCard";
import CameraAlt from "@material-ui/icons/CameraAlt";
import { truncateString,formateAddress } from "../helpers/strings";
import "bootstrap/dist/css/bootstrap.min.css";
import {  getAuthObj } from "../webServices/loginServices";
import { Container, Row, Col, Tab, Nav, Form, Card } from "react-bootstrap";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableContainer from "@material-ui/core/TableContainer";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Paper from "@material-ui/core/Paper";
import { getFavorites } from "../webServices/nftServices";
import ClearIcon from "@material-ui/icons/Clear";
import { windowLocation } from "../helpers/common";
import "../styles/style.css";
import axios from "axios";

class WalletProfilePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
        activeTab: "first",
        favorites: [],
        show: false,
        rows: [],
        isLoaded: false,
        fixPoint: 3,
        MarketplaceItems: [],
        sotringData: [],
        orignalData: [],
        isLoadedFavorites: false,
        wordsLength: 15,
        hobbies: [],
        collections: [],
        sliderVal: null,
        startPrice: 0,
        endPrice: 1000,
        checkedStatus: false,
        sortingStatus: false,
        sortingFilterStatus: false,
        selectedCollection: [],
        collectionFilterStatus: false,
        filtersData: [],
        applyFilterStatus: false,
        // newArrivalFilter:false,
        // buyNowFilter:false,
        // onAuctionFilter:false,
        // hasOfferFilter:false,
        // priceRangeFilter:false,
        button: "show",
        shows: true,
        newArrival: false,
        buyNow: false,
        auctions: false,
        offers: false,
        collection: false,
        collectionschek: false,
        category: false,
        categories: false,
        all: false,
        sliderStatus: true,
        ProfileImage: defaultAvatar,
        filterButtonsState: [],
        userAssets:[],
        userCollections:[],
        userTradeHistory:[],
        name: "",
        bio: "",
        email: "",
        authName: "",
        authBio: "",
        authEmail: ""
      };
  }

  

  
  
  
  
  handleClicksOpen = (event, newValue) => {
    document.getElementById("maxSliderVal").value = newValue;
    this.sortingBypriceSlide();
  };

  setFavorites =  () => {
    getFavorites().then((fav)=>{
      this.setState({favorites: fav});
    }).catch((error)=>{
      console.log(error);
    });
  };


  getUserAssets = async ()=> {
    const userWalletAddress = this.props.match.params.walletId;
      await axios
        .get(`${process.env.REACT_APP_API_SERVER_URL}/assets/created/${userWalletAddress}`)
        .then((res) => {
          this.setState({
            userAssets: res.data,
            isLoaded: true,
          });
        })
        .catch((err) => {
          console.log("err");
          console.log(err);
        });
  }
   getMyCollections = async () => {
    const userWalletAddress = this.props.match.params.walletId;
    const { data: Items } = await GET(
        `assets?owner=${userWalletAddress}&limit=50`
      );
      if (Items.assets) {
        // Set data to state
        this.setState({
            collections: Items.assets,
          isLoaded: true,
        });
    } 
  };
  getUserTradeHistory = async () => {
    const userWalletAddress = this.props.match.params.walletId;
    const serverURL = process.env.REACT_APP_API_SERVER_URL;
        await axios
          .get(`${serverURL}/userTradeHistory/${userWalletAddress}`)
          .then((res) => {
            console.log('getting userTradeHistory');
            this.setState({userTradeHistory:res.data});
          })
          .catch((err) => {
            console.log("Unable to get userTradeHistory", err);
          });
}

  componentDidMount() {
    this.setState({
      ProfileImage: (this.props.ProfileImage)
        ? this.props.ProfileImage
        : defaultAvatar,
    });
    const url = new URL(window.location.href);
    const query_activeTab = url.searchParams.get('tab');
      switch (query_activeTab) {
        case "collected":
          this.setState({activeTab:"first"});
          
          break;
        case "created":
          this.setState({activeTab:"second"});
          this.setState({ activeTab: 'second' }, () => {
            console.log(this.state.activeTab)
          }
         );
          
          
          break;
        case "favorites":
          this.setState({activeTab:"third"});
          break;
        case "trade":
          this.setState({activeTab:"four"});
          break;
        default:
          this.setState({activeTab:"four"});
          break;

      
      } 
    
    this.getMyCollections();
    this.getUserAssets();
    this.setFavorites();
    this.getUserTradeHistory();
    this.setState({name:getAuthObj().Username,email:getAuthObj().Email,bio:getAuthObj().Bio})
    this.setState({authName:getAuthObj().Username,authEmail:getAuthObj().Email,authBio:getAuthObj().Bio})
  }

  render() {
    const { shows,activeTab } = this.state;
    
    

    const onTabChanageHandle = async (tab) => {
      switch (tab) {
        case "first":
          this.getMyCollections();
          break;
        case "second":
          this.getUserAssets();
            break;
        case "third":
          this.setFavorites();
            break;
        case "four":
          this.getUserTradeHistory();
            break;
      
        default:
          break;
      }
      this.setState({activeTab:tab})
    }
    
    return (
      <>
        <div className="container-cmd">
          <Container>
            <Tab.Container id="left-tabs-example" defaultActiveKey={"four"} activeKey={this.state.activeTab} onSelect={(k) => onTabChanageHandle(k)}>
              <Row>
                <Col lg={{ span: 3 }}>
                  <div className="sidebar-fix">
                    <div className="card-profileuser">
                      <Card border="primary">
                        <Card.Body>
                         
                            <Row>
                              <Col lg={{ span: 12 }}>
                                <div style={{textAlign:"center"}}>
                                  <img
                                    style={{ width: "60px", height: "60px" }}
                                    alt="ProfileImage"
                                    src={
                                      this.state.ProfileImage
                                        ? this.state.ProfileImage
                                        : defaultAvatar
                                    }
                                  />
                                </div>
                            
                              <div className="info-user">
                                  <div className="user-name edit-profile-icons" style={{textAlign:'center',fontWeight: 500,fontSize:"14px"}}>
                                    {"Unnamed"}
                                  </div>
                                  <div className="email-user" style={{fontSize:"8px",fontWeight:400}}>
                                  {this.props.match.params.walletId}{" "}
                                  </div>
                                  
                                </div>
                              </Col>
                            </Row>
                        </Card.Body>
                      </Card>
                    </div>

                    <Row>
                      <Col lg={12}>
                        <div className="tabslink-top">
                          <b className="b" style={{ textAlign: "left" }}>ACTIVITY</b>
                          <Nav  variant="pills" className="flex-column">
                            <Nav.Item style={{cursor: "pointer" }}>
                              <Nav.Link eventKey="first" >
                                Collected
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item style={{cursor: "pointer" }}>
                              <Nav.Link eventKey="second" >
                               Created
                              </Nav.Link>
                            </Nav.Item>
                            <Nav.Item style={{cursor: "pointer" }}>
                              <Nav.Link eventKey="third">Favorites</Nav.Link>
                            </Nav.Item>
                            <Nav.Item style={{cursor: "pointer" }}>
                              <Nav.Link eventKey="four">Trade History</Nav.Link>
                              </Nav.Item>
                          </Nav>
                        </div>
                      </Col>
                      <Col lg={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="first"></Tab.Pane>
                          <Tab.Pane eventKey="second"></Tab.Pane>
                        </Tab.Content>
                      </Col>
                      
                      <Col lg={12}>
                        <Tab.Content>
                          <Tab.Pane eventKey="first"></Tab.Pane>
                          <Tab.Pane eventKey="second"></Tab.Pane>
                        </Tab.Content>
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col lg={{ span: 9 }} xs={{ span: 12 }}>
                 
                  
                  <div className="tabs-cards-component">
                    <Tab.Content>
                      <Tab.Pane eventKey="first">
                        <Row>
                          <Grid container spacing={1}>
                            {!this.state.isLoaded ? (
                              <div style={{ marginLeft: "13px" }}>
                                Loading...
                              </div>
                            ) : (
                              this.state.collections.map((item) => (
                                <Grid item sm={2} md={4}>
                                <AssetCard
                                asset={item}
                              />
                              </Grid>
                              ))
                            )}
                          </Grid>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="second">
                        <Row>
                          <Grid container spacing={1}>
                            {!this.state.isLoaded ? (
                              <div style={{ marginLeft: "13px" }}>
                                Loading...
                              </div>
                            ) : (
                              this.state.userAssets.map((item) => (
                                <Grid item sm={2} md={4}>
                                <AssetCard
                                  asset={item}
                                  key={
                                    item.collections +
                                    "/" +
                                    item.token
                                  }
                                />
                                </Grid>
                              ))
                            )}
                          </Grid>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane
                        eventKey="third"
                      >
                        <Row>
                        <Grid container>
                            {!this.state.favorites ? (
                              <div>Loading...</div>
                            ) : (
                              this.state.favorites.map((item, i) => (
                                <Grid item sm={2} md={4}>
                                <AssetCard
                                  favorite={true}
                                  update={this.setFavorites}
                                  asset={item}
                                  key={i}
                                />
                                </Grid>
                              ))
                            )}
                          </Grid>
                        </Row>
                      </Tab.Pane>
                      <Tab.Pane eventKey="four">
                        <Row>
                          <Col lg={{ span: 12 }}>
                            <TableContainer component={Paper}>
                              <Table aria-label="simple table">
                                <TableHead>
                                  <TableRow>
                                    <Typography
                                      variant="h6"
                                      id="tableTitle"
                                      component="div"
                                      style={{ padding: "20px" }}
                                    >
                                      Trade History
                                    </Typography>
                                  </TableRow>
                                  <TableRow>
                                    <TableCell>Event</TableCell>
                                    <TableCell>Item</TableCell>
                                    {/* <TableCell>Unit Price</TableCell>
                                    <TableCell>Quantity</TableCell> */}
                                    <TableCell>From</TableCell>
                                    <TableCell>To</TableCell>
                                    <TableCell>Date</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {this.state.userTradeHistory.map((row, i) => (
                                    <TableRow key={i}>
                                      <TableCell component="th" scope="row">
                                      <Typography >{row.eventType.toUpperCase()}</Typography>
                                      </TableCell>
                                      <TableCell>
                                      <div style={{display:"flex",cursor: "pointer"}} onClick={() => windowLocation(`/asset/${row.contractAddress}/${row.tokenId}`)}>
                                       <div className={'asset-cell-image'}>
                                       <img src={row.image} className={'asset-image'} />
                                      </div>
                                      <div style={{padding:"5px"}}>
                                      <div >
                                        <p className={'asset-cell-collectionName'}>{row.collectionName}</p>
                                        </div>
                                        <div className={'asset-cell-nftName'}>
                                        <Typography>{row.name}</Typography>
                                        </div>
                                      </div>
                                      </div>
                                        </TableCell>
                                      <TableCell><Tooltip title={row.from ? row.from.toUpperCase() : ""} arrow><Typography>{row.from ? formateAddress(row.from) : ""}</Typography></Tooltip></TableCell>
                                      <TableCell><Tooltip title={row.to ? row.to.toUpperCase() : ""} arrow><Typography>{row.to ? formateAddress(row.to) : ""}</Typography></Tooltip></TableCell>
                                      <TableCell><Typography>{moment(row.created).local().fromNow()}</Typography></TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Col>
                        </Row>
                      </Tab.Pane>
                    </Tab.Content>
                  </div>
                </Col>
              </Row>
            </Tab.Container>
          </Container>
        </div>
      </>
    );
  }
}

export default withRouter(WalletProfilePage);
