import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import ComingSoon from "../assets/coming_soon.jpg";
import Boxes from "../assets/images/box.png";
import btc from "../assets/images/btc.png";
import nft from "../assets/images/nft.webp";

function Coming() {
  return (
    <>
      <div className="container">
        <div className="coming-soon-icon">
          <Row>
          <Col  lg={{ span: 2 }} />
            <Col  lg={{ span: 8 }}>
                <div style={{display:'flex'}}>
                <img src={ComingSoon} style={{padding:"20px"}}/>
                
                </div>
            </Col>
            <Col  lg={{ span: 2 }} />
          </Row>
        </div>
      </div>
    </>
  );
}

export default Coming;
