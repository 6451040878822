import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import CardMedia from "@material-ui/core/CardMedia";
import { Column, Item } from "@mui-treasury/components/flex";
import { Row, Col } from "react-bootstrap";
import { useFourThreeCardMediaStyles } from "@mui-treasury/styles/cardMedia/fourThree";
import { Badge } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  card: {
    border: "2px solid",
    borderColor: "#E7EDF3",
    borderRadius: 16,
    transition: "0.4s",
    "&:hover": {
      borderColor: "#5B9FED",
    },
  },
  media: {
    borderRadius: 6,
    paddingBottom: "100%",
    marginBottom: 15,
  },
  smCardBox: {
    border: "1px solid #c9c9c9",
  },

  avatarStyle: {
    borderRadius: "50px",
    width: "55px",
    height: "55px",
    display: "flex",
    border: "2px solid #0084ff",
    padding: 5,
  },
  numberinCard: {
    fontWeight: 900,
    color: "#c9c9c9",
  },

  yelloBadge: {
    position: "absolute",
    right: -8,
    top: -6,
  },
}));

export const TabsCards = React.memo(function ShowcaseCard(props) {
  const styles = useStyles();
  const mediaStyles = useFourThreeCardMediaStyles();
  const gap = { xs: 1, sm: 1.5, lg: 2 };
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} md={3}>
      <Column
        className={styles.card}
        p={{ xs: 0.5, sm: 0.75, lg: 1 }}
        gap={gap}
      >
        <CardMedia
          className={cx(styles.media, mediaStyles.root)}
          image={
            props.FeaturedImage ??
            "https://images.unsplash.com/photo-1500462918059-b1a0cb512f1d?ixlib=rb-1.2.1&auto=format&fit=crop&w=934&q=80"
          }
        />
        <Row>
          <Item p={1}>
            <div className="img-profile-center">
              <Badge>
                <div className={classes.avatarStyle}>
                  <Avatar
                    variant={"circle"}
                    src={
                      props.Image ??
                      "https://www.biography.com/.image/t_share/MTU0ODUwMjQ0NjIwNzI0MDAx/chris-hemsworth-poses-during-a-photo-call-for-thor-ragnarok-on-october-15-2017-in-sydney-australia-photo-by-mark-metcalfe_getty-images-for-disney-square.jpg"
                    }
                  />
                </div>
                {/* <SvgIcon component={yelloico} className={classes.yelloBadge} style={{}}  viewBox="0 0 400 476.6"  /> */}
              </Badge>
            </div>
          </Item>
          <Row>
            <Col lg={{ span: 12 }}>
              <div className="profile-name">
                {props.name ?? "Collection title"}
              </div>
            </Col>
            <Col lg={{ span: 12 }}>
              <div className="description-name">
                {/* {props.TotalItems ?? '0 Items'} */}
              </div>
            </Col>
          </Row>
        </Row>
        <Row></Row>
      </Column>
    </Grid>
  );
});
export default TabsCards;
