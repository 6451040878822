import { OpenSeaPort, Network } from 'opensea-js';
import { infraWeb3,getWeb3,isAuthenticated } from "./loginServices";

export const getSeaport = async () => {
        let providerFunction;
    if (localStorage.getItem("isAuthenticated") ) {
        let authObj = JSON.parse(localStorage.getItem("authObj"));
        if (authObj.IsWalletConnected) {
            providerFunction =  getWeb3;
        }else {
            providerFunction =  infraWeb3;
        }
        
    } else {
        providerFunction =  infraWeb3;
    }
    const providerWeb3= await providerFunction();
    return new OpenSeaPort(providerWeb3.currentProvider, {apiKey: "c84357b40d684e27bc752108d4b30c61",networkName: process.env.REACT_APP_NETWORK==="rinkeby" ? Network.Rinkeby : Network.Main});
}