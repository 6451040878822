import Button from "@restart/ui/esm/Button";
export const windowLocation = (url) => {
  window.location.href = url;
  return false;
};
export const ButtonGroup = ({ next, previous, goToSlide, ...rest }) => {

  const { carouselState: { currentSlide } } = rest;
  // console.log(rest);
  // console.log(rest.carouselState.totalItems);
  // console.log(rest.carouselState.slidesToShow);
  // console.log(rest.carouselState.currentSlide);
  // console.log(rest.carouselState.totalItems - rest.carouselState.slidesToShow);  
  const endSlide = rest.carouselState.totalItems - rest.carouselState.slidesToShow;
  const cSlide = rest.carouselState.currentSlide;
  // var goToslide = false;
  if(endSlide === cSlide){
    var goToslide = true;
  }
  return (
    <div className="carousel-button-group"> 
    <Button   className=  'react-multiple-carousel__arrow react-multiple-carousel__arrow--right' style={{top:'50%',position:'absolute'}} type="button" onClick={() => goToslide?goToSlide(0, true):next()}></Button>
    <Button className="react-multiple-carousel__arrow react-multiple-carousel__arrow--left" style={{top:'50%',position:'absolute'}} onClick={() => cSlide === 0?goToSlide(endSlide, true):previous()} />
      {/* <Button className={currentSlide === 0 ? 'disable' : ''} onClick={() => previous()} />
      <Button onClick={() => next()} /> */}
      {/* <Button onClick={() => goToSlide(currentSlide + 1)}> Go to any slide </Button> */}
    </div>
  );
};
