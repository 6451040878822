import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import OceanAppFooterDemo from "../../containers/footer/Footer";
import PrimarySearchAppBar from "../../containers/header/Header";
import { Row, Col, Button } from "react-bootstrap";
import CareerImg from "../../assets/careers-at-mintjar-2.png";


function Careers() {
  return (
    <div>
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg">
          <PrimarySearchAppBar />
          <div className="container">
            <div className="coming-soon-icon">
            <Row>
            <Col  lg={{ span: 2 }} />
                <Col  lg={{ span: 9 }}>
                    <h1 style={{textAlign:'left'}}>Careers </h1>
                    <img src={CareerImg} style={{paddingBottom:"10px",width:"900px"}}/>
                    
                    <p style={{textAlign:'left',fontFamily:"arial",fontSize:"17px"}}>
                    Together, we create access to crypto and NFT, and build products for everyone. We are building a robust, diverse talent pool to support our industry’s growth.</p>
                    <p style={{textAlign:'left',fontFamily:"arial",fontSize:"17px"}}><b>Want to be a MintJar “Minter”? Join our team! </b></p>
                    <p style={{textAlign:'left',fontFamily:"arial",fontSize:"17px"}}>Please send your resume to: <a href="mailto:career@mintjar.com">career@mintjar.com</a>
                        </p>
                    
                
                
                
                </Col>
            </Row>
            </div>
        </div>
        </Container>
      </React.Fragment>
      <OceanAppFooterDemo />
    </div>
  );
}

export default Careers;
