import React from "react";
import { Grid, Typography, Box } from "@material-ui/core";
import FeaturedCard from "../componets/cards/FeaturedCard";
import { Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import {ButtonGroup } from "../helpers/common";
import "react-multi-carousel/lib/styles.css";
import CircularProgress from "@material-ui/core/CircularProgress";
import { truncateString } from "../helpers/strings";
import { GET,dbGET } from "../webServices/fetch.js";
import axios from "axios";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 4,
  },
  tablet: {
    breakpoint: { max: 1024, min: 575 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
  },
};  
class FeaturedCards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      FeaturedNFTs: [],
      isLoaded: false,
      wordsLength: 120,
    };
  }
  componentDidMount() {
    const getFeaturedData = async () =>{

    //   let url ="https://scraper.mintjar.co/Ranking/?format=json&number_of_records=10";
    //   await axios({
    //     method: 'get',
    //     url: url,
    //     withCredentials: false,
    //   }).then((res) => {
    //   console.log("🚀 ~ file: FeaturedCards.js ~ line 44 ~ FeaturedCards ~ .then ~ res", res)
    //       if (res.data){
    //         this.setState({
    //           FeaturedNFTs:res.data,
    //           isLoaded:true
    //       });
    //       }
    //   }).catch((err) => {
    //     console.log("🚀  ERROR", err);
    //   });

    const { data: Items } = await dbGET(`v1/collections/top`)
    if (Items) {
      // Set data to state
      this.setState({
        FeaturedNFTs: Items,
        isLoaded: true,
      });

     }
    }
    getFeaturedData();
    
}

  render() {
    // const { FeaturedNFTs, isLoaded } = this.state;
    return (
      <Grid sm={12}>
        <Typography variant="h3" component="h3" className={"grid_title"}>
          <Box fontSize={"24px"} about="Popular NFT Collections">Popular NFT Collections</Box>
        </Typography>
		<Row className="marginOpx">
        <Col lg={{ span: 12 }} xs={{ span: 12 }} style={{position:'relative'}}>
        <div
          className="responsive-view homePageCarousel"
          style={{ marginTop: "0px", paddingTop: 0,position:'relative' }}
        >
          <Carousel
                            responsive={responsive}
                            arrows = {false}
                            customButtonGroup={<ButtonGroup
                                next={this.props.next}
                                previous={this.props.previous}
                                rest={this.props.rest}
                            />}
                        >
            
            {!this.state.isLoaded ? (
              <div className="loading-icons" style={{textAlign: "center", justifyContent: "center", display: "inline-block"}}>
                  <Row>
                  <Col lg={{ span: 11 }} xs={{ span: 12 }}>
                    <div class="loader-container">
                    <CircularProgress disableShrink />
                    </div>
                  </Col>
                  </Row>
                  
                  </div>
            ) : (
              this.state.FeaturedNFTs.map((item,i) => (
			  <Col lg={{ span: 11 }} xs={{ span: 12 }} key={i}>
                        <div className="cards-iii">
               <a alt="Mintjar - ${item.slug} Collection" href={`/marketplace?collections=${item.slug}`}><FeaturedCard
              name={truncateString(item.name, 25)}
              description={
                item.description ? item.description : "Highly sought after NFT collection!"
              }
              image={item.image_url ? item.image_url.split("=")[0] : ""}
              key={item.slug}
            /></a>
			</div>
                      </Col>
              ))
            )}
          </Carousel>
		  </div>
		  </Col>
		  </Row>
        
      </Grid>
     
    );
  }
}
export default FeaturedCards;
