import React from "react";
import { Row, Col, Button } from "react-bootstrap";
import Boxes from "../assets/images/box.png";
import Logo from "../assets/mintjar_blue_bg.png";
import btc from "../assets/images/btc.png";
import nft from "../assets/images/nft.webp";

function Coming() {
  return (
    <>
      <div className="container">
        <div className="coming-soon-icon">
          <Row>
          <Col  lg={{ span: 2 }} />
            <Col  lg={{ span: 9 }}>
              
                <h1 style={{textAlign:'left',fontFamily:"arial" }}>About Us</h1>
                <div style={{display:"flex"}}>
                <img src={Logo} style={{padding:"20px",height:"fit-content"}}/>
                <p style={{textAlign:'left',fontFamily:"arial",fontSize:"17px",fontWeight: 500,padding:"20px"}}>
                Founded in 2022, and born during the pandemic, MintJar is  an Austin,Taxes based startup, on a mission to maximize utility of NFTs by unlocking the true value of digital assets & the metaverse! <br/><br/>
                Our team of “Minters” are passionate about making NFTs accessible by enabling it as a means of exchanging digital tokens that link to a digital life. <br/><br/>
                Our platform serves as a decentralized peer-to-peer technology for buying, selling and trading rare digital goods, from gaming items to collectibles to art, which are built on NFT technology and run on the Ethereum blockchain. <br/><br/>
                But that’s just our starting point – we have an exciting roadmap of products and services, stay tuned!
                </p>
                </div>
              {/* <img style={{width:'100%'}} src={btc} /> */}
            
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

export default Coming;
