import React from "react";
import {  Col, Card, Button, Container } from "react-bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Box, Typography } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import { Row, Column, Item } from "@mui-treasury/components/flex";
import cx from "clsx";
import { useFourThreeCardMediaStyles } from "@mui-treasury/styles/cardMedia/fourThree";
const useStyles = makeStyles(() => ({
    card: {
      border: "2px solid",
      borderColor: "#E7EDF3",
      borderRadius: 16,
      transition: "0.4s",
      "&:hover": {
        borderColor: "#5B9FED",
      },
    },
    media: {
      borderRadius: 6,
      paddingBottom: "100%",
      marginBottom: 15,
    },
  }));
  export const ListingsCard = React.memo(function ShowcaseCard(props) {
    const styles = useStyles();
  const mediaStyles = useFourThreeCardMediaStyles();
  const gap = { xs: 1, sm: 1.5, lg: 2 };
  const cardinfo = [
    {
      image: "",
      title: "Title",
      text: "Collection Name",
    }
    
  ];

  const renderCard = (card, index) => {
    return (
      <>
      <article className="feature1">
      <input type="checkbox" id="feature1"/>
      <div className="add-checked-option">
       <Grid item xs={6} md={12} lg={12} className="preview-card-margin">
      <Column
        className={styles.card}
        p={{ xs: 0.5, sm: 0.75, lg: 3 }}
        gap={gap}
      >
        <CardMedia
          className={cx(styles.media, mediaStyles.root)}
          image={props.image}
        ></CardMedia>

        <Row>
          <Item position={"start"}>
            <Typography fontSize={8}>
              <Box fontSize={"0.75rem"}>
                {props.name ? props.name : "Title"}
              </Box>
              <Box style={{ fontSize: "11px", color: "rgb(112, 122, 131)" }}>
                {props.collection ? props.collection : "Collection Name"}
              </Box>
            </Typography>
          </Item>
        </Row>
      </Column>
    </Grid>
    </div>
    </article>
      </>
    );
  };

  return (
    <>
     

      <div className="grid ">{cardinfo.map(renderCard)}</div>
    </>
  );
});
export default ListingsCard;
