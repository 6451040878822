import { React, useState,useEffect } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Meta from "../../src/assets/meta.png";
import MailIcon from "@material-ui/icons/Mail";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import {
  setMetamaskAccounts,
  loginMetamask,
  isAuthenticated,
  setLoginStorage,
  responseGoogleSuccess,
  responseGoogleFailed,
  loginViaEmail,
} from "../webServices/loginServices";
import OnboardingButton from "../Pages/metamaskButton";
import "../styles/style.css";

function LoginId() {
  isAuthenticated();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setError] = useState("");

  function validateForm() {
    return email.length > 0 && password.length > 0;
  }
  function handleSubmit(event) {
    event.preventDefault();
    loginViaEmail(email, password)
      .then((response) => {
        const profile = response.data.profile;
        //
        setLoginStorage(
          profile.userId,
          profile.name,
          profile.email,
          "Email",
          false,
          "NAN",
          0,
          profile.profileImage,
          profile.bio,
          response.data.accessToken,
          response.data.refreshToken
        );
      })
      .catch((error) => {
        console.log(error);
        setError(error.response.data.errors[0]);
      });
  }

  return (
    <>
      <div className="container-cmd">
        <Container>
          <Row>
            <Col lg={{ span: 12 }}>
            
              <div className="registered-sign">
                <Row>
                  <Col lg={{ span: 7, offset: 3 }}>
                  

                  <div className="forms-tag">
                    <h4>Connect your wallet to sign-in</h4><br/>
                    <h6>To buy and sell, Metamask wallet is required</h6>
                    <h6>It only takes a minute!</h6><br/><div className="LoginButton">
                    <OnboardingButton></OnboardingButton>
                    <p style={{marginTop: '30px'}}><b>Note:</b> return to MintJar after connecting your wallet</p>
                </div>
                </div>
                

                    
                        
                       
                


                      {/* <Form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Control
                            type="email"
                            placeholder="Enter email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                          />
                        </Form.Group>

                        <Form.Group
                          className="mb-3"
                          controlId="formBasicPassword"
                        >
                          <Form.Control
                            type="password"
                            placeholder="Password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                          />
                        </Form.Group>
                        <div className="log-in">
                          <Button
                            variant="primary"
                            className="light-shadow "
                            type="submit"
                            disabled={!validateForm()}
                          >
                            Login
                          </Button>

                          {errors ? (
                            <p style={{ fontSize: "small", color: "red" }}>
                              {errors}
                            </p>
                          ) : (
                            ""
                          )}
                        </div>
                        <span>OR</span>

                        <div className="LoginButton">
                          <a href="signup" style={{ color: "white" }}>
                            <Button
                              className={"btnOther"}
                              style={{ fontSize: "14px" }}
                            >
                              <MailIcon style={{ marginRight: 20 }} /> Sign up
                              using email
                            </Button>
                          </a>
                        </div>
                        <div className="LoginButton">
                          <GoogleLogin
                            className="btnGoogle"
                            clientId="659915795219-holg20amggr1fle5p4fmch5r926dlbil.apps.googleusercontent.com"
                            onSuccess={responseGoogleSuccess}
                            onFailure={responseGoogleFailed}
                            cookiePolicy={"single_host_origin"}
                          />
                        </div>
                        <div className="LoginButton">
                          <FacebookLogin
                            cssClass="btnFacebook"
                            appId="1752059724984984"
                            fields="name,email,picture"
                          />
                        </div> */}
                        
                      {/* </Form>
                    </div> */}
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default LoginId;
