import React from "react";
import { Row, Col, Card, Button, Container } from "react-bootstrap";
import Bird from "../assets/images/bird-icon.jpeg";
import "../styles/style.css";

function Listings() {
  const cardinfo = [
    {
      image: "",
      title: "Creature V30",
      text: "Dave Starbelly",
    },
    {
      image: "",
      title: "Creature V30",
      text: "Dave Starbelly",
    },
    {
      image: "",
      title: "Creature V30",
      text: "Dave Starbelly",
    },
    {
      image: "",
      title: "Creature V30",
      text: "Dave Starbelly",
    },
    {
      image: "",
      title: "Creature V30",
      text: "Dave Starbelly",
    },
  ];

  const renderCard = (card, index) => {
    return (
      <>
        <div className="container-md listings check-order-cards">
          <Card
            style={{
              width: "100%",
              borderRadius: "20px",
              marginBottom: "5px",
              boxShadow:
                "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)",
            }}
            key={index}
            className="box"
            for="check01"
            type="checkbox"
          >
            <div className="row">
              <div className="col-lg-2 col-md-2 col-sm-6 col-xs-10">
                <Card.Img
                  style={{
                    width: "100%",

                    padding: "5px",
                  }}
                  variant="top"
                  src={Bird}
                />
              </div>
              <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                <Card.Body>
                  <Card.Title>
                    <h6
                      style={{
                        color: "gray",
                        fontSize: "16px",
                      }}
                    >
                      {" "}
                      {card.title}{" "}
                    </h6>
                  </Card.Title>
                  <Card.Text>
                    <h3
                      style={{
                        color: "black",
                        fontSize: "18px",
                      }}
                    >
                      {card.text}
                    </h3>
                  </Card.Text>
                </Card.Body>
                <hr />
                <div className="row">
                  <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                    <Card.Body>
                      <div className="order-buttons">
                        <ul>
                          <li>
                            <Button variant="outline-info">
                              Generation<p>22</p>
                            </Button>
                          </li>
                          <li>
                            <Button variant="outline-info">
                              Personality<p>21</p>
                            </Button>
                          </li>
                          <li>
                            <Button variant="outline-info">
                              Standards<p>22</p>
                            </Button>
                          </li>
                          <li>
                            <Button variant="outline-info">
                              Definations<p>22</p>
                            </Button>
                          </li>
                          <li>
                            <Button variant="outline-info">
                              Persoanity<p>22</p>
                            </Button>
                          </li>
                          <li>
                            <Button variant="outline-info">
                              Generations<p>22</p>
                            </Button>
                          </li>
                        </ul>
                      </div>
                    </Card.Body>
                  </div>
                </div>
              </div>
            </div>
          </Card>
        </div>
      </>
    );
  };

  return (
    <>
      <Container>
        <Row>
          <Col lg={{ span: 4 }}>
            <div className="form-group has-search sort-search">
              <span className="fa fa-search form-control-feedback"></span>
              <select className="form-control" placeholder="Sort By">
                <option>Sort By:</option>
                <option>Created: New to Old</option>
                <option>Created: Old to New</option>
                <option>Recently Sold</option>
                {/* <option>Ending Soon</option> */}
                <option>Price: Low to High</option>
                <option>Price: High to Low</option>
                <option>Most Popular</option>
              </select>
            </div>
          </Col>
        </Row>
      </Container>

      <div className="grid">{cardinfo.map(renderCard)}</div>
    </>
  );
}
export default Listings;
