import React from "react";
import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import OceanAppFooterDemo from "../../containers/footer/Footer";
import PrimarySearchAppBar from "../../containers/header/Header";
import { Row, Col, Button } from "react-bootstrap";


function FAQs() {
  return (
    <div>
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg">
          <PrimarySearchAppBar />
          <div className="container">
            <div className="coming-soon-icon">
            <Row>
            <Col  lg={{ span: 2 }} />
                <Col  lg={{ span: 8 }}>
                    <h5 style={{textAlign:'left'}}>Decentralized Business Models with Minting NFTs</h5>
                    <h6 style={{textAlign:'left'}}>Introduction to Minting NFTs</h6>
                    <p style={{textAlign:'left'}}>                   
                    Digital assets are a new form of ownership that can exist as one or more types of digital files. These digital items can be augmented with non-fungible tokens (NFTs), which are tokens that cannot be exchanged, swapped, or transferred. An example of an NFT is something like the digital representation of a baseball card. These digital cards have limited edition and rarity values just like their physical
                    </p>
<h6 style={{textAlign:'left'}}>How to Mint Your Own Token on Ethereum?</h6>
<p style={{textAlign:'left'}}>
ICOs are becoming more popular, but how does one make their own token? There are various ways token sales can be conducted. Some projects choose to do a traditional ICO (Initial Coin Offering) by selling tokens in order to fund the development of the project. Others will do an STO (Security Token Offering), which is similar to an IPO for stocks, but with blockchain securities. Investors purchase
</p>

<h6 style={{textAlign:'left'}}>What are the Most Promising Platforms for Token Creation?</h6>
<p style={{textAlign:'left'}}>
If you are planning on creating a token site, make sure to use the best NFT platform. This is because it will give your project credibility. The best NFT platform will also provide you with access to other talented developers who can help build your site.
                        </p>
                    
                        
                <br />
                <br />
                
                </Col>
            </Row>
            </div>
        </div>
        </Container>
      </React.Fragment>
      <OceanAppFooterDemo />
    </div>
  );
}

export default FAQs;
