import React from "react";

import CssBaseline from "@material-ui/core/CssBaseline";
import Container from "@material-ui/core/Container";
import OceanAppFooterDemo from "../containers/footer/Footer";
import PrimarySearchAppBar from "../containers/header/Header";
import SignupId from "../componets/SingupId";

function Signup() {
  return (
    <div>
      <React.Fragment>
        <CssBaseline />
        <Container maxWidth="lg">
          <PrimarySearchAppBar />

          <SignupId />
        </Container>
      </React.Fragment>
      <OceanAppFooterDemo />
    </div>
  );
}

export default Signup;
