import axios from "axios";
import { isAuthenticated } from "./loginServices";

// Requires userId,userWalletAddress,contractAddress,tokenId,from{nullAddress},to
export const logMintTrade = async (name,collectionName,image,contractAddress,tokenId,transactionHash) => {
    
  if (isAuthenticated()) {
    const accessToken = JSON.parse(localStorage.getItem("authObj")).AccessToken;
    const userId = JSON.parse(localStorage.getItem("authObj")).UserId;
    const userWalletAddress = JSON.parse(localStorage.getItem("authObj")).WalletAddress;
    const serverURL = process.env.REACT_APP_API_SERVER_URL;
    const config = {
      headers: { Authorization: `Bearer ${accessToken}` }
    };
    const body={name,collectionName,image,userId:userId,userWalletAddress:userWalletAddress,eventType:'mint',from:'NullAddress',to:userWalletAddress,contractAddress,tokenId,transactionHash:transactionHash};
    await axios
      .post(`${serverURL}/trade`, body,config)
      .then((res) => {
        console.log('LogMintTrade Successfully');
        console.log(JSON.stringify(res.data));
      })
      .catch((err) => {
        console.log("Unable to LogMintTrade", err);
      });
  }
}

// Requires userId,userWalletAddress,contractAddress,tokenId,price
export const logListTrade = async (name,collectionName,image,contractAddress,tokenId,transactionHash,price) => {
    if (isAuthenticated()) {
        const accessToken = JSON.parse(localStorage.getItem("authObj")).AccessToken;
        const userId = JSON.parse(localStorage.getItem("authObj")).UserId;
        const userWalletAddress = JSON.parse(localStorage.getItem("authObj")).WalletAddress;
        const serverURL = process.env.REACT_APP_API_SERVER_URL;
        const config = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        const body = {name,collectionName,image,userId:userId,userWalletAddress:userWalletAddress,eventType:'list',from:userWalletAddress,price:price,contractAddress,tokenId,transactionHash:transactionHash};
        await axios
          .post(`${serverURL}/trade`,body, config)
          .then((res) => {
            console.log('LogListTrade Successfully');
            console.log(JSON.stringify(res.data));
          })
          .catch((err) => {
            console.log("Unable to LogListTrade", err);
          });
      }
}

export const logSaleTrade = async (name,collectionName,image,contractAddress,tokenId,transactionHash,price,from,to) => {
    if (isAuthenticated()) {
        const accessToken = JSON.parse(localStorage.getItem("authObj")).AccessToken;
        const userId = JSON.parse(localStorage.getItem("authObj")).UserId;
        const userWalletAddress = JSON.parse(localStorage.getItem("authObj")).WalletAddress;
        const serverURL = process.env.REACT_APP_API_SERVER_URL;
        const config = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        const body = {name,collectionName,image,userId:userId,userWalletAddress:userWalletAddress,eventType:'sale',from:userWalletAddress,price:price,contractAddress,tokenId,transactionHash:transactionHash,from:from,to:to};
        await axios
          .post(`${serverURL}/trade`,body, config)
          .then((res) => {
            console.log('LogSaleTrade Successfully');
            console.log(JSON.stringify(res.data));
          })
          .catch((err) => {
            console.log("Unable to LogSaleTrade", err);
          });
      }

}

export const logTransferTrade = async (name,collectionName,image,contractAddress,tokenId,transactionHash,price,from,to) => {
    if (isAuthenticated()) {
        const accessToken = JSON.parse(localStorage.getItem("authObj")).AccessToken;
        const userId = JSON.parse(localStorage.getItem("authObj")).UserId;
        const userWalletAddress = JSON.parse(localStorage.getItem("authObj")).WalletAddress;
        const serverURL = process.env.REACT_APP_API_SERVER_URL;
        const config = {
          headers: { Authorization: `Bearer ${accessToken}` }
        };
        const body = {name,collectionName,image,userId:userId,userWalletAddress:userWalletAddress,eventType:'transfer',from:userWalletAddress,price:price,contractAddress,tokenId,transactionHash:transactionHash,from:from,to:to};
        await axios
          .post(`${serverURL}/trade`,body, config)
          .then((res) => {
            console.log('LogTransferTrade Successfully');
            console.log(JSON.stringify(res.data));
          })
          .catch((err) => {
            console.log("Unable to LogTransferTrade", err);
          });
      }
}

export const transferAsset = async (contractAddress,tokenId,ownerAddress,dataObj) => {
  if (isAuthenticated()) {
      const accessToken = JSON.parse(localStorage.getItem("authObj")).AccessToken;
      const serverURL = process.env.REACT_APP_API_SERVER_URL;
      const config = {
        headers: { Authorization: `Bearer ${accessToken}` }
      };
      const body = {contract:contractAddress,token:tokenId,owner:ownerAddress,data:dataObj};
      await axios
        .post(`${serverURL}/asset/transfer`,body, config)
        .then((res) => {
          console.log('Asset Transfer Successfully');
          console.log(JSON.stringify(res.data));
        })
        .catch((err) => {
          console.log("Unable to Transfer Asset", err);
        });
    }
}

