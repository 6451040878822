import React from "react";
import { useState,useEffect } from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import { Typography, Box, IconButton } from "@material-ui/core";
import CardMedia from "@material-ui/core/CardMedia";
import { Row, Column, Item } from "@mui-treasury/components/flex";
import { useFourThreeCardMediaStyles } from "@mui-treasury/styles/cardMedia/fourThree";
import { windowLocation } from "../../helpers/common";
import { truncateString } from "../../helpers/strings";
import { red } from "@material-ui/core/colors";
import FavoriteBorderIcon from "@material-ui/icons/FavoriteBorder";
import FavoriteIcon from "@material-ui/icons/Favorite";
import { getFavorites } from "../../webServices/nftServices";
import ethrLogo from "../../assets/ethereum-logo.png";
import {
  markFavoriteNFT,
  unmarkFavoriteNFT,
} from "../../webServices/nftServices";
import Web3 from "web3";

const useStyles = makeStyles(() => ({
  card: {
    border: "2px solid",
    borderColor: "#E7EDF3",
    borderRadius: 16,
    transition: "0.4s",
    "&:hover": {
      borderColor: "#5B9FED",
    },
  },
  media: {
    borderRadius: 6,
    paddingBottom: "100%",
    marginBottom: 15,
  },
}));

export const AssetCard = React.memo(function ShowcaseCard(props) {
  const styles = useStyles();
  const mediaStyles = useFourThreeCardMediaStyles();
  const [isClick, setClick] = useState(false);
  const [contractAddress, setContractAddress] = useState("");
  const [tokenId, setTokenId] = useState("");
  const [assetName, setAssetName] = useState("");
  const [collectionTitle, setCollectionTitle] = useState("");
  const [description, setDescription] = useState("");
  const [image, setImage] = useState("");
  const [basePrice, setBasePrice] = useState("");
  
 


  useEffect(() => {
    if (props.favorite) {
      setClick(true);
    }
     
    if (props.asset.asset_contract) {
      setContractAddress(props.asset.asset_contract.address);
      setTokenId(props.asset.token_id);
      setAssetName(props.asset.name ? props.asset.name : props.asset.token_id );
      setCollectionTitle(props.asset.collection.name);
      setDescription(props.asset.description);
      // setImage(props.asset.image_url ? props.asset.image_url.split("=")[0]+"=h200"  : (props.asset.asset_contract.image_url ? props.asset.asset_contract.image_url.split("=")[0]+"=h200" : ""));
      setImage(props.asset.image_url ? props.asset.image_url  : (props.asset.asset_contract.image_url ? props.asset.asset_contract.image_url : ""));
      if(props.asset.sell_orders){
        if (props.asset.sell_orders.length > 0) {
          setBasePrice(Web3.utils.fromWei(props.asset.sell_orders[0].base_price.toString()))
        }
      }

    } else {
      setContractAddress(props.asset.contract);
      setTokenId(props.asset.token);
      setAssetName(props.asset.name);
      setCollectionTitle(props.asset.collectionTitle ? props.asset.collectionTitle : "" );
      setDescription(props.asset.description);
      setImage(props.asset.image)
      if(props.asset.sellOrders){
        if (props.asset.sellOrders.length > 0) {
          setBasePrice(Web3.utils.fromWei(props.asset.sellOrders[0].basePrice.toString()))
        }
      }
    }
    if (props.asset.assetContract) {
      setContractAddress(props.asset.assetContract.address);
      setTokenId(props.asset.tokenId);
      setAssetName(props.asset.name ? props.asset.name : "#" + props.asset.tokenId );
      setCollectionTitle(props.asset.collection.name);
      setDescription(props.asset.description);
      // setImage(props.asset.imageUrl ? props.asset.imageUrl : (props.asset.assetContract.imageUrl ? props.asset.assetContract.imageUrl.split("=")[0]+"=h200" : ""))
      setImage(props.asset.imageUrl ? props.asset.imageUrl : (props.asset.assetContract.imageUrl ? props.asset.assetContract.imageUrl : ""))
      if(props.asset.orders){
        if (props.asset.orders.length > 0) {
          setBasePrice(Web3.utils.fromWei(props.asset.orders[0].basePrice.toString()))
        }
      }
      if(props.asset.sellOrders){
        if (props.asset.sellOrders.length > 0) {
          setBasePrice(Web3.utils.fromWei(props.asset.sellOrders[0].basePrice.toString()))
        }
      }

    }

    
    
   

   }, [props.asset]);

   
   useEffect(() => {
    let favoriteItems = localStorage.getItem("favItems") ? JSON.parse(localStorage.getItem("favItems")) : [];
    let fiContract,fiToken
    if (props.asset.asset_contract) {
      fiContract=props.asset.asset_contract.address;
      fiToken=props.asset.token_id;
    }
    else { 
      fiContract=props.asset.contract;
      fiToken=props.asset.token;

    }
    if (props.asset.assetContract) { 
      fiContract=props.asset.assetContract.address;
      fiToken=props.asset.tokenId;
    }
      if(favoriteItems.some(asset => asset.contract == fiContract && asset.token==fiToken)) {
          setClick(true);
        }
  }, [])

  const markFavorite = (e) => {
    e.stopPropagation();
    if (isClick) {
      unmarkFavoriteNFT(contractAddress,tokenId);
      if(props.update){
        props.update();
      }
    } else {
      markFavoriteNFT(contractAddress,tokenId,assetName,collectionTitle,description,image);
    }
    setClick(!isClick);
  };

  const gap = { xs: 1, sm: 1.5, lg: 2 };
  return (
    <Grid item xs={11} md={12}>
      <a
        onClick={() => windowLocation(`/asset/${contractAddress}/${tokenId}`)}
        style={{ cursor: "pointer" }}
      >
        <Column
          style={{ margin: 10 }}
          className={styles.card}
          p={{ xs: 0.5, sm: 0.75, lg: 1 }}
          gap={gap}
        >
         
          <CardMedia
            className={cx(styles.media, mediaStyles.root)}
            image={image}
          />

          <Row>
            <Item position={"start"}>
              <Typography fontSize={8}>
                <Box style={{ fontSize: "11px", color: "rgb(112, 122, 131)" }}>
                  {truncateString(collectionTitle,17)}
                </Box>
                <Box fontSize={"0.75rem"}><Typography>{truncateString(assetName,17)}</Typography></Box>
              </Typography>
            </Item>
            <Item position={"right"}>
              <span
                style={{
                  fontSize: "11px",
                  color: "rgb(112, 122, 131)",
                  textAlign: "right",
                }}
              >
                {basePrice ? "Price" : ""}
              </span>
              <br></br>
              <span
                style={{ fontSize: "12px", color: "black", textAlign: "right" }}
              >
                {basePrice}
              </span>
              {basePrice ? (
                <img
                  src={ethrLogo}
                  height={16}
                  width={16}
                  style={{ padding: "2px" }}
                />
              ) : (
                ""
              )}
            </Item>
          </Row>
          <Row justifyContent="space-between" pr={1} pl={1} {...props}>
            <Item position={"middle"} pl={{ sm: 0.5, lg: 0.5 }}></Item>
            <a
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              { window.localStorage.getItem("isAuthenticated") 
              ? (<IconButton
                aria-label="add to favorites"
                onClick={(e) => markFavorite(e)}
              >
                {isClick ? (
                  <FavoriteIcon fontSize="small" style={{ color: red[500] }} />
                ) : (
                  <FavoriteBorderIcon
                    fontSize="small"
                    style={{ color: red[500] }}
                  />
                )}
              </IconButton>)
              : (<span></span>)}
              
            </a>
          </Row>
        </Column>
      </a>
    </Grid>
  );
});
export default AssetCard;
