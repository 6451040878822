import { useEffect, useRef ,React, useState } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import {useLocation } from "react-router-dom";
import Meta from "../../src/assets/meta.png";
import MailIcon from "@material-ui/icons/Mail";
import FacebookLogin from "react-facebook-login";
import GoogleLogin from "react-google-login";
import {
  connectToWallet,
  IsWalletConnected,
  setLoginStorage,
  responseGoogleSuccess,
  responseGoogleFailed,
  loginViaEmail,
} from "../webServices/loginServices";
import "../styles/style.css";
import { stringify } from "uuid";
const usePrevLocation = (location) => {
  const prevLocRef = useRef(location)
  useEffect(()=>{
  prevLocRef.current = location
  },[location])
  return prevLocRef.current
  }
function WalletId() {
  const [errors, setError] = useState("");
  return (
    <>
      <div className="container-cmd">
        <Container>
          <Row>
            <Col lg={{ span: 12 }}>
              <div className="registered-sign">
                <Row>
                  <Col lg={{ span: 7, offset: 3 }}>
                    <div className="forms-tag">
                    <Form >
                    <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/3/36/MetaMask_Fox.svg/512px-MetaMask_Fox.svg.png" style={{width: '154px',borderRadius: '50%'}} /> 
                        <div className="LoginButton">
                        
                          <Button
                            className={"btnOther"}
                            onClick={()=>connectToWallet()}
                            style={{ fontSize: "14px" }}
                          >
                            <img
                              src={Meta}
                              style={{ marginRight: 20, width: "20px" }}
                            />{" "}
                            Connect Metamask
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </Col>
                </Row>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default WalletId;
