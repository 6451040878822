/* eslint-disable no-undef */
import React from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import Feature from "../componets/cards/TabsCards";
import "../styles/style.css";
import axios from "axios";

class Collectiondata extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collections: [],
      isLoaded: false,
      show: false,
    };
  }
  componentDidMount() {
    const getMyCollections = async () => {
      let authObj = localStorage.getItem("authObj");
      authObj = JSON.parse(authObj);
      if (authObj.AccessToken) {
        const config = {
          headers: { Authorization: `Bearer ${authObj.AccessToken}` },
        };
        await axios
          .get(`${process.env.REACT_APP_API_SERVER_URL}/collections`, config)
          .then((res) => {
            this.setState({
              collections: res.data,
              isLoaded: true,
            });
          })
          .catch((err) => {
            console.log("err");
            console.log(err);
          });
      }
    };
    getMyCollections();
  }

  render() {
    return (
      <>
        <Container>
          <Row>
            <Col lg={{ span: 12 }}>
              <div className="asset-title collection-title">
                <p>My Collections</p>
                <label>
                  Create, accurate and manage collections of unique NFTs to
                  share and sell.
                </label>
              </div>
              <div className="multiple-pickers create-collect">
                <a href="/collections/create">
                  <Button variant="primary" type="text">
                    Create a Collection
                  </Button>
                </a>
              </div>
            </Col>
          </Row>
          <Row>
            {/* <Col lg={{span: 3}}>
                    <div className="cards-iii collection-cards">
                   <Feature />
                   </div>
                    </Col> */}
            {!this.state.isLoaded ? (
              <div>Loading...</div>
            ) : (
              this.state.collections.map((item, i) => (
                <Col lg={{ span: 3 }} key={i}>
                  <div className="cards-iii collection-cards">
                    <Feature
                      name={item.name}
                      TotalItems={0}
                      description={item.description}
                      FeaturedImage={item.image}
                      Image={item.image}
                    />
                  </div>
                </Col>
              ))
            )}
          </Row>
        </Container>
      </>
    );
  }
}
export default Collectiondata;
