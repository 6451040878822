import React from "react";
import cx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Avatar from "@material-ui/core/Avatar";
import CardMedia from "@material-ui/core/CardMedia";
import Tooltip from '@material-ui/core/Tooltip';
import { Column, Item } from "@mui-treasury/components/flex";
import { windowLocation } from "../../helpers/common";
import { Row, Col } from "react-bootstrap";
import { useFourThreeCardMediaStyles } from "@mui-treasury/styles/cardMedia/fourThree";
import { ReactComponent as yelloico } from "../../assets/icons/yelloico.svg";
import { Badge, SvgIcon } from "@material-ui/core";
import { truncateString } from "../../helpers/strings";
const useStyles = makeStyles(() => ({
  card: {
    border: "2px solid",
    borderColor: "#E7EDF3",
    borderRadius: 16,
    transition: "0.4s",
    "&:hover": {
      borderColor: "#5B9FED",
    },
  },
  media: {
    borderRadius: 6,
    paddingBottom: "100%",
    marginBottom: 15,
  },
  smCardBox: {
    border: "1px solid #c9c9c9",
  },

  avatarStyle: {
    borderRadius: "50px",
    width: "55px",
    height: "55px",
    display: "flex",
    border: "2px solid #0084ff",
    padding: 5,
  },
  numberinCard: {
    fontWeight: 900,
    color: "#c9c9c9",
  },

  yelloBadge: {
    position: "absolute",
    right: -8,
    top: -6,
  },
}));

export const FeaturedCard = React.memo(function ShowcaseCard(props) {
  const styles = useStyles();
  const mediaStyles = useFourThreeCardMediaStyles();
  const gap = { xs: 1, sm: 1.5, lg: 2 };
  const classes = useStyles();

  return (
    <Grid item xs={12} sm={6} md={3}>
      <a
        onClick={() => windowLocation(`/marketplace/`)}
        style={{ cursor: "pointer" }}
      >
      <Column
        className={styles.card}
        p={{ xs: 0.5, sm: 0.75, lg: 1 }}
        gap={gap}
      >
        <CardMedia
          className={cx(styles.media, mediaStyles.root)}
          image={props.image}
        />
        <Row>
          {/* <Item p={1}>
            <div className="img-profile-center">
              <Badge>
                <div className={classes.avatarStyle}>
                  <Avatar variant={"circle"} src={props.image} />
                </div>
                <SvgIcon
                  component={yelloico}
                  className={classes.yelloBadge}
                  style={{}}
                  viewBox="0 0 400 476.6"
                />
              </Badge>
            </div>
          </Item> */}
          <Row>
          <Col lg={{ span: 12 }}>
          
            <div className="featured-card">
              <div className="profile-name">{props.name}</div>
              <div className="description-name">
              <Tooltip title={props.description} placement="right">
                <p style={{overflow:'hidden'}}>{truncateString(props.description, 150)}</p>
              </Tooltip>
              </div>
            </div>
            
            </Col>
          </Row>
        </Row>
        <Row></Row>
      </Column>
      </a>
    </Grid>
  );
});
export default FeaturedCard;
