import React, { useEffect, useState } from "react";
import {
  CategoryProvider,
  CategoryTitle,
  CategoryItem,
} from "@mui-treasury/components/menu/category";
import axios from "axios";
// import  setConfig  from  "@mailchimp/mailchimp_marketing";
import {
  SocialProvider,
  SocialLink,
} from "@mui-treasury/components/socialLink";

import { useNikiCategoryMenuStyles } from "@mui-treasury/styles/categoryMenu/niki";
import {
  EmailSubscribe,
  EmailTextInput,
  SubmitButton,
} from "@mui-treasury/components/EmailSubscribe";
import ArrowUpwardIcon from "@material-ui/icons/ArrowUpward";
import {
  Box,
  Grid,
  IconButton, 
  Typography,
  FormLabel,
  Link,
} from "@material-ui/core";
import { useMoonSocialLinkStyles } from "@mui-treasury/styles/socialLink/moon";
import { useBootstrapEmailSubscribeStyles } from "@mui-treasury/styles/emailSubscribe/bootstrap";
import { makeStyles } from "@material-ui/core/styles";
import Logo from "../../assets/mintjar_logo.svg";
import "../../styles/style.css";
// subcribe
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Button from '@material-ui/core/Button';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import green from '@material-ui/core/colors/green';
import amber from '@material-ui/core/colors/amber';
// import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import WarningIcon from '@material-ui/icons/Warning';
import { withStyles } from '@material-ui/core/styles';
const variantIcon = {
  success: CheckCircleIcon,
}
const styles1 = theme => ({
  success: {
    backgroundColor: green[600],
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});
function MySnackbarContent(props) {
  const { classes, className, message, onClose, variant, ...other } = props;
  const Icon = variantIcon[variant];

  return (
    <SnackbarContent
      className={classNames(classes[variant], className)}
      aria-describedby="client-snackbar"
      message={
        <span id="client-snackbar" className={classes.message}>
          <Icon className={classNames(classes.icon, classes.iconVariant)} />
          {message}
        </span>
      }
      action={[
        <IconButton
          key="close"
          aria-label="Close"
          color="inherit"
          className={classes.close}
          onClick={onClose}
        >
          <CloseIcon className={classes.icon} />
        </IconButton>,
      ]}
      {...other}
    />
  );
}

MySnackbarContent.propTypes = {
  classes: PropTypes.object.isRequired,
  className: PropTypes.string,
  message: PropTypes.node,
  onClose: PropTypes.func,
  variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
};

const MySnackbarContentWrapper = withStyles(styles1)(MySnackbarContent);

const styles2 = theme => ({
  margin: {
    margin: theme.spacing.unit,
  },
});
/////////////// end suncribe ///////////////////
const useStyles = makeStyles(() => ({
  fazal: {
    fontFamily: [
      "SF Pro Text",
      "SF Pro Icons",
      "Helvetica Neue",
      "Helvetica",
      "Arial",
      "sans-serif",
    ].join(","),
  },

  inputInput: {
    width: "40ch !important",
  },
  label: {
    display: "flex",
    marginBottom: 20,
  },
  title: {
    display: "flex",
    marginBottom: 20,
  },
}));

export const OceanAppFooterDemo = React.memo(function OceanAppFooter() {
  const classes = useStyles();
  const [disableBtn, setDisableBtn] = useState(false);
  const [open, setOpen] = useState(false);
  const [isVisible, setIsVisible] = useState(false);
  const toggleVisibility = () => {
    if (window.pageYOffset > 300) {
      setIsVisible(true);
    } else {
      setIsVisible(false);
    }
  };
  // client.setConfig()

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const subcribe = async (e)=> {
    setDisableBtn(true);
    let email = document.getElementById('newsletter').value;
    const bodyParameters = {
      email: email
    };
    const AccessToken = JSON.parse(localStorage.getItem("authObj")).AccessToken;
    if (AccessToken) {
      const config = {
        headers: { Authorization: `Bearer ${AccessToken}` },
      };
      await axios
        .post(`${process.env.REACT_APP_API_SERVER_URL}/subcribe`, bodyParameters)
        .then((res) => {
          // console.log(res.data);
          if(res.data.id){
            setOpen(true);
          }else{
            setOpen(true);
          }
          setDisableBtn(false);
          setTimeout(
            function() {
              setOpen(false);
            }, 5000);
        //  alert(JSON.stringify(res));
        })
        .catch((err) => {
          console.log("err");
          console.log(err);
        });
    }
  }

  
  
  // const  = () => {
  //   alert(process.env.REACT_APP_API_SERVER_URL);

  //   this.getUserAssets();
    // const body = {userId:userId,userWalletAddress:userWalletAddress,eventType:'list',from:userWalletAddress,price:price,contractAddress,tokenId,transactionHash:transactionHash};
        // axios
        //   .get(`${process.env.REACT_APP_API_SERVER_URL}/test`)
        //   .then((res) => {
        //     alert(res);
        //     // console.log('LogListTrade Successfully');
        //     // console.log(JSON.stringify(res.data));
        //   })
        //   .catch((err) => {
        //     console.log("Unable to LogListTrade", err);
        //   });
//  setConfig({
//       apiKey: "6c17134f23b80d765729c2e57f93b4e2-us12",
//       server: "https://us12.admin.mailchimp.com/",
//     });
    // con
    // const run = async () => {
    //   // alert(121212);
    //   // return false;
    //   const response = await client.lists.setListMember(
    //     "a913a86de3",
    //     "subscriber_hash",
    //     { email_address: "Marcel81@gmail.com", status_if_new: "pending" }
    //   );
    //   console.log(response);
    // };
    
    // run();
  // }
  useEffect(() => {
    document.getElementById('discord').innerHTML = '<img src="https://img.icons8.com/material-two-tone/24/000000/discord-logo--v2.png"/>'
    document.getElementById('reddit').innerHTML = '<img style="width: 1em;height: 1em;" src="https://img.icons8.com/ios/50/000000/reddit--v2.png"/>'
    
  });
  // Set the top cordinate to 0
  // make scrolling smooth
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  useEffect(() => {
    window.addEventListener("scroll", toggleVisibility);
  }, []);
  return (
    <div className="footer_wrap">
      <div className="arrow-top">
        {isVisible && (
          <div onClick={scrollToTop} style={{ textAlign: "center" }}>
            <IconButton aria-label="Scroll to top">
              <ArrowUpwardIcon />
            </IconButton>
          </div>
        )}
      </div>
      <Box width={"100%"} px={{ xs: 2, sm: 3, lg: 4 }}>
        <Box pt={6} pb={{ md: 6 }} style={{ paddingTop: 0 }}>
          <div className="Footer-communinty">
            <Grid container spacing={2}>
              <Grid item xs={12} md={4}>
                <Box textAlign={"center"} mt={{ xs: 2, md: 0 }} my={2}>
                  <Typography className={classes.title}>
                    Stay Updated
                  </Typography>
                  <FormLabel htmlFor={"newsletter"} className={classes.label}>
                    Subscribe to our newsletter to get our latest news
                  </FormLabel>
                  {/* <p id="subcribeMessage" style={{textAlign:'left',color:'green',display:'none'}}>You have subscribed successfully.</p> */}
                  <EmailSubscribe
                    onSubmit={subcribe}
                    className={classes.inputInput}
                    useStyles={useBootstrapEmailSubscribeStyles}
                    inputClearedAfterSubmit
                  >
                    <EmailTextInput
                      id={"newsletter"}
                      placeholder="Enter your email"
                    />
                    <SubmitButton disabled={disableBtn} >Subscribe</SubmitButton>
                  </EmailSubscribe>
                </Box>
              </Grid>
              <Grid item md={3}  />
              {/* <Grid item md={2} implementation="css" smDown  /> */}
              <Grid item xs={12}  lg={5}>
                <Box textAlign={"center"} mt={{ xs: 2, md: 0 }} my={2}>
                  <Typography>Community</Typography>
                </Box>

                <Box textAlign={"center"} mt={{ xs: 2, md: 1 }} my={1}>
                  <SocialProvider useStyles={useMoonSocialLinkStyles}>
                    <SocialLink
                      brand={"FacebookCircle"}
                      href={"https://www.facebook.com/MintJarCo/"}
                      alt="MintJar Facebook page"
                    />
                    <SocialLink
                      brand={"Twitter"}
                      href={"https://twitter.com/mintjarco"}
                      alt="MintJar Twitter page"
                    />
                    <SocialLink brand={"Instagram"} href={"https://www.instagram.com/mintjarco/"} 
                    alt="MintJar Instagram page"
                    />
                    <SocialLink id="reddit"  href={"#"} 
                    alt="MintJar Reddit page"
                    />
                    <SocialLink id="discord"  href={"#"} 
                    alt="MintJar Discord page"/>
                  </SocialProvider>

                </Box>
              </Grid>
            </Grid>
          </div>
          <hr />
          <Grid container spacing={2}>
            <Grid item md={1}></Grid>
            

            {/* <Grid item xs={12} md={2}>
             
              <Box  width={'50%'} pt={3}>

              <EmailSubscribe
                onSubmit={email => alert(`Your email is ${email}.`)}
                useStyles={useInfoEmailSubscribeStyles}
                inputClearedAfterSubmit
              >
                <EmailTextInput placeholder="Email address" />
                <SubmitButton aria-label={'subscribe'}>
                  <ArrowForward />
                </SubmitButton>
              </EmailSubscribe>
              </Box>
            </Grid> */}

            <Grid item xs={12} md={10} lg={10}>
              <Grid container spacing={0}>
                <Grid item lg={1}>
                  <div className="copy-right">
                    <p></p>
                  </div>
                </Grid>
                <Grid item xs={6} md={3} lg={2} sm={6}>
                <div className="footer-log" style={{display:'flex'}}>
                <CategoryTitle></CategoryTitle>
                <Box component={"img"} mt={-3} width={120} src={Logo} alt="" />
              </div>
                  </Grid>
                <Grid item xs={6} md={2} lg={2} sm={6}>
                  <div className="support-footer">
                    <CategoryProvider useStyles={useNikiCategoryMenuStyles}>
                      <CategoryTitle>
                        <Typography>Company</Typography>
                      </CategoryTitle>
                      <div className="footer-text">
                        
                      <a href="/about">
                              <CategoryItem>
                              About
                              </CategoryItem>
                              </a>
                         
                              <a className="cur-auto" href="/careers">
                        <CategoryItem>Join our team</CategoryItem>
                        </a>
                        {/* <a className="cur-auto" href="/privacy">
                        <CategoryItem>Legal and privacy</CategoryItem>
                        </a> */}
                      </div>
                    </CategoryProvider>
                  </div>
                </Grid>
                <Grid item xs={6} md={2} lg={2} sm={6}>
                  <div className="support-footer">
                    <CategoryProvider useStyles={useNikiCategoryMenuStyles}>
                      <CategoryTitle>
                        <Typography>Sellers</Typography>
                      </CategoryTitle>
                      <div className="footer-text">
                        <a href="/assets/create">
                        <CategoryItem>Mint NFT</CategoryItem>
                        </a>
                        <a className="cur-auto" href="/my-account?tab=created">
                        <CategoryItem>Sell NFT</CategoryItem>
                        </a>
                        {/* <a href="/earn-royalties">
                        <CategoryItem>Earn royalties</CategoryItem>
                        </a> */}
                      </div>

                      <CategoryTitle>
                        <Typography>Buyers</Typography>
                      </CategoryTitle>
                      <div className="footer-text">
                      <a className="cur-auto" href="/marketplace">
                        <CategoryItem>Marketplace</CategoryItem>
                        </a>
                        {/* <a className="cur-auto" href="/marketplace/?auction=true">
                        <CategoryItem>Auctions</CategoryItem>
                        </a> */}
                      </div>
                    </CategoryProvider>
                  </div>
                </Grid>
                <Grid item xs={6} md={2} lg={2} sm={6}>
                  <div className="support-footer ">
                    <CategoryProvider useStyles={useNikiCategoryMenuStyles}>
                      <CategoryTitle>
                        <Typography>Support</Typography>
                      </CategoryTitle>
                      <div className="footer-text">
                      <a className="cur-auto" href="mailto:support@mintjar.com">
                        <CategoryItem>Help center</CategoryItem>
                        </a>
                        <a className="cur-auto" href="mailto:support@mintjar.com" >
                        <CategoryItem>Contact us</CategoryItem>
                        </a>
                        {/* <a href="/faq">
                        <CategoryItem>FAQ</CategoryItem>
                        </a> */}
                      </div>

                      <CategoryTitle>
                        <Typography>Account</Typography>
                      </CategoryTitle>
                      <div className="footer-text">
                      <a className="cur-auto" href="/my-account">
                        <CategoryItem>My Account</CategoryItem>
                        </a>
                        <a className="cur-auto" href="/my-account?tab=favorites">
                        <CategoryItem>My Favorites</CategoryItem>
                        </a>
                      </div>
                    </CategoryProvider>
                  </div>
                </Grid>
                <Grid item xs={6} md={2} lg={3} sm={6}>
                  <div className="support-footer">
                    <CategoryProvider useStyles={useNikiCategoryMenuStyles}>
                      <CategoryTitle>
                        <Typography>Learn</Typography>
                      </CategoryTitle>
                      <div className="footer-text">
                      <a href="https://metamask.io/download/" target="_blank">
                        <CategoryItem>How to set up wallet</CategoryItem>
                      </a>
                      <a href="https://www.fool.com/investing/stock-market/market-sectors/financials/non-fungible-tokens/nft-minting/" target="_blank">
                        <CategoryItem>What is minting?</CategoryItem>
                        </a>
                        <a className="cur-auto" href="https://en.wikipedia.org/wiki/Non-fungible_token" target="_blank">
                        <CategoryItem>
                          What is non-fungible tokens?
                        </CategoryItem>
                        </a>
                        <a href="https://en.wikipedia.org/wiki/Cryptocurrency" target="_blank">
                        <CategoryItem>What is crypto currency?</CategoryItem>
                        </a>
                        <a href="https://en.wikipedia.org/wiki/Blockchain" target="_blank">
                        <CategoryItem>What is blockchain?</CategoryItem>
                        </a>
                        <a className="cur-auto" href="https://ethereum.org/en/" target="_blank">
                        <CategoryItem>Ethereum.org</CategoryItem>
                        </a>
                      </div>
                    </CategoryProvider>
                  </div>
                </Grid>
              </Grid>
            </Grid>

            {/* <Grid item xs={12} md={4} style={{ marginLeft: 'auto' }}>
              <CategoryProvider useStyles={useNikiCategoryMenuStyles}>
                <CategoryTitle>
                </CategoryTitle>
              </CategoryProvider>
              <Box>
                <SocialProvider useStyles={useBallSocialLinkStyles}>
                  <SocialLink brand={'Envelope'} />
                  <SocialLink brand={'GooglePlus'} />
                  <SocialLink brand={'Pinterest'} />
                  <SocialLink brand={'Dribbble'} />
                </SocialProvider>
              </Box>
            </Grid> */}
          </Grid>
        </Box>
        <hr />
        <Grid item xs={12} md={10}>
          <Grid container spacing={0}>
            <Grid item xs={12} md={4} sm={6}>
              <div className="copy-right">
                <p></p>
              </div>
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <div className="copy-right">
                <p>Copyright © 2022 MintJar. All rights reserved.</p>
              </div>
            </Grid>
            <Grid item xs={12} md={4} sm={6}>
              <div className="copy-right">
                <ul>
                  <li>
                    <a href="/privacy">Privacy Policy</a>
                  </li>
                  <li>
                    <a>|</a>
                  </li>
                  <li>
                    <a href="/terms-of-service">Terms of Service</a>
                  </li>
                </ul>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          open={open}
          autoHideDuration={6000}
          onClose={handleClose}
        >
          <MySnackbarContentWrapper
            onClose={handleClose}
            variant="success"
            message="You have subscribed successfully"
          />
        </Snackbar>
      </Box>
    </div>
  );
});

export default OceanAppFooterDemo;
