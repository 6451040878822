import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";
import AssetCard from "../componets/cards/AssetCard";
import { GET,dbPOST } from "../webServices/fetch.js";
import Carousel from "react-multi-carousel";
import {ButtonGroup } from "../helpers/common";
import { Row, Col } from "react-bootstrap";
import CircularProgress from '@material-ui/core/CircularProgress';
import "react-multi-carousel/lib/styles.css";
const responsive = {
  superLargeDesktop: {
    // the naming can be any, depends on you.
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
  },
  tablet: {
    breakpoint: { max: 1024, min: 575 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 575, min: 0 },
    items: 1,
  },
};
class NFTsYouViewed extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      LiveAuctions: [],
      isLoaded: false,
    };
  }
  componentDidMount() {
   
    let likedAssetsTokens =[];
    let likedAssetsAddresses =[];
    if(localStorage.getItem("RecentViews")){
      let toObj=JSON.parse(localStorage.getItem("RecentViews"));
      toObj.map((item)=>{
        likedAssetsAddresses.push(item.contract);
        likedAssetsTokens.push(item.token);
      });
    }
    if(localStorage.getItem("favItems")){
      let toObj=JSON.parse(localStorage.getItem("favItems"));
      toObj.map((item)=>{
        likedAssetsAddresses.push(item.contract);
        likedAssetsTokens.push(item.token);
      });
    }


    const getData = async () => {
      const { data: Items } = await dbPOST(
        `v1/assets/maylike`,{likedAssetsTokens:likedAssetsTokens,likedAssetsAddresses:likedAssetsAddresses}
      );
      let orderData=[];
      Items.forEach((itm)=>{
         orderData.push(itm)
       });

      if (orderData) {
        // Set data to state
        this.setState({
          NFTsYouViewed: orderData,
          isLoaded: true,
        });
         console.log(this.state.NFTsYouViewed);
        if (this.state.NFTsYouViewed) {
          // this.state.NFTsYouViewed.map((item) => console.log(item.name));
        }
      } else {
        // error
      }
    };

   
    getData();
    
  }

  render() {
    // const { Items, isLoaded } = this.state;
    return (
      <Grid >
        <Typography variant="h3" component="h3" className={"grid_title"}>
          <Box fontSize={"24px"} about="NFTs you may like">NFTs You May Like</Box>
        </Typography>
        <div className=" responsive-view homePageCarousel">
        <Carousel
            responsive={responsive}
            arrows = {false}
            customButtonGroup={<ButtonGroup
                next={this.props.next}
                previous={this.props.previous}
                rest={this.props.rest}
            />}
                        >
            {!this.state.isLoaded ? (
              <div className="loading-icons" style={{textAlign: "center", justifyContent: "center", display: "inline-block"}}>
                  <Row>
                  <Col lg={{ span: 11 }} xs={{ span: 12 }}>
                    <div class="loader-container">
                    <CircularProgress disableShrink />
                    </div>
                  </Col>
                  </Row>
                  
                  </div>
            ) : (
              this.state.NFTsYouViewed.map((item) => (
                <Grid item sm={12}>
                <AssetCard
                  asset={item}
                  key={item.asset_contract.address + "/" + item.token_id}
                />
                </Grid>
              ))
            )}
          </Carousel>
        </div>
      </Grid>
    );
  }
}
export default NFTsYouViewed;
