import { React, useState} from "react";
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles } from "@material-ui/core/styles";

function ClipboardCopy({ copyText, displayText  }) {
    const [isCopied, setIsCopied] = useState(false);
    const useStyles = makeStyles((theme) => ({
        copyToClipboard: {
            fontWeight: '500',
            fontSize: '14px',
            float: 'right',
            paddingRight: '20px',
            display: 'inline-block',
            cursor: "pointer"
        }
    }));
    const classes = useStyles();
    // This is the function we wrote earlier
    async function copyTextToClipboard(text) {
      if ('clipboard' in navigator) {
        return await navigator.clipboard.writeText(text);
      } else {
        return document.execCommand('copy', true, text);
      }
    }
  
    // onClick handler function for the copy button
    const handleCopyClick = () => {
      // Asynchronously call copyTextToClipboard
      copyTextToClipboard(copyText)
        .then(() => {
          // If successful, update the isCopied state value
          setIsCopied(true);
          setTimeout(() => {
            setIsCopied(false);
          }, 1500);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  
    return (
        <Tooltip title={isCopied ? 'Copied!' : 'Copy'} aria-label="Copy">
        <p className={classes.copyToClipboard} onClick={handleCopyClick}>{displayText}</p>
      </Tooltip>
    );
  }

  export default ClipboardCopy;