import "../src/styles/main.scss";
import "bootstrap/dist/css/bootstrap.min.css";
import HomeDataReady from "../src/Pages/HomeDataReady";
import Profile from "../src/Pages/Profile";
import MarketPlace from "../src/Pages/MarketPlace";
import Assets from "../src/Pages/Assets";
import Login from "../src/Pages/Login";
import Wallet from "./Pages/ConnectWallet";
import Signup from "../src/Pages/Signup";
import ProtectedRoute from "../src/Component/ProtectedRoute";
// import ProtectRoutViaWallet from "../src/Component/ProtectRoutViaWallet";
import CreateAssets from "../src/Pages/CreateAssets";
import SellAssets from "../src/Pages/SellAssets";
import MyCollection from "../src/Pages/MyCollection";
import WalletProfile from "../src/Pages/WalletProfile";
import CreateCollection from "../src/Pages/CreateCollection";
import ComingSoon from "../src/Pages/Footer/ComingSoon";
import Learn from "../src/Pages/Footer/Learn";
import About from "./Pages/Footer/About";
import Privacy from "./Pages/Footer/Privacy";
import Tos from "./Pages/Footer/tos";// term of use
import Careers from "./Pages/Footer/Careers";// term of use
import MintNFT from "./Pages/Blog/MintNFT";// term of use
import EarnRoyalties from "./Pages/Blog/EarnRoyalties";// term of use
import FAQs from "./Pages/Blog/FAQs";// term of use
import HelpCenter from "./Pages/Blog/HelpCenter";// term of use
import ContactUs from "./Pages/Blog/ContactUs";// term of use
import tempTest from "./Pages/tempTest"
// import Profile from '../src/componets/Profile';
import { Switch, Route, Redirect } from "react-router-dom";

function App() {
  // localStorage.setItem("prevUrl", '/');
  return (
    <>
      <Switch>
        <Route exact path="/" component={HomeDataReady} />
        <Route exact path="/wallet/:walletId" component={WalletProfile} />
        <ProtectedRoute exact path="/my-account" component={Profile} />
        <Route exact path="/marketplace/:contract?" component={MarketPlace} />
        <Route exact path="/marketplace/contract/:contractAddress" component={MarketPlace} />
        <Route exact path="/asset/:contract?/:token?" component={Assets} />
        <Route exact path="/asset/:contract?/:token?/sell" component={SellAssets} />
        <Route exact path="/login" component={Login} />
        <Route exact path="/wallet" component={Wallet} />
        <Route exact path="/signup" component={Signup} />
        <ProtectedRoute exact path="/assets/create" component={CreateAssets} />
        <Route exact path="/collections" component={MyCollection} />
        <Route exact path="/collections/create" component={CreateCollection} />
        <Route exact path="/collections/coming" component={ComingSoon} />
        <Route exact path="/learn" component={Learn} />
        <Route exact path="/about" component={About} />
        <Route exact path="/mint-nft" component={MintNFT} />
        <Route exact path="/earn-royalties" component={EarnRoyalties} />
        <Route exact path="/faq" component={FAQs} />
        <Route exact path="/contact-us" component={ContactUs} />
        <Route exact path="/help-center" component={HelpCenter} />
        <Route exact path="/privacy" component={Privacy} />
        <Route exact path="/terms-of-service" component={Tos} />
        <Route exact path="/careers" component={Careers} />
        <Route exact path="/career" component={Careers} />
        <Route exact path="/test" component={tempTest} />
        <Redirect to="/" />
      </Switch>
    </>
  );
}

export default App;
