import React from "react";
import { Redirect, Route , useLocation } from "react-router-dom";
import { isAuthenticated,connectViaMetaMask } from "../webServices/loginServices";
function ProtectedRoute({ component: Component, ...restOfProps }) {
  let location = useLocation();
  location = location.pathname;
  if(location === '/assets/create')
    localStorage.setItem("prevUrl", location);
    else 
    localStorage.setItem("prevUrl", '/');
  // alert(connectViaMetaMask(JSON.stringify(restOfProps)));
  return (  
    <Route
      {...restOfProps}
      render={(props) =>
        // isAuthenticated() ?  <Component {...props} /> : <Redirect to="/login" />
        isAuthenticated() ? location === '/assets/create' ? connectViaMetaMask() ? <Component {...props} />:  <Redirect to="/wallet" /> : <Component {...props} /> : <Redirect to="/login" />
      }
    />
  );
}

export default ProtectedRoute;
